import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InvitationFilter } from '../../interface/invitation-filter.interface';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { EtatInvitationCollaborateur } from 'src/app/interfaces/invitation-collaborateur/etat-invitation-collaborateur';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone:true,
  imports:[ ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatCardModule,MatButtonModule,NgFor]
})
export class FilterComponent {
  filter: InvitationFilter = {};
  @Input() etats: EtatInvitationCollaborateur[] = [];
  @Output() filterChange = new EventEmitter<InvitationFilter>();

  filterForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.filterForm = this.fb.group({
      nom     : [''],
      prenom  : [''],
      idEtat  : ['']
    });
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.filterChange.emit(this.filterForm.value);
  }

}
