<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Sélectionner votre filtre</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mat-card-content">

        <mat-form-field class="formDate" appearance="outline">
            <mat-label>A partir de *</mat-label>
            <input  matInput 
                    type ="date"
                    [(ngModel)] = "filterDateDebut"
                    (changeX)    = "selectFilter()"
                    >
                    
        </mat-form-field>

        <mat-form-field class="formDate" appearance="outline">
            <mat-label>Jusqu'au *</mat-label>
            <input  matInput  
                    type="date"
                    [(ngModel)] = "filterDateFin"
                    (changeX)    = "selectFilter()"
                    >
                    
        </mat-form-field>

        <button mat-raised-button (click)="searchingEntite()" color="primary" style="margin: 10px;">Rechercher une entite</button>
        <app-entite-search *ngIf="searching" (entiteEvent)="setEntite($event)"></app-entite-search>
        <div *ngIf="entiteSelected" class="entite">
            <div style="margin-right: 15px;">
                {{entiteSelected!.raison_sociale}}
            </div>
            <button mat-mini-fab (click)="cancelEntite()"  matTooltip="Supprimer l'entité de la recherche"><mat-icon>close</mat-icon></button>
        </div>
        
    </mat-card-content>
        <mat-card-actions  align="end">
            <button mat-raised-button color="primary" (click)="selectFilter()">Rechercher</button>
            <!-- <div class="asterisque"> * commandes émise à partir de / dossiers envoyés à partir de</div> -->
        </mat-card-actions>

</mat-card>

