import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { AlphaNumDirective } from 'src/app/directive/alphaNumdirective';
import { ImmatDirective } from 'src/app/directive/immat.directive';
import { PercentDirective } from 'src/app/directive/percent.directive';
import { ThousandSeparatorDirective } from 'src/app/directive/thousandSeparatordirective';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { DateRangeValidator } from 'src/app/validators/dateRange.validator';
import { VehiculeValidator } from 'src/app/validators/vehicule.validator';
import { RDEAFile } from '../../interfaces/rdeaFile';
import { References, ReferencesInit } from '../../interfaces/reference';

@Component({
  selector: 'app-edit-suivi',
  templateUrl: './edit-suivi.component.html',
  styleUrls: ['./edit-suivi.component.scss'],
  standalone: true,
  imports : [MatCardModule, MatIconModule, MatStepperModule, NgClass, ReactiveFormsModule, MatFormFieldModule
            , MatInputModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatCheckboxModule, MatButtonModule
            , MatRadioModule, ImmatDirective, AlphaNumDirective,ThousandSeparatorDirective,MatProgressSpinnerModule
            , PercentDirective,ReactiveFormsModule]
})
export class EditSuiviComponent {
  @Input() entites    : Entite[]=[]
  @Input() rdeaFile!  : RDEAFile
  @Input() references : References = ReferencesInit
  @Input() errors     : string[]=[]
  @Input() waiting    : boolean = false
  @Output() fileEvent = new EventEmitter<RDEAFile>()
  @Output() retourEvent = new EventEmitter<boolean>()
  @Output() editSuiviEvent      = new EventEmitter<boolean>()

  vinValidator        = inject(VehiculeValidator)
  dateRangeValidator  = inject(DateRangeValidator)
  today               : Date = new Date()

  suiviForm:FormGroup = new FormGroup({
    
    information : new FormGroup({
        type              : new FormControl<number>(1,Validators.required)
      , dateEffet         : new FormControl<Date|null>(null,[Validators.required,this.dateRangeValidator.verifyDateRange(null,this.today,true)])
      , idEntite          : new FormControl<number|null>(null,Validators.required)
    })

    , reference : new FormGroup({
        numeroDossier     : new FormControl<string>('',Validators.required)
      , dateSinistre      : new FormControl<Date|null>(null,[Validators.required,this.dateRangeValidator.verifyDateRange(null,this.today,true)])
      , natureEvenement   : new FormControl<string>('')
      , natureAdverse     : new FormControl<string>('')
    })

    , technique : new FormGroup({
        immatriculation   : new FormControl<string>('',Validators.required)
      , vin               : new FormControl<string>('',[Validators.required,this.vinValidator.verifieVin() ])
      , numeroFormule     : new FormControl<string>('',Validators.required)
      , dateCi            : new FormControl<Date|null>(null,[Validators.required,this.dateRangeValidator.verifyDateRange(null,this.today,true)])
      , dateMec           : new FormControl<Date|null>(null,[Validators.required,this.dateRangeValidator.verifyDateRange(null,this.today,true)])
      , typeMine          : new FormControl<string>('',Validators.required)
      , marque            : new FormControl<string>('',Validators.required)
      , modele            : new FormControl<string>('',Validators.required)
      , genre             : new FormControl<string>('',Validators.required)
      , carrosserie       : new FormControl<string>('',Validators.required)
      , energie           : new FormControl<string>('',Validators.required)
      , kilometrage       : new FormControl<number>(0,Validators.required)
      , puissanceFiscale  : new FormControl<number>(0,Validators.required)
      , puissanceReelle   : new FormControl<number>(0)
      , couleur           : new FormControl<string>('',Validators.required)
    })

    , expertise : new FormGroup({
      ensemble : new FormControl<boolean>(false)
      , valeurTotaleHt    : new FormControl<number>(0, [Validators.min(0),Validators.max(1000000)])
      , valeurTotaleTtc   : new FormControl<number>(0, [Validators.min(0),Validators.max(1000000)])
      , totalHt           : new FormControl<number>(0)
      , piecesHt          : new FormControl<number>(0)
      ,  usureAvg            : new FormControl<number>(0, [Validators.min(0),Validators.max(100)])
      , usureAvd            : new FormControl<number>(0, [Validators.min(0),Validators.max(100)])
      , usureArg            : new FormControl<number>(0, [Validators.min(0),Validators.max(100)])
      , usureArd            : new FormControl<number>(0, [Validators.min(0),Validators.max(100)])
      , usureArg2           : new FormControl<number>(0, [Validators.min(0),Validators.max(100)])
      , usureArd2           : new FormControl<number>(0, [Validators.min(0),Validators.max(100)])
      , chocDessus          : new FormControl<boolean>(false)
      , chocSoubassement    : new FormControl<boolean>(false)
      , chocTrainsRoulants  : new FormControl<boolean>(false)
      , chocMoteurBvPonts   : new FormControl<boolean>(false)
      , chocHabitacle       : new FormControl<boolean>(false)
      , chocAvant           : new FormControl<boolean>(false)
      , chocAvantG          : new FormControl<boolean>(false)
      , chocAvantD          : new FormControl<boolean>(false)
      , chocLateralG        : new FormControl<boolean>(false)
      , chocLateralD        : new FormControl<boolean>(false)
      , chocArriereG        : new FormControl<boolean>(false)
      , chocArriereD        : new FormControl<boolean>(false)
      , chocArriere         : new FormControl<boolean>(false)
    })

    , conclusion : new FormGroup({
        dangereux     : new FormControl<number>(0)
      , ecoReparable  : new FormControl<number>(0)
      , techReparable : new FormControl<number>(0)
      , ca3           : new FormControl<boolean>(false)
      , d3            : new FormControl<boolean>(false)
      , ls3           : new FormControl<boolean>(false)
      , sp4           : new FormControl<boolean>(false)
      , en5           : new FormControl<boolean>(false)
    })
  })
  
  ngOnInit(){
    this.initDossier()
  }

  uncheckAll(ensemble:boolean){
    if (ensemble) {
      this.suiviForm.controls.expertise.patchValue({
        chocDessus:false
        , chocSoubassement    : false
        , chocTrainsRoulants  : false
        , chocMoteurBvPonts   : false
        , chocHabitacle       : false
        , chocAvant           : false
        , chocAvantG          : false
        , chocAvantD          : false
        , chocLateralG        : false
        , chocLateralD        : false
        , chocArriereG        : false
        , chocArriereD        : false
        , chocArriere         : false
      })
    }
  }

  uncheckEnsemble(checked:boolean){
    if (checked) {
      this.suiviForm.controls.expertise.patchValue({ensemble:false})
    }
  }

  setDangerous(dangereux:boolean){
    
    if (!dangereux) {
      this.suiviForm.controls.conclusion.patchValue({
          ca3 : false
        , d3  : false
        , ls3 : false
        , sp4 : false
        , en5 : false
      })
      this.suiviForm.controls.conclusion.get('ca3')!.disable()
      this.suiviForm.controls.conclusion.get('d3')!.disable()
      this.suiviForm.controls.conclusion.get('ls3')!.disable()
      this.suiviForm.controls.conclusion.get('sp4')!.disable()
      this.suiviForm.controls.conclusion.get('en5')!.disable()
    }else{
      this.suiviForm.controls.conclusion.get('ca3')!.enable()
      this.suiviForm.controls.conclusion.get('d3')!.enable()
      this.suiviForm.controls.conclusion.get('ls3')!.enable()
      this.suiviForm.controls.conclusion.get('sp4')!.enable()
      this.suiviForm.controls.conclusion.get('en5')!.enable()
    }
  }

  getErrors(controlName:string){
    switch (controlName) {
      case 'vin':
        return this.suiviForm.controls.technique.get('vin')?.errors?.erreur

      case 'dateSinistre':
        return this.suiviForm.controls.reference.get('dateSinistre')?.errors?.erreur

      case 'dateMec':
        return this.suiviForm.controls.technique.get('dateMec')?.errors?.erreur

      case 'dateCi':
        return this.suiviForm.controls.technique.get('dateCi')?.errors?.erreur

      default:
        return ''
    }
    
  }
  sendFile(){
    //On déforme l'immatriculation:
    this.suiviForm.patchValue({
      technique:{
        immatriculation : this.suiviForm.value.technique.immatriculation.replaceAll('-','')
      }
    })
    
    this.fileEvent.next(this.suiviForm.value);
  }

  initDossier(){    
    let ecoReparable  : string = this.rdeaFile?.conclusion?.ecoReparable?   '1' : '0'
    let techReparable : string = this.rdeaFile?.conclusion?.techReparable?  '1' : '0'
    let dangereux     : string = this.rdeaFile?.conclusion?.dangereux?      '1' : '0'
    
    this.errors = [];

    this.suiviForm.patchValue({
      information : {
       idEntite          : this.rdeaFile?.information?.idEntite? this.rdeaFile.information.idEntite : 0
    }
      ,reference: {
        dateSinistre    : this.rdeaFile?.reference?.dateSinistre ? this.rdeaFile.reference.dateSinistre : null,
        natureAdverse   : this.rdeaFile?.reference?.natureAdverse ? this.rdeaFile.reference.natureAdverse : null,
        natureEvenement : this.rdeaFile?.reference?.natureEvenement ? this.rdeaFile.reference.natureEvenement : null,
        numeroDossier   : this.rdeaFile?.reference?.numeroDossier ? this.rdeaFile.reference.numeroDossier : null
    },
    technique: {
        carrosserie     : this.rdeaFile?.technique?.carrosserie ? this.rdeaFile.technique.carrosserie : null,
        couleur         : this.rdeaFile?.technique?.couleur ? this.rdeaFile.technique.couleur : null,
        dateCi          : this.rdeaFile?.technique?.dateCi ? this.rdeaFile.technique.dateCi : null,
        dateMec         : this.rdeaFile?.technique?.dateMec ? this.rdeaFile.technique.dateMec : null,
        energie         : this.rdeaFile?.technique?.energie ? this.rdeaFile.technique.energie : null,
        genre           : this.rdeaFile?.technique?.genre ? this.rdeaFile.technique.genre : null,
        immatriculation : this.rdeaFile?.technique?.immatriculation ? this.rdeaFile.technique.immatriculation : null,
        kilometrage     : this.rdeaFile?.technique?.kilometrage ? this.rdeaFile.technique.kilometrage : 0,
        marque          : this.rdeaFile?.technique?.marque ? this.rdeaFile.technique.marque : null,
        modele          : this.rdeaFile?.technique?.modele ? this.rdeaFile.technique.modele : null,
        numeroFormule   : this.rdeaFile?.technique?.numeroFormule ? this.rdeaFile.technique.numeroFormule : null,
        puissanceFiscale: this.rdeaFile?.technique?.puissanceFiscale ? this.rdeaFile.technique.puissanceFiscale : 0,
        puissanceReelle : this.rdeaFile?.technique?.puissanceReelle ? this.rdeaFile.technique.puissanceReelle : 0,
        typeMine        : this.rdeaFile?.technique?.typeMine ? this.rdeaFile.technique.typeMine : null,
        vin             : this.rdeaFile?.technique?.vin ? this.rdeaFile.technique.vin : null
    },
    expertise: {
        chocDessus        : this.rdeaFile?.expertise?.chocDessus ? this.rdeaFile.expertise.chocDessus : null,
        chocSoubassement  : this.rdeaFile?.expertise?.chocSoubassement ? this.rdeaFile.expertise.chocSoubassement : null,
        chocTrainsRoulants: this.rdeaFile?.expertise?.chocTrainsRoulants ? this.rdeaFile.expertise.chocTrainsRoulants : null,
        chocMoteurBvPonts : this.rdeaFile?.expertise?.chocMoteurBvPonts ? this.rdeaFile.expertise.chocMoteurBvPonts : null,
        chocHabitacle     : this.rdeaFile?.expertise?.chocHabitacle ? this.rdeaFile.expertise.chocHabitacle : null,
        chocArriere       : this.rdeaFile?.expertise?.chocArriere ? this.rdeaFile.expertise.chocArriere : null,
        chocArriereD      : this.rdeaFile?.expertise?.chocArriereD ? this.rdeaFile.expertise.chocArriereD : null,
        chocArriereG      : this.rdeaFile?.expertise?.chocArriereG ? this.rdeaFile.expertise.chocArriereG : null,
        chocAvant         : this.rdeaFile?.expertise?.chocAvant ? this.rdeaFile.expertise.chocAvant : null,
        chocAvantD        : this.rdeaFile?.expertise?.chocAvantD ? this.rdeaFile.expertise.chocAvantD : null,
        chocAvantG        : this.rdeaFile?.expertise?.chocAvantG ? this.rdeaFile.expertise.chocAvantG : null,
        chocLateralG      : this.rdeaFile?.expertise?.chocLateralG ? this.rdeaFile.expertise.chocLateralG : null,
        chocLateralD      : this.rdeaFile?.expertise?.chocLateralD ? this.rdeaFile.expertise.chocLateralD : null,
        piecesHt          : this.rdeaFile?.expertise?.piecesHt ? this.rdeaFile.expertise.piecesHt : 0,
        totalHt           : this.rdeaFile?.expertise?.totalHt ? this.rdeaFile.expertise.totalHt : 0,
        usureArd          : this.rdeaFile?.expertise?.usureArd ? this.rdeaFile.expertise.usureArd : 0,
        usureArd2         : this.rdeaFile?.expertise?.usureArd2 ? this.rdeaFile.expertise.usureArd2 : 0,
        usureArg          : this.rdeaFile?.expertise?.usureArg ? this.rdeaFile.expertise.usureArg : 0,
        usureArg2         : this.rdeaFile?.expertise?.usureArg2 ? this.rdeaFile.expertise.usureArg2 : 0,
        usureAvd          : this.rdeaFile?.expertise?.usureAvd ? this.rdeaFile.expertise.usureAvd : 0,
        usureAvg          : this.rdeaFile?.expertise?.usureAvg ? this.rdeaFile.expertise.usureAvg : 0,
        valeurTotaleHt    : this.rdeaFile?.expertise?.valeurTotaleHt ? this.rdeaFile.expertise.valeurTotaleHt : 0,
        valeurTotaleTtc   : this.rdeaFile?.expertise?.valeurTotaleTtc ? this.rdeaFile.expertise.valeurTotaleTtc : 0
    },
    conclusion: {
        dangereux     : dangereux,
        ecoReparable  : ecoReparable,
        techReparable : techReparable,
        ca3           : this.rdeaFile?.conclusion?.ca3 ? this.rdeaFile.conclusion.ca3 : false,
        d3            : this.rdeaFile?.conclusion.d3 ? this.rdeaFile.conclusion.d3 : false,
        ls3           : this.rdeaFile?.conclusion?.ls3 ? this.rdeaFile.conclusion.ls3 : false,
        sp4           : this.rdeaFile?.conclusion?.sp4 ? this.rdeaFile.conclusion.sp4 : false,
        en5           : this.rdeaFile?.conclusion?.en5 ? this.rdeaFile.conclusion.en5 : false
    }   
    })
  }

  editSuiviSIV(){
    this.editSuiviEvent.next(true)
  }

  getRetour(){
    this.retourEvent.next(true)
  }
}
