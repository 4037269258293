<mat-card appearance="outlined" (window:resize)="onResize($event)">
    <form [formGroup]="entiteForm">
        <mat-card-header>
            <mat-card-title>Création d'un cabinet</mat-card-title>
        </mat-card-header>
        <mat-card-content  class=".mat-card-content">
            <div class="containerL1" >
                <mat-form-field>
                    <mat-label>Type d'entité</mat-label>
                    <mat-select
                        formControlName="typeEntite" 
                        (selectionChange)="setTypeEntite($event.value)"
                        required
                        >
                        <mat-option *ngFor="let type of typesEntite" [value]="type" (select)="setTypeEntite(type)">{{ type.libelle }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div [hidden]="typeEntiteSelected.code==='ADELA'">
                    <mat-form-field >
                        <mat-label >Habilitation N°</mat-label>
                        <input  matInput 
                                type            = "text" 
                                formControlName = "habilitation" 
                                placeholder     = "Numéro d'habilitation"
                                maxlength       = "10"
                                [required]      = "habilitationRequired"
                                >
                            <mat-error>Le numéro d'habilitation est obligatoire</mat-error>
                    </mat-form-field>
                </div>
            </div>

        <div style="margin-top: 10px;">
            <app-siren controlKey="groupSiren" (adresseEvent) = "setAdresse($event)"></app-siren>
        </div>   
        <div style="margin-top: 30px;">
            <app-adresse controlKey = "groupAdresse" [adresse]="adresse"></app-adresse>
        </div>
            <div class="containerL4">
                <mat-form-field>
                    <mat-label for="tva">TVA</mat-label>
                    <mat-select formControlName="tva">
                        <mat-option *ngFor="let item of tvas" [value]="item.id">{{ item.libelle }} - {{ item.pourcentage }}%</mat-option>
                    </mat-select>
                   
                    <mat-error *ngIf="tva?.invalid && (tva?.dirty || tva?.touched)">La TVA est obligatoire</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label >mail facturation</mat-label>
                    <input  matInput 
                            type            = "text" 
                            formControlName = "mailfacturation" 
                            placeholder     = "adresse mail facturation"
                            required
                            >
                      <mat-error>L'adresse mail facturation est obligatoire</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label >mail contact</mat-label>
                    <input  matInput 
                            type            = "text" 
                            formControlName = "mailcontact" 
                            placeholder     = "adresse mail contact"
                            required
                            >
                      <mat-error>L'adresse mail de contact est obligatoire</mat-error>
                </mat-form-field>
            </div>
        <fieldset class="cadre" style="margin-top: 30px;">
            <legend class="legend">Dirigeant</legend>
                    <div class="containerDir">
                    
                        <mat-form-field id="agrement">
                                <mat-label for="agrement">Agrément</mat-label>
                                <input  matInput
                                        type            = "text"
                                        formControlName = "agrement" 
                                        placeholder     = "XXXXXX-VE" 
                                        maxlength       = "9"
                                        [required]      = "habilitationRequired"
                                        oninput         = "this.value = this.value.toUpperCase()">
                                <mat-error *ngIf="agrement?.invalid && (agrement?.dirty || agrement?.touched)">{{
                                    agrement?.errors?.erreur }}</mat-error>
                        </mat-form-field>
                    
                        <mat-form-field id="groupeUti">
                            <mat-label>Groupe d'utilisateurs</mat-label>
                            <mat-select required
                                        formControlName = "groupe"
                                        placeholder     = "Groupe d'utilisateurs"
                                        >
                                <mat-option *ngFor="let groupe of groupesDisplayed" [value]="groupe.id" >
                                    {{ groupe.nom }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="!entiteForm?.valid && entiteForm.touched">Vous devez sélectionner un type</mat-error>
                        </mat-form-field> 
                        <mat-form-field class="identite">
                            <mat-label>Nom </mat-label>
                            <input matInput 
                                    type            = "text" 
                                    required
                                    formControlName = "nom" 
                                    placeholder     = "Nom"
                                    oninput         = "this.value = this.value.toUpperCase()"
                                    maxlength       = "50"
                                    >
                            <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="identite">
                            <mat-label for="prenom">Prénom </mat-label>
                            <input  matInput
                                    appTitleCase
                                    type            = "text" 
                                    required
                                    formControlName = "prenom" 
                                    placeholder     = "Prénom"
                                    minlength       = "3"
                                    maxlength       = "50"
                                    >
                            <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
                        </mat-form-field>
                        <mat-form-field class="identite">
                            <mat-label >Email</mat-label>
                            <input  matInput 
                                    type            = "email" 
                                    required
                                    formControlName = "mail"
                                    placeholder     = "Email">
                            <mat-error *ngIf="mail?.invalid && (mail?.dirty || mail?.touched)">{{ mail?.errors?.erreur}}</mat-error>
                        </mat-form-field>
            
                    </div>
        </fieldset>

         <mat-hint>ATTENTION : Pour la TVA, sélectionnez "FR - 20%" pour les cabinets localisés en France métropolitaine, "DOM - 5,5%" pour les cabinets localisés dans les DOM ou "PAS - 0%" pour les cabinets sans TVA.<br/>
                                Ces informations seront contrôlées par la FFEA.
        </mat-hint>

        </mat-card-content>
        
    </form>

    <mat-card-actions align="end">
        <button mat-raised-button 
                color       = "primary"
                (click)     = "annuler()"
                >
            Annuler
        </button>

        <button *ngIf="!waiting"
                mat-raised-button 
                color       = "primary"
                [disabled]  = "entiteForm.invalid"
                (click)     = "enregistrer()"
                >
            Enregistrer
        </button>
        <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>

    </mat-card-actions>

</mat-card>
