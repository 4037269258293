import { ENTER,COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { Filtre } from 'src/app/interfaces/acquittement/filtre';
import { Suivi } from 'src/app/interfaces/acquittement/suivi';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAcquittementService } from 'src/app/services/api-acquittement.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { TableAcquittementComponent } from '../table-acquittement/table-acquittement.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ImmatDirective } from '../../../directive/immat.directive';
import { NgFor, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';


export interface TypeOperation {
  libelle: string;
  id: number;
};
@Component({
    selector: 'app-mes-acquittements',
    templateUrl: './mes-acquittements.component.html',
    styleUrls: ['./mes-acquittements.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatChipsModule, NgFor, NgIf, ImmatDirective, MatSelectModule, MatOptionModule, MatInputModule, MatButtonModule, MatProgressSpinnerModule, TableAcquittementComponent]
})
export class MesAcquittementsComponent implements OnInit {

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  selectable                  = true;
  removable                   = true;
  addOnBlur                   = true;

  // Gestion de l'affichage
  waitingResearch       : boolean = false;
  expandPannel          : boolean = true;

  // Liste des interfaces pour les combos
  profilUtilisateur  : ProfilUtilisateur = <ProfilUtilisateur>{};
  entites            : Entite[] = [];
  filterEntites      : Entite[] = [];
  type_operations    : TypeOperation[] = [{libelle: '1A', id: 1},{libelle: '1B', id: 2},{libelle: '1C', id: 3}];

  // Formulaire de filtrage
  filtre: Filtre = <Filtre>{};
  FiltreFormGroup: any;
  
  // Tableau de suivis
  suivis: Array<Suivi> = [];
  
  get immatriculations()  { return this.FiltreFormGroup.get('immatriculations'); }
  get numerosDossier()    { return this.FiltreFormGroup.get('numerosDossier'); }
  get entite()            { return this.FiltreFormGroup.get('entite'); }
  get type_operation()    { return this.FiltreFormGroup.get('type_operation'); }
  get date_debut()        { return this.FiltreFormGroup.get('date_debut'); }
  get date_fin()          { return this.FiltreFormGroup.get('date_fin'); }

  constructor(private _fb          : FormBuilder
            , private authUser     : ApiAuthentificationService
            , private apiAcquittement: ApiAcquittementService
            , private apiEntite    : ApiEntiteService
            , private toast        : PersoSnackbarService) { }

  ngOnInit(): void {
    this.profilUtilisateur = this.authUser.userConnect;
  
    // Récupération des paramètres du formulaire de filtrage
    this.chargeListeCabinet();

    // Initialisation du formulaire de filtrage
    this.FiltreFormGroup = this._fb.group({
      date_debut            : ['', [this.verificationFormatDateDebut.bind(this)]],
      date_fin              : ['', [this.verificationFormatDateFin.bind(this)]],
      immatriculations      : this._fb.array([]),
      numerosDossier        : this._fb.array([]),
      entite                : ['', [Validators.required]],
      type_operation        : [this.type_operations, [Validators.required]]      
    });

    this.changeChipsListValidators();
  }
  
  get immatControls() : FormArray{
    return this.FiltreFormGroup.controls['immatriculations'] as FormArray
  }

  get numerosDossierControls() : FormArray{
    return this.FiltreFormGroup.controls['numerosDossier'] as FormArray
  }

  removeImmat(immat: string): void {
    const index = this.immatControls.value.indexOf(immat);

    if (index >= 0) {
      this.immatControls.removeAt(index);
      this.changeChipsListValidators();
      this.immatControls.updateValueAndValidity();
      this.numerosDossierControls.updateValueAndValidity();
    }
  }

  removeDossier(dossier: string): void {
    const index = this.numerosDossierControls.value.indexOf(dossier);

    if (index >= 0) {
      this.numerosDossierControls.removeAt(index);
      this.changeChipsListValidators();
      this.numerosDossierControls.updateValueAndValidity();
      this.immatControls.updateValueAndValidity();
    }
  }

  addImmat(event: MatChipInputEvent): void {
    let value = event.value;

    // Add our immat
    if (value) {
      this.immatControls.push(this._fb.control(value));
      this.changeChipsListValidators();
      this.numerosDossierControls.updateValueAndValidity();
      this.immatControls.updateValueAndValidity();
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  addDossier(event: MatChipInputEvent): void {
    let value = event.value;

    // Add our dossier
    if (value) {
      this.numerosDossierControls.push(this._fb.control(value));
      this.changeChipsListValidators();
      this.numerosDossierControls.updateValueAndValidity();
      this.immatControls.updateValueAndValidity();
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  private verificationFormatDateDebut(fcControler: FormControl): any {
 
    if(this.FiltreFormGroup == undefined){
      return null;
    }
    
    let dateFin     : Date = new Date(this.FiltreFormGroup.controls.date_fin.value);
    let dateDebut   : Date = new Date(fcControler.value);
    let dateToday   : Date = new Date();
    let dateLastYear: Date = new Date(dateToday);    

    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 
    dateLastYear.setFullYear(dateLastYear.getFullYear() - 1);
    
    // if(isNaN(dateDebut.getTime())) { return {FormatDateDebut : 'Veuillez saisir une date de début postérieure à un an'}}

/*     if(dateDebut < dateLastYear) {
      return {FormatDateDebut : 'Date de début doit être postérieure à un an'}
    } */

    if(dateFin != undefined) {
      dateFin.setHours(23,59,59,999);
      if(dateDebut > dateFin) { return { FormatDateDebut: 'Date de début doit être antérieure à la date de fin' }}
    }        

    if(dateDebut.getTime() > dateToday.getTime()){
      return { FormatDateDebut: 'Date de début doit être antérieure à la date du jour' }
    }
    
    return null;
  }

  private verificationFormatDateFin(fcControler: FormControl): any {

    if(this.FiltreFormGroup == undefined){
      return null;
    }

    let dateFin   : Date = new Date(fcControler.value);
    let dateDebut : Date = new Date(this.FiltreFormGroup.controls.date_debut.value);
    let dateToday : Date = new Date();    
    
    dateDebut.setHours(0,0,0,0);
    dateToday.setHours(23,59,59,999); 
    dateFin.setHours(23,59,59,999);
    
    // if(isNaN(dateFin.getTime())) { return {FormatDateFin : 'Veuillez saisir une date de fin postérieure à la date de début ou antérieure à aujourd\'hui'}}

    if(dateFin.getTime() > dateToday.getTime() || (dateDebut.getTime() != null && dateFin.getTime() < dateDebut.getTime())){
      return { FormatDateFin: 'Veuillez saisir une date de fin postérieure à la date de début ou antérieure à aujourd\'hui' }
    }
    
    return null;
  }

  public onSearchingSuivi() { 
    
    this.waitingResearch = true;
    
    this.suivis = [];

    this.immatriculations.value.forEach((element:any) => {
      element.replaceAll('-','')
    });

    this.filtre = {
      date_debut      : this.FiltreFormGroup.controls.date_debut.value,
      date_fin        : this.FiltreFormGroup.controls.date_fin.value,
      immatriculation : [],
      numerosDossier  : [],
      habilitation    : [],  
      type_operation  : this.FiltreFormGroup.controls.type_operation.value
    };
    this.immatriculations.value.forEach((element:any) => {
      this.filtre.immatriculation.push(element.replaceAll('-',''));
    });
    this.numerosDossier.value.forEach((element:any) => {
      this.filtre.numerosDossier.push(element);
    });
    if (this.entite.value == undefined || (this.entite.value.id == 0 && this.entite.value.habilitation == '') ) {
      this.entites.forEach(entite => {
        // If avec includes pour ne pas envoyer de numéro d'habilitation en doublon au web service
        if (entite.habilitation != '' && !this.filtre.habilitation.includes(entite.habilitation)) {
          this.filtre.habilitation.push(entite.habilitation);
        }  
      });
    } else {
      this.filtre.habilitation = [this.FiltreFormGroup.controls.entite.value.habilitation];
    }


    this.apiAcquittement.postSuivi(this.filtre)
      .subscribe(
        (data: Suivi[]) => {
          console.log(data);
          
          this.suivis           = data;          
          this.waitingResearch  = false;
          this.expandPannel     = false;
          this.toast.showInfo('Recherche terminée');
        },
        (err: any) => {
          console.error('/!\ err: ', err.message);
          this.waitingResearch = false;
        }
      );
  }

  private chargeListeCabinet() {
    if ( this.profilUtilisateur.droits_utilisateur.est_admin) {
      this.apiEntite.getAllEntite()
      .subscribe(
        (data: Entite[] ) => {              
          this.entites = data;
          this.FiltreFormGroup.patchValue({
            entite                : this.entites[0]
          });
          this.filterEntitesOfType(true);
        }
      );
    } else {
      this.entites.push(<Entite>{raison_sociale:'Tous', id:0, habilitation:'', EstInscrit: true, EstActif:true});
      for(let i = 0; i < this.authUser.relUserEnt.length; i++) {
        if(this.authUser.relUserEnt[i].est_active) {
          this.entites.push(this.authUser.relUserEnt[i].entite);
        }            
      }
      this.filterEntitesOfType(true);
    }
  }

  private filterEntitesOfType(type: boolean){
    this.filterEntites = this.entites.filter(entite => entite.EstActif == type && entite.EstInscrit == true);
  }

  private changeChipsListValidators() {

    if (this.immatriculations.value.length > 0 && this.numerosDossier.value.length == 0) {
      this.immatControls.setValidators([Validators.min(1), Validators.required]);
      this.numerosDossierControls.setValidators([Validators.min(0)]);
    }

    if (this.immatriculations.value.length == 0 && this.numerosDossier.value.length > 0) {
      this.immatControls.clearValidators();
      this.numerosDossierControls.setValidators([Validators.min(1), Validators.required]);
    }

    if (this.immatriculations.value.length == 0 && this.numerosDossier.value.length == 0) {
      this.immatControls.setValidators([Validators.min(1), Validators.required]);
      this.numerosDossierControls.setValidators([Validators.min(1), Validators.required]);
    }
    
    this.FiltreFormGroup.updateValueAndValidity();
  }

}