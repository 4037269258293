<mat-card>
    <form [formGroup]="filtreMissionForm">
        <div class="div-container-left">
            <app-entite-list    [fieldName]         = "'Cabinet'"
                (entiteEmitter)     = "onSetEntite($event)"
                [CoordBanqDisabled] = "false"
                        >
            </app-entite-list>
            <mat-form-field >
                <mat-label>N° de sinistre</mat-label>
                <input 
                    formControlName = "numeroSinistre"
                    matInput 
                    >
            </mat-form-field>
            
            <mat-form-field class="formDate">
                <mat-label>Date de début</mat-label>
                <input matInput type="date" formControlName="dateDebut">
            </mat-form-field>

            <mat-form-field class="formDate">
                <mat-label>Date de fin</mat-label>
                <input matInput type="date"  formControlName="dateFin">
            </mat-form-field>

            <button 
                *ngIf="!waiting"
                mat-raised-button 
                color       = "primary" 
                (click)     = "getRedirections()"
                [disabled]  = "!entite"
                >Rechercher les redirections</button>
            <mat-spinner
                *ngIf="waiting" 
                diameter="24"
            ></mat-spinner>
        </div>
    </form>
    <div class="content">
        <div class="titlebox">Missions à rediriger</div>
        <mat-spinner 
            *ngIf       = "waitingRemiseADisposition"
            diameter    = "24" 
            style       = "margin:auto"
            ></mat-spinner>
        <mat-table 
            [dataSource]    = "dataSourceRedirections" 
            matTableExporter
            #exporter       = "matTableExporter"
            >
            
            <!-- date de redirection --> 
            <ng-container matColumnDef="dateRedirection">
                <mat-header-cell *matHeaderCellDef > Redirigée le </mat-header-cell>
                <mat-cell *matCellDef="let redirection" > {{ redirection.dateRedirection | date: 'dd/MM/yyy'}} </mat-cell> 
            </ng-container>            

            <!-- numéro de sinistre -->
            <ng-container matColumnDef="numeroSinistre">
                <mat-header-cell *matHeaderCellDef > N° sinistre </mat-header-cell>
                <mat-cell *matCellDef="let redirection">
                    {{ redirection.numeroSinistre }}
                </mat-cell>
            </ng-container>

            
            <!-- habilitation -->
            <ng-container matColumnDef="habilitation">
                <mat-header-cell *matHeaderCellDef > Code Expert </mat-header-cell>
                <mat-cell *matCellDef="let redirection" > {{ redirection.nouveauCode }} </mat-cell>
            </ng-container>

            <!-- Raison sociale --> 
            <ng-container matColumnDef="raisonSociale">
                <mat-header-cell *matHeaderCellDef > Raison sociale </mat-header-cell>
                <mat-cell *matCellDef="let redirection" > {{ redirection.raisonSociale }} </mat-cell> 
            </ng-container>     

            <!-- Remettre à disposition -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="text-align: right;"></th>
                <td mat-cell *matCellDef="let redirection" class="colonne-module" style="text-align: right;">
                    <button 
                        mat-icon-button 
                        *ngIf       = "!waitingRemiseADisposition"
                        class       = "button-etat" 
                        color       = "warn"
                        style       = "width: 48px; height: 48px;"
                        matTooltip  = "Remettre à disposition cette mission"
                        (click)     = "remiseADisposition(redirection)"
                        >
                        <mat-icon color="warn">undo</mat-icon>
                    </button>
                    
                
                </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsRedirections"></mat-header-row>
            <mat-row 
                matRipple 
                *matRowDef="let mission; columns: displayedColumnsRedirections;"
                
                ></mat-row>
        </mat-table>

        <div class="div-container-between" style="align-items: center;background-color:white">
            <app-export-table 
                    [fileExportName]    = "'Historique redirections'" 
                    [exporter]          = "exporter" 
                    [nb_ligne]          = "this.dataSourceRedirections.data.length"
                    ></app-export-table>
            <mat-paginator
            #paginator  = "matPaginator"
            [pageSizeOptions]   = "[5,10,25]"
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
        </div>

      
       
    </div>
</mat-card>

