import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgModel, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Filtre } from 'src/app/components/facture/mes-factures/mes-factures.component';
import { EtatFacture } from 'src/app/interfaces/facture/etat-facture';
import { FiltreInitial } from 'src/app/services/api-facture.service';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-facture-filtre',
    templateUrl: './facture-filtre.component.html',
    styleUrls: ['./facture-filtre.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule]
})
export class FactureFiltreComponent implements OnInit {
  @Input()  etats         :  EtatFacture[] = []
  @Output() filterSelected = new EventEmitter<Filtre>();
  
  filterDateDebut   : string = ''
  filterDateFin     : string = ''
  filterNumFacture  : string = ''
  public etatsSelected     : number[]=[]
  

  constructor() { }

  ngOnInit(): void {
    this.initDates()
  }

  initDates() {
    const today     = new Date();
    const lastYear  = new Date(today);
    lastYear.setFullYear(today.getFullYear() - 1);
    this.filterDateDebut  = lastYear.toISOString().substring(0, 10)
    this.filterDateFin    = today.toISOString().substring(0, 10)
   
  }

  selectFilter(){
    const FiltreFacture:Filtre = {...FiltreInitial,"debut":this.filterDateDebut,"fin":this.filterDateFin,"numero":this.filterNumFacture,"etats":this.etatsSelected}
    this.filterSelected.emit(FiltreFacture)
  }
}
