<mat-card appearance="outlined" class="mat-card-no-shadow">
    <mat-expansion-panel [(expanded)]="ExpandPannel">
    
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title class="label-accent">
                <mat-icon>search</mat-icon>Veuillez sélectionner un service et les éventuelles options de votre recherche...
            </mat-panel-title>
        </mat-expansion-panel-header>
    
        <form [formGroup]="formGroupFiltre" (submit)="getPhrasiers()">            
            <mat-form-field>
                <mat-label>Catégorie</mat-label>
                <mat-select name="categorie" id="categorie" formControlName="categorie" (selectionChange)="onSelectChangeCategorie($event)">
                    <mat-option>Tous</mat-option>
                    <mat-option *ngFor="let categorie of categories" [value]="categorie">
                        {{ categorie.libelle }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Sous-catégorie</mat-label>
                <mat-select name="sous_categorie" id="sous_categorie" formControlName="sous_categorie">
                    <mat-option>Tous</mat-option>
                    <mat-option *ngFor="let sous_categorie of sous_categoriesFiltre" [value]="sous_categorie">
                        {{ sous_categorie.libelle }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="div-button-center">
                <div class="button-container">                    
                    <button 
                        mat-raised-button 
                        *ngIf       = "!waitingResearch"
                        color       = "primary" 
                        [disabled]  = "waitingResearch || !formGroupFiltre.valid"
                        >
                        <mat-icon>search</mat-icon>
                        Rechercher
                    </button>
                    <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                </div>
            </div>
        </form>                               
    </mat-expansion-panel>
</mat-card>

<mat-card appearance="outlined" class="mat-card">
    <mat-card-subtitle>Phrasiers</mat-card-subtitle>

    <div [hidden]="!showAllTable">         
        <button mat-raised-button (click)="onCreerPhrasier()" color="primary" type="submit">
            <mat-icon>add</mat-icon>
            <span class="mdc-button__label">Nouvelle phrase</span>
      </button>
    </div>

    <div class="container">
        <table mat-table matTableResponsiveLarge [dataSource]="dataSource" class="table-phrasier">

            <!-- Catégorie Column -->
            <ng-container matColumnDef="categorie">
                <th mat-header-cell *matHeaderCellDef> Catégorie </th>
                <td mat-cell *matCellDef="let phrasier">
                    <b>{{phrasier.categorie.libelle}}</b>
                </td>
            </ng-container>

            <!-- Sous-catégorie Column -->
            <ng-container matColumnDef="sous-categorie">
                <th mat-header-cell *matHeaderCellDef> Sous-catégorie </th>
                <td mat-cell *matCellDef="let phrasier"> {{phrasier.sous_categorie.libelle}} </td>
            </ng-container>

            <!-- Titre Column -->
            <ng-container matColumnDef="titre">
                <th mat-header-cell *matHeaderCellDef> Titre </th>
                <td mat-cell *matCellDef="let phrasier"> {{phrasier.titre}}</td>
            </ng-container>

            <!-- Phrase Column -->
            <ng-container matColumnDef="phrase">
                <th mat-header-cell *matHeaderCellDef> Phrase </th>
                <td mat-cell *matCellDef="let phrasier"> <i>{{phrasier.phrase}}</i> </td>
            </ng-container>

            <!-- Bouton Coller Column -->
            <ng-container matColumnDef="coller">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let phrasier">
                    <button mat-icon-button (click)="onCopyContentPhrasier(phrasier.phrase)" color="primary">
                        <mat-icon>content_copy</mat-icon>
                    </button>                    
                </td>
            </ng-container>

            <!-- Bouton Modifier Column -->
            <ng-container matColumnDef="modifier">
                <th mat-header-cell *matHeaderCellDef [hidden]="!showAllTable"></th>
                <td mat-cell *matCellDef="let phrasier" [hidden]="!showAllTable">
                    <button mat-icon-button (click)="onModifierPhrasier(phrasier)" color="primary">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Bouton Supprimer Column -->
            <ng-container matColumnDef="supprimer">
                <th mat-header-cell *matHeaderCellDef [hidden]="!showAllTable"></th>
                <td mat-cell *matCellDef="let phrasier" [hidden]="!showAllTable">
                    <button mat-icon-button (click)="onSupprimerPhrasier(phrasier)" color="warn">
                        <mat-icon>delete</mat-icon>
                    </button>                    
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10,25,50]" showFirstLastButtons></mat-paginator> 
    </div>
</mat-card>