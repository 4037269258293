
<mat-card appearance="outlined">
      <mat-card-title>
            <mat-icon color="primary">apartment</mat-icon>&nbsp; Entité(s)
      </mat-card-title>

      <mat-card-content  class=".mat-card-content">
            <mat-label *ngIf="listRelUltEntFiltered.length <= 0 || listRelUltEntFiltered == undefined">
                  Vous n'avez pas d'entité rattachée à votre compte.
            </mat-label>
            
            <mat-accordion stopPropagation *ngFor="let rel of listRelUltEntFiltered, index as indiceItem">
                  
                  <mat-expansion-panel *ngIf="rel.est_active == true && rel.entite.EstInscrit == true" hideToggle="false">
                        <mat-expansion-panel-header style="padding-top: 4px !important; padding-bottom: 4px !important;">
                              <mat-panel-title>
                                    <div class=".div-container-left">
                                          <mat-label class="grey-icon">{{ indiceItem + 1 }}. </mat-label>
                                          <mat-icon class="img-logo grey-icon" *ngIf="!rel.entite.logo">apartment</mat-icon>
                                          <img class="img-logo" style="margin-right: 8px; margin-left: 8px;" 
                                                *ngIf="rel.entite.logo" 
                                                [src]="_sanitizer.bypassSecurityTrustResourceUrl(rel.entite.logo)">
                                          {{ rel.entite.raison_sociale }}
                                    </div>                                                                        
                              </mat-panel-title>

                              <mat-panel-description class="panel-description">
                                    <mat-label>{{ getLabelRoleEntite(rel.est_dirigeant) }}</mat-label>

                                    <div class="panel-description-entite-details">
                                          <button style="margin-right: 5px;" *ngIf="rel.entite_id > 0 && rel.est_dirigeant === true && rel.entite.id_type_entite == type_id_entite_principale" mat-raised-button stopPropagation (click)="onAddSuccursaleToEntite( rel.entite.id )" color="primary" type="submit">
                                                <mat-icon>domain_add</mat-icon>
                                                <span class="mdc-button__label">Créer une succursale</span>
                                          </button>
      
                                          <button mat-raised-button stopPropagation (click)="openEntite( rel.entite.id, $event )" color="primary" type="submit">
                                                <mat-icon>zoom_in</mat-icon>
                                                <span class="mdc-button__label">Détails</span>
                                          </button>            
                                    </div>
                              </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <mat-card-content>
                              <div class="div-container">
                                    <app-carte-adresse [adresse]="{'ligne1' :rel.entite.adresse,'ligne2':rel.entite.adresse_cplt, 'ligne3':rel.entite.adresse_ligne3,'cp':rel.entite.code_postal,'ville':rel.entite.ville,'contact':rel.entite.numero_client, 'nom':''}"></app-carte-adresse>
                                    <div  style="width: 400px;">
                                          <div class="info" *ngIf="rel.entite.telephone!=''">
                                                <mat-icon class="icon" color="primary">phone</mat-icon><span>{{ rel.entite.telephone|phoneFormat }}</span>
                                          </div>
                                          <div class="info" *ngIf="rel.entite.mail_facturation!=''">
                                                <mat-icon class="icon" color="primary">mail</mat-icon><span>{{ rel.entite.mail_facturation }}</span>
                                          </div>
                                    </div>
                              </div>
                              
                        </mat-card-content>

                        <app-liste-succursale [RelUtilisateurEntite]="getSuccursale(rel.entite_id)" [est_dirigeant]="rel.est_dirigeant" *ngIf="!isServiceFFEA && rel.entite_id > 0 && rel.entite.id_type_entite == type_id_entite_principale"></app-liste-succursale>

                        <mat-action-row class="action-row">         
                              <div *ngIf="!isServiceFFEA">
                                    <button style="margin-right: 5px;" *ngIf="rel.entite_id > 0 && rel.est_dirigeant === true && rel.entite.id_type_entite == type_id_entite_principale" mat-raised-button stopPropagation (click)="onAddSuccursaleToEntite( rel.entite.id )" color="primary" type="submit">
                                          <mat-icon>domain_add</mat-icon>
                                          <span class="mdc-button__label">Créer une succursale</span>
                                    </button>
                              </div>                           
      
                              <button mat-raised-button stopPropagation (click)="openEntite( rel.entite.id, $event )" color="primary" type="submit">
                                    <mat-icon>zoom_in</mat-icon>
                                    <span class="mdc-button__label">Détails</span>
                              </button>              
                        </mat-action-row>
                  </mat-expansion-panel>

            </mat-accordion>

      </mat-card-content>
</mat-card>

