<mat-card>
  <mat-card-title>
      <div class="div-container-left">
            <mat-icon>report</mat-icon>
            <span>{{ getPageTitle() }}</span>
      </div>
  </mat-card-title>
    <br>
    <mat-card-content>
      <div *ngFor="let paramVal of utiParamVals; let i = index">
        <app-parametre-utilisateur [idUtilisateur]="idUtilisateur" [idEntite]="idEntite" [inputParamVal]=paramVal></app-parametre-utilisateur>
      </div>       
    </mat-card-content>
</mat-card>
