import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiRelUtilisateurEntiteService } from 'src/app/services/api-rel-utilisateur-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiConcentrateurService } from '../../../../services/api-concentrateur.service';
import { SuiviService } from '../../data_access/suivi.service';
import { RDEAFile, RDEAInit } from '../../interfaces/rdeaFile';
import { References, ReferencesInit } from '../../interfaces/reference';
import { Suivi, SuiviFiltre, SuiviSiv } from '../../interfaces/suivi';
import { EditSuiviComponent } from '../edit-suivi/edit-suivi.component';
import { FilterSuiviComponent } from '../filter-suivi/filter-suivi.component';
import { ListSuiviSivComponent } from '../list-suivi-siv/list-suivi-siv.component';
import { ListSuiviComponent } from '../list-suivi/list-suivi.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-shel-suivi',
  templateUrl: './shel-suivi.component.html',
  styleUrls: ['./shel-suivi.component.scss'],
  standalone:true,
  imports:[FilterSuiviComponent,ListSuiviComponent,EditSuiviComponent, NgIf,ListSuiviSivComponent,NgSwitch,NgSwitchCase]
})

export class ShelSuiviComponent {

  readonly VIEW_FILTER  = 1;
  readonly VIEW_EDIT    = 2;
  readonly VIEW_SIV     = 3;

  apiRelUti = inject(ApiRelUtilisateurEntiteService)
  apiSuivi  = inject(SuiviService)
  apiAuth   = inject(ApiAuthentificationService)
  apiConcentrateur   = inject(ApiConcentrateurService)
  apiToast  = inject(PersoSnackbarService)
  

  suivis        : Suivi[]                       = []
  suivisSiv     : SuiviSiv[]                    = []
  relUtiEntites : RelationUtilisateurEntite[]   = []
  entites       : Entite[]                      = []
  modeView      : number                        = this.VIEW_FILTER
  modeViewBack  : number                        = this.VIEW_FILTER
  references    : References                    = ReferencesInit
  errors        : string[]                        = []
  rdeaFile!     : RDEAFile
  idSuivi       : number                        = 0
  errorList     : boolean                       = false
  suiviBack!    : Suivi
  waiting       : boolean                       = false                

  currentElement: string='' ;

  constructor(private activatedRoute: ActivatedRoute
              , private router      : Router
  ){}

  ngOnInit(){
    this.activatedRoute.url.subscribe(url => {
      
      const elements    = this.router.url.split('/');
      this.currentElement = elements[elements.length - 1];
      
      switch (this.currentElement) {
        
        case 'rdea-suivi':
          this.modeView = this.VIEW_FILTER
          break;
        
          case 'edit':
          this.modeView = this.VIEW_EDIT
          break;
        
          case 'siv':
          this.modeView = this.VIEW_SIV
          break;

        default:
          break;
      }
    });

    this.apiRelUti.getRelUtilisateurEntite(this.apiAuth.userConnect.id,0).subscribe(
      (relUtiEntites:RelationUtilisateurEntite[])=>{
        this.relUtiEntites = relUtiEntites
        this.entites = this.relUtiEntites.map((rel:RelationUtilisateurEntite)=>rel.entite)
      }
    )

    this.apiSuivi.getReferences().subscribe(
      (data:References)=>{
        this.references = data
      }
    )
    
  }
  applyFilter(suiviFilter: SuiviFiltre){
    this.apiSuivi.getSuivis(suiviFilter).subscribe(
      (suivis:Suivi[])=> {
        this.suivis = suivis
        if (this.suivis.length===0) {
          this.apiToast.showInfo('Aucun résultat ne correspond à vos critères de recherche')
        }
      }
    )
  }

  archiver(suivi:Suivi){
    this.apiSuivi.archiveSuivi(suivi).subscribe(
      (suivi:Suivi)=>this.suivis = this.suivis.map((sui:Suivi)=> sui.id===suivi.id? suivi : sui)
    )
  }

  editSuiviSivFromList(suivi:Suivi){
    this.modeViewBack = this.VIEW_FILTER
    this.editSuiviSiv(suivi)
  }

  editSuiviSivFromEdit(){
    this.editSuiviSiv(this.suiviBack,this.VIEW_EDIT)
  }
  
  editSuiviSiv(suivi:Suivi,modeBack:number=this.VIEW_FILTER){
    
    const filtreSiv:SuiviFiltre = {'idEntite':suivi.id_entite,'dossier':suivi.dossier}
    this.apiSuivi.getSuiviSiv(filtreSiv).subscribe(
      (suivisSiv:SuiviSiv[])=>{
        this.modeViewBack = modeBack
        this.suiviBack  = suivi
        this.suivisSiv  = suivisSiv
        this.modeView   = this.VIEW_SIV
        this.router.navigate(['app/rdea-suivi/siv']);
      }
      ,(error)=>console.log(error)
    )
  }

  editSuivi(suivi:Suivi){
    this.errorList = false
    this.idSuivi = 0
    if (suivi.id!=0) {
      this.apiSuivi.getDossier(suivi.id!).subscribe(
        (rdeaFile:RDEAFile)=>{
          
          this.modeViewBack = this.VIEW_FILTER
          this.suiviBack  = suivi
          this.rdeaFile   = rdeaFile
          this.modeView   = this.VIEW_EDIT
          this.router.navigate(['app/rdea-suivi/edit']);
          this.idSuivi    = suivi.id!
        }
        ,(error)=>{
          this.errorList = true
          this.apiToast.showError('impossible d\'éditer le dossier')
        }
      )
    }else{
      // const RDEAEMPTY = {} as RDEAFile
      this.rdeaFile   = RDEAInit
      this.modeView = this.VIEW_EDIT
      this.router.navigate(['app/rdea-suivi/edit']);
    }
  }

  
  sendFile(rdeaFile:RDEAFile){
    this.waiting = true
    this.errors = []

    const entite      = this.getEntite(rdeaFile.information.idEntite)
    let suivi:Suivi = {
        'id' : this.idSuivi
      , 'id_entite' : rdeaFile.information.idEntite
      , 'dossier'   : rdeaFile.reference.numeroDossier
      , 'immat'     : rdeaFile.technique.immatriculation.replaceAll('-','')
      ,'statut'     : this.apiSuivi.CONST_STATUT_ENCOURS
      ,'date_envoi' : new Date()
    }

    this.apiConcentrateur.sendFile(rdeaFile,entite).pipe(
        mergeMap((responseSIV:any)=> {
            if (responseSIV.description_erreur?.length>0) {
              this.errors = responseSIV.description_erreur.map((error:any)=> error.item_erreur.message_erreur)
            } else {
              if (rdeaFile.information.type === '4') {suivi.statut = this.apiSuivi.CONST_STATUT_TERMINE;}
            }
            return this.apiSuivi.saveSuivi(suivi);
        })
      , catchError(error=>{return throwError(error)})
    ).subscribe(
      (data:any)=>{
        if (this.errors.length>0) {
          this.apiToast.showError('Votre dossier a bien été déposé auprès du Concentrateur mais qui l\'a rejeté suite aux erreurs rencontrées')
        }else{
          this.apiToast.showInfo('Votre dossier a bien été déposé auprès du Concentrateur')
        }
        this.waiting = false;
        this.idSuivi = data.id;
      }
      ,(error)=>{
        this.waiting = false;

        if (error.status<404) {
          this.errors= this.apiConcentrateur.formatErrors(error.error)
        }else if (error.status=500) {
          this.apiToast.showError('Une erreur est survenue lors du dépôt d’expertise.Contacter le support technique de la FFEA.')
        }
      }
    )
  }

  getRetour(){
    this.modeView = this.modeViewBack
  }

  getRetourFromEdit(){
    this.modeView = this.VIEW_FILTER
    this.router.navigate(['app/rdea-suivi']);
  }


  getEntite(idEntite:number):Entite{
    return this.entites.find((entite:Entite)=>entite.id===idEntite)!
  }
}
