import { Adresse } from "../adresse/adresse";
import { CoordonneesBancaire, CoordonneesBancaireInit } from "./coordonnees-bancaire";
import { EntiteAccesConcentrateur, EntiteAccesConcentrateurInit } from "./entite-acces-concentrateur";
import { TypeEntite } from "./type-entite";

export interface Entite {
      id?                     : number,
      idexterne               : number,
      id_entite_principale    : number,
      raison_sociale          : string,
      logo                    : string,
      id_type_entite          : number,
      typ_ent_nom             : string,
      typ_ent_code            : string,
      habilitation            : string,
      indice                  : string,
      compteTiers             : string,
      siren                   : string,
      siret                   : string,
      adresse                 : string,
      adresse_cplt            : string,
      adresse_ligne3          : string,
      ville                   : string,
      code_postal             : string,
      telephone               : string,
      fax                     : string,
      mail_facturation        : string,
      mail_statistique        : string,
      mail_contact            : string,
      EstActif                : boolean,
      EstInscrit              : boolean,
      numero_client           : string,
      idAdresse               : number,
      historique_coordonnees_bancaire: Array<CoordonneesBancaire>,
      tva? : {
            id: number,
            libelle: string,
            pourcentage: string,
      },
      coordonnees_bancaire    : CoordonneesBancaire;
      acces_concentrateur     : EntiteAccesConcentrateur;
      adresses? : Adresse[]
      typeEntite?:TypeEntite
      idModePaiementDefaut?   : number
}

export const EntiteInit : Entite = {
       'EstActif'                         : true
      , 'EstInscrit'                      : true
      , 'acces_concentrateur'             : EntiteAccesConcentrateurInit
      , 'adresse'                         : ''
      ,'adresse_cplt'                     : ''
      , 'adresse_ligne3'                  : ''
      , 'code_postal'                     : ''
      , 'compteTiers'                     : ''
      , 'coordonnees_bancaire'            : CoordonneesBancaireInit
      , 'fax'                             : ''
      , 'habilitation'                    : ''
      , 'historique_coordonnees_bancaire' : []
      , 'id_entite_principale'            : 0
      , 'id_type_entite'                  : 0
      , 'idexterne'                       : 0
      , 'indice'                          : ''
      , 'logo'                            : ''
      , 'mail_contact'                    : ''
      , 'mail_facturation'                : ''
      , 'mail_statistique'                : ''
      , 'numero_client'                   : ''
      , 'raison_sociale'                  : ''
      , 'siren'                           : ''
      , 'siret'                           : ''
      , 'telephone'                       : ''
      , 'typ_ent_nom'                     : ''
      , 'typ_ent_code'                    : ''
      , 'ville'                           : ''
      , 'idAdresse'                       : 0
}
