import { SelectionModel } from '@angular/cdk/collections';
import { NgIf, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableDataSourcePaginator, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { MatTableExporterModule } from 'mat-table-exporter';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatTableResponsiveLargeDirective } from '../../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { ExportTableComponent } from '../../../templates/export-table/export-table.component';
import { MassUpdateListeCabinetsComponent } from '../mass-update-liste-cabinets/mass-update-liste-cabinets.component';
import { ContexteService, TYPE_CABINET } from 'src/app/services/contexte.service';

@Component({
    selector: 'app-table-liste-cabinets',
    templateUrl: './table-liste-cabinets.component.html',
    styleUrls: ['./table-liste-cabinets.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule
              , MatCheckboxModule, NgStyle, MatTooltipModule, ExportTableComponent, MatPaginatorModule, NgIf]
})
export class TableListeCabinetsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | MatTableDataSourcePaginator| null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource: any;
  @Output() editionEvent = new EventEmitter<boolean>()

  dataSourceMatTable  : MatTableDataSource<Entite> = new MatTableDataSource<Entite>();
  expandPannel        : boolean = true;
  displayedColumns    : Array<string> = ['select', 'typ_ent_nom','ent_habilitation', 'ent_numero_client', 'ent_raison_sociale' , 'ent_active', 'ent_code_postal', 'ent_ville', 'ent_siren', 'actions'];
  selection           = new SelectionModel<Entite>(true, []);

  constructor(
      private route           : Router
    , private toast           : PersoSnackbarService
    , private apiEntite       : ApiEntiteService
    , public dialog           : MatDialog
    , public apiAuthUser      : ApiAuthentificationService
    , private contexte        : ContexteService
    , private cdr             : ChangeDetectorRef 
  ) {}

  ngOnInit(): void {
    this.contexte.setType(TYPE_CABINET)
  }
  
  ngAfterViewInit() {
    this.paginator!.pageIndex = this.contexte.getPageIndex(TYPE_CABINET)
    this.dataSourceMatTable.paginator = this.paginator
    this.cdr.detectChanges();
  }
  
  ngOnChanges() {
    this.dataSourceMatTable.paginator = this.paginator;
    // obligatoire
    if(this.dataSource != undefined) {
      this.contexte.setTableData(this.dataSource)
      this.dataSourceMatTable.data = this.contexte.getTableData(TYPE_CABINET);
      this.selection.clear();
    }     
  }

  public onClickOuvrir(ent_id: number): void {
    //on mémorise d'abord le contexte
    this.contexte.setPageIndex(this.dataSourceMatTable.paginator!.pageIndex)  
    
    //on route vers la page de l'entité
    this.route.navigate(['/app/entite', ent_id])
  }

  public addEntite(entite:Entite){
    
    this.apiEntite.addEntite(entite)
    this.route.navigate(['app/suspension/entite/', entite.id])
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceMatTable.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceMatTable.data);
  }

  private getSelectedCabinets() : Array<Entite> {
    let selectedCabinets    : Array<Entite> = [];

    for(let i = 0; i < this.dataSourceMatTable.data.length; i++) {
      if(this.selection.isSelected(this.dataSourceMatTable.data[i])) {
        selectedCabinets.push(this.dataSourceMatTable.data[i]);
      }
    }

    return selectedCabinets;
  }

  public onClikActiver() : void {
    this.onClickAction('activate');
  }

  public onClikDesactiver() : void {
    this.onClickAction('deactivate');
  }

  public onClikIdentifiant() : void {
    this.onClickAction('identifiant');
  }

  private onClickAction(action: string) {
    let selectedCabinets    : Array<Entite> = this.getSelectedCabinets(); 

    if (selectedCabinets.length > 0) {
      const dialogRef = this.dialog.open(MassUpdateListeCabinetsComponent, {data : {type: action, dataSource_Cabinets: selectedCabinets}});

      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.apiEntite.isDataModify.next(true);
          this.selection.clear();
        }        
      });

    } else {
      this.toast.showAlert('Vous devez sélectionner au moins un cabinet'); 
    }
  }

  public onCreer(){
    this.editionEvent.next(true)


  }
}
