import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {
  title = 'FFEA';

  constructor(private titleService: Title
            , private matIconRegistry : MatIconRegistry
            , private domSanitizer    : DomSanitizer) {

    // Icônes utilisées dans les exports de table (module mat-table-exporter)
    // nécessitant d'être utilisé en tant que SVG, et non comme des ligatures habituellement.
    // Il faut les ajouter dans le répertoire des assets (svg : 24px à télécharger sur le site de Google / Material Icon)
    this.matIconRegistry.addSvgIcon(
      `fiber_new`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/fiber_new.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `send`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/send.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `cancel`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/cancel.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `check_small`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/check_small.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `folder`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/folder.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `folder_special`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/folder_special.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `key`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/key.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `filter_center_focus`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/filter_center_focus.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `done`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/done.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `close`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/close.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `file_download_off`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/file_download_off.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `download`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/download.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `invert_colors`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/invert_colors.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `crop_free`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/crop_free.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `shopping_cart`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/shopping_cart.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `work`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/work.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `table_view`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/table_view.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `add_shopping_cart`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/add_shopping_cart.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `home_repair_service`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/home_repair_service.svg`)
    );
  }
              

  ngOnInit() {
  }

  setDocTitle(title: string) {
    this.titleService.setTitle(title);
 }
}
