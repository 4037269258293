import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { environment } from 'src/environments/environment';
import { Deficience, RDEAFile, ZoneChoc } from '../components/rdea/interfaces/rdeaFile';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiConcentrateurService {

  apiUrl:string = 'api/rdea'
  private http: HttpClient;
  apiAuth = inject(ApiAuthentificationService)

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  formatErrors(erreur:string){
    let erreurs : string[] = erreur.split(/\r?\n|\r|\n/g)
    return erreurs
  }
  sendFile(rdeaFile:RDEAFile,entite:Entite){
    
    const password      = entite.acces_concentrateur.mot_de_passe
    const login         = entite.acces_concentrateur.habilitation
    const authorization = btoa(login + ':' + password)
    const header        = {headers:new HttpHeaders().set('Authorization',`Basic ` +authorization).set('Referrer-Policy','same-origin')}
    const rdeaJson      = this.getRdeaJsonFile(rdeaFile,entite)

    return this.http.post(environment.urlApiConcentrateur+'dossier',rdeaJson,header)
  }

  getRdeaJsonFile(rdeaFile:RDEAFile,entite:Entite){
    let date_examen_1A    : Date|null
    let date_examen_1B    : Date|null
    let date_examen_1C    : Date|null
    let date_rapport_1A   : Date
    let date_rapport_1B   : Date|null
    let date_rapport_1C   : Date|null
    let demande_fo        : string = ''
    let dangereux_1A      : string|null = 'nc'
    let dangereux_1B      : string|null = 'nc'
    let pointsChocs       : ZoneChoc[] = []
    let nbPointsChocs     : number = 0  
    let deficiences       : Deficience[] = []
    let type_operation    : string = ''
    let reparable_1A      : string|null = 'nc'
    let reparable_1B      : string|null = 'nc'

    if (rdeaFile.expertise.chocArriere) {
      this.setPointChoc('chocArriere',pointsChocs)
    }

    if (rdeaFile.expertise.chocArriereD) {
      this.setPointChoc('chocArriereD',pointsChocs)
    }

    if (rdeaFile.expertise.chocLateralD) {
      this.setPointChoc('chocLateralD',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocAvantD) {
      this.setPointChoc('chocAvantD',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocArriereG) {
      this.setPointChoc('chocArriereG',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocLateralG) {
      this.setPointChoc('chocLateralG',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocAvantG) {
      this.setPointChoc('chocAvantG',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocAvant) {
      this.setPointChoc('chocAvant',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocHabitacle) {
      this.setPointChoc('chocHabitacle',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocMoteurBvPonts) {
      this.setPointChoc('chocMoteurBvPonts',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocTrainsRoulants) {
      this.setPointChoc('chocTrainsRoulants',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocSoubassement) {
      this.setPointChoc('chocSoubassement',pointsChocs)
    }
    
    if (rdeaFile.expertise.chocDessus) {
      this.setPointChoc('chocDessus',pointsChocs)
    }
    
    if (rdeaFile.expertise.ensemble) {
      this.setPointChoc('ensemble',pointsChocs)
    }
    
    nbPointsChocs = pointsChocs.length

    //Deficiences:
    let dateDangerosite:Date|null=null
    
    if (rdeaFile.conclusion.dangereux==1) {
      dateDangerosite = rdeaFile.information.dateEffet
    }

    if (rdeaFile.conclusion.ca3) {
      this.setDeficience('ca3',deficiences,dateDangerosite)
    }
    if (rdeaFile.conclusion.d3) {
      this.setDeficience('d3',deficiences,dateDangerosite)
    }
    if (rdeaFile.conclusion.ls3) {
      this.setDeficience('ls3',deficiences,dateDangerosite)
    }
    if (rdeaFile.conclusion.sp4) {
      this.setDeficience('sp4',deficiences,dateDangerosite)
    }
    if (rdeaFile.conclusion.en5) {
      this.setDeficience('en5',deficiences,dateDangerosite)
    }
    
    const type:string=rdeaFile.information.type.toString()
    
    switch (type) {
      case "1":
        type_operation  = 'declaration'
        date_examen_1A  = rdeaFile.information.dateEffet
        date_rapport_1A = new Date()
        dangereux_1A    = rdeaFile.conclusion.dangereux==1? 'oui' : 'non'
        demande_fo      = 'non'
        reparable_1A    = rdeaFile.conclusion.techReparable==1? 'oui' : 'non'
        break;
    
      case "2":
        type_operation  = 'premier'
        date_rapport_1B = rdeaFile.information.dateEffet
        date_examen_1B  = rdeaFile.information.dateEffet
        dangereux_1B    = rdeaFile.conclusion.dangereux==1? 'oui' : 'non'
        demande_fo      = 'oui'
        reparable_1B    = rdeaFile.conclusion.techReparable==1? 'oui' : 'non'
        break;
        
      case "3":
        type_operation  = 'premier'
        date_rapport_1B = new Date()
        date_examen_1B  = rdeaFile.information.dateEffet
        dangereux_1B    = rdeaFile.conclusion.dangereux===1? 'oui' : 'non'
        demande_fo      = 'non'
        reparable_1B    = rdeaFile.conclusion.techReparable==1? 'oui' : 'non'
        break;
          
      case "4":
        type_operation  = 'deuxieme'
        date_rapport_1C = rdeaFile.information.dateEffet
        date_examen_1C  = rdeaFile.information.dateEffet
        demande_fo      = 'non'
        break;
            
      default:
        break;
      }

    let rdeaJson = {
      "control": {
        "logiciel": "RDEA Certif",
        "code_partenaire": entite.habilitation,
        "code_expert": this.apiAuth.userConnect.agrement_ve,
      },
      "vehicule": {
        "certificat_present": "oui",
        "numero_immatriculation": rdeaFile.technique.immatriculation,
        "numero_vin": rdeaFile.technique.vin,
        "numero_formule": rdeaFile.technique.numeroFormule,
        "date_certificat": rdeaFile.technique.dateCi,
        "numero_dossier": rdeaFile.reference.numeroDossier,
        "constatation_technique": {
          "date_immatriculation":rdeaFile.technique.dateMec,
          "demande_fo": demande_fo,
          "type_mines": rdeaFile.technique.typeMine,
          "code_marque": rdeaFile.technique.marque,
          "modele": rdeaFile.technique.modele,
          "code_genre": rdeaFile.technique.genre,
          "code_carrosserie": rdeaFile.technique.carrosserie,
          "puissance_reelle": rdeaFile.technique.puissanceReelle,
          "puissance_fiscale": rdeaFile.technique.puissanceFiscale,
          "code_energie": rdeaFile.technique.energie,
          "code_couleur": rdeaFile.technique.couleur,
          "kilometrage": rdeaFile.technique.kilometrage,
          "usure_pneumatique_AVD": rdeaFile.expertise.usureAvd,
          "usure_pneumatique_AVG": rdeaFile.expertise.usureAvg,
          "usure_pneumatique_ARD_AVANT": rdeaFile.expertise.usureArd,
          "usure_pneumatique_ARD_ARRIERE": rdeaFile.expertise.usureArd2,
          "usure_pneumatique_ARG_AVANT": rdeaFile.expertise.usureArg,
          "usure_pneumatique_ARG_ARRIERE": rdeaFile.expertise.usureArd2,
          "reparabilite_economique": rdeaFile.conclusion.ecoReparable==1? 'oui' :'non',
          "reparabilite_technique": rdeaFile.conclusion.techReparable==1? 'oui' :'non',
        }
      },
      "expertise": {
        "type_operation": type_operation,
        "date_examen_1A": date_examen_1A!,
        "date_rapport_1A": date_rapport_1A!,
        "dangereux_1A": dangereux_1A,
        "date_rapport_1B": date_rapport_1B!,
        "date_examen_1B" : date_examen_1B!,
        "dangereux_1B": dangereux_1B,
        "date_rapport_1C": date_rapport_1C!,
        "date_examen_1C" : date_examen_1C!,
        "peut_circuler": rdeaFile.information.type=="4"? 'oui':'nc',
        "deficience": deficiences,
        "reparable_1A": reparable_1A,
        "reparable_1B": reparable_1B,
        "sinistre": {
          "code_nature_evenement": rdeaFile.reference.natureEvenement,
          "code_nature_adverse": rdeaFile.reference.natureAdverse,
          "date_evenement": rdeaFile.reference.dateSinistre,
        },
        "reparation_globale": {
          "nb_points_choc": nbPointsChocs,
          "totalHT": rdeaFile.expertise.totalHt,
          "sous_total_HT_pieces" : rdeaFile.expertise.piecesHt
        },
        "points_de_choc": pointsChocs,

        "valeur_vehicule": {
          "valeur_totale_HT_vehicule": rdeaFile.expertise.valeurTotaleHt,
          "valeur_totale_TTC_vehicule": rdeaFile.expertise.valeurTotaleTtc,
        }
      }
    }
    return rdeaJson
  }

  setDeficience(formControlName:string,deficiences:Deficience[],date:Date|null){
    let deficience:Deficience={'code_dangerosite':'','deficience_imputable':'','date_dangerosite':date }

    switch (formControlName) {
      case 'ca3':
        deficience.code_dangerosite     = 'CA3'
        deficience.deficience_imputable = 'oui'
        break;

      case 'd3':
        deficience.code_dangerosite     = 'DI3'
        deficience.deficience_imputable = 'oui'
        break;

      case 'ls3':
        deficience.code_dangerosite     = 'LS3'
        deficience.deficience_imputable = 'oui'
        break;

      case 'sp4':
        deficience.code_dangerosite     = 'SP4'
        deficience.deficience_imputable = 'oui'
        break;

      case 'en5':
        deficience.code_dangerosite     = 'EN5'
        deficience.deficience_imputable = 'oui'
        break;
    
      default:
        break;
    }

    if (deficience.code_dangerosite!='') {
      deficiences.push(deficience)
    }
  }

  setPointChoc(formControlName:string,poinsChocs:ZoneChoc[]){
    let pointChoc:ZoneChoc = {'code_loc_point_choc':'','code_zone_deformation':'','designation_choc':''}

    switch (formControlName) {
      case 'ensemble':
        pointChoc.code_loc_point_choc   = 'TAPC11'
        pointChoc.code_zone_deformation = 'TAZD11' 
        pointChoc.designation_choc      = 'ENSEMBLE'
        break;
      
      case 'chocDessus':
        pointChoc.code_loc_point_choc   = 'TAPC09'
        pointChoc.code_zone_deformation = 'TAZD09' 
        pointChoc.designation_choc      = 'DESSUS'
        break;
      
      case 'chocSoubassement':
        pointChoc.code_loc_point_choc   = 'TAPC10'
        pointChoc.code_zone_deformation = 'TAZD10' 
        pointChoc.designation_choc      = 'SOUBASSEMENT'
        break;

      case 'chocTrainsRoulants':
        pointChoc.code_loc_point_choc   = 'TAPC12'
        pointChoc.code_zone_deformation = 'TAZD12' 
        pointChoc.designation_choc      = 'TRAINS ROULANTS'
        break;
      
      case 'chocMoteurBvPonts':
        pointChoc.code_loc_point_choc   = 'TAPC13'
        pointChoc.code_zone_deformation = 'TAZD13' 
        pointChoc.designation_choc      = 'MOTEUR/BV/PONTS'
        break;
    
      case 'chocHabitacle':
        pointChoc.code_loc_point_choc   = 'TAPC14'
        pointChoc.code_zone_deformation = 'TAZD14' 
        pointChoc.designation_choc      = 'HABITACLE'
        break;
  
      case 'chocAvant':
        pointChoc.code_loc_point_choc   = 'TAPC01'
        pointChoc.code_zone_deformation = 'TAZD01' 
        pointChoc.designation_choc      = 'AVANT'
        break;
    
      case 'chocAvantG':
        pointChoc.code_loc_point_choc   = 'TAPC06'
        pointChoc.code_zone_deformation = 'TAZD06' 
        pointChoc.designation_choc      = 'TIERS AVANT GAUCHE'
        break;

      case 'chocLateralG':
        pointChoc.code_loc_point_choc   = 'TAPC07'
        pointChoc.code_zone_deformation = 'TAZD07' 
        pointChoc.designation_choc      = 'CENTRAL GAUCHE'
        break;

      case 'chocArriereG':
        pointChoc.code_loc_point_choc   = 'TAPC08'
        pointChoc.code_zone_deformation = 'TAZD08' 
        pointChoc.designation_choc      = 'TIERS ARRIERE GAUCHE'
        break;

      case 'chocAvantD':
        pointChoc.code_loc_point_choc   = 'TAPC02'
        pointChoc.code_zone_deformation = 'TAZD02' 
        pointChoc.designation_choc      = 'TIERS AVANT DROIT'
        break;

      case 'chocLateralD':
        pointChoc.code_loc_point_choc   = 'TAPC03'
        pointChoc.code_zone_deformation = 'TAZD03' 
        pointChoc.designation_choc      = 'CENTRAL DROIT'
        break;

      case 'chocArriereD':
        pointChoc.code_loc_point_choc   = 'TAPC04'
        pointChoc.code_zone_deformation = 'TAZD04' 
        pointChoc.designation_choc      = 'TIERS ARRIERE DROIT'
        break;

      case 'chocArriere':
        pointChoc.code_loc_point_choc   = 'TAPC05'
        pointChoc.code_zone_deformation = 'TAZD05' 
        pointChoc.designation_choc      = 'ARRIERE'
        break;

      case '' :
        pointChoc.code_loc_point_choc   = 'TAPC15'
        pointChoc.code_zone_deformation = 'TAZD15' 
        pointChoc.designation_choc      = 'NON PRECISE'
        break;

      default:
        break;
    }

    if (pointChoc.code_loc_point_choc!='') {
      poinsChocs.push(pointChoc)
    }
  }

  public remettreDispositionAcquittement(idSession: number, entite:Entite): Observable<any> {
    const password      = entite.acces_concentrateur.mot_de_passe
    const login         = entite.acces_concentrateur.habilitation
    const authorization = btoa(login + ':' + password)
    const header        = {headers:new HttpHeaders().set('Authorization','Basic '+authorization).set('Referrer-Policy','same-origin')}

    return this.http.get<any>(environment.urlApiConcentrateur+'acquittement/remisedisposition/'+idSession, header)
  }
}
