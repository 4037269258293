import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Entite } from '../interfaces/profil-user/entite';
import { ProfilUtilisateur } from '../interfaces/profil-user/profil-utilisateur';
import { RelationUtilisateurEntite } from '../interfaces/utilisateur-entite/relation-utilisateur-entite';

@Injectable({
  providedIn: 'root'
})
export class ApiRelUtilisateurEntiteService {

  urlApi: string = '/api';
  jsonContentType = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  // Ajout d'une liste de collaborateur à une entité
  public postListeCollaborateur(liste_collaborateur: Array<RelationUtilisateurEntite>): Observable<RelationUtilisateurEntite[]> {
    return this.http.post<RelationUtilisateurEntite[]>(this.urlApi+"/rel_utilisateur_entite/liste-collaborateur", liste_collaborateur);
  }

  // récupération des entites à partir d'un id utilisateur
  public getRelUtilisateurEntite(id_utilisateur: number, id_entite :number): Observable<RelationUtilisateurEntite[]> {
    return this.http.get<RelationUtilisateurEntite[]>(
      this.urlApi+"/rel_utilisateur_entite/relation?id_utilisateur=" + id_utilisateur + "&id_entite=" + id_entite, { headers: this.jsonContentType });
  }

   // Récupération de la liste des utilisateurs à partir de l'id d'une entité
   public getUtilisateursByIDEntite(id_entite: number): Observable<ProfilUtilisateur[]> {
    return  this.http.get<ProfilUtilisateur[]>(
      this.urlApi+"/rel_utilisateur_entite/utilisateurs?id_entite=" + id_entite, { headers: this.jsonContentType });
  }

  // récupération des entites à partir d'un id utilisateur
  public getEntitesByIDUtilisateur(id_utilisateur: number, id_Entite: number= 0, type: string= ''): Observable<Entite[]> {
    let option : string = '';
    option += (type != '' ? '&type='+type : '');
    option += (id_Entite > 0 ? '&entite='+id_Entite : '');
    return  this.http.get<Entite[]>(
      this.urlApi+"/rel_utilisateur_entite/entites?id_utilisateur=" + id_utilisateur + option, { headers: this.jsonContentType });
  }

  // récupération d'une entité groupe et ses sous-entité lié id utilisateur
  public getSousEntitesByIDUtilisateur(id_utilisateur: number, id_Entite: number= 0): Observable<Entite[]> {
    let option : string = '';
    option += (id_Entite > 0 ? '&id_entite='+id_Entite : '');
    return  this.http.get<Entite[]>(
      this.urlApi+"/rel_utilisateur_entite/sousentites?id_utilisateur=" + id_utilisateur + option, { headers: this.jsonContentType });
  }

  // récupération des entites à partir d'un id utilisateur
  public getEntitesByType(type: string, id_utilisateur: number = 0): Observable<Entite[]> {
    let option: string = '';
    option = '?type='+type
    option += (id_utilisateur === 0 ? '' : '&id_utilisateur='+id_utilisateur)
    return  this.http.get<Entite[]>(
      this.urlApi+"/rel_utilisateur_entite/entites" + option, { headers: this.jsonContentType });
  }

}
