import { Component, OnInit }                    from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router }               from '@angular/router';
import { Categorie }                            from 'src/app/interfaces/phrasier/categorie';
import { Phrasier }                             from 'src/app/interfaces/phrasier/phrasier';
import { SousCategorie }                        from 'src/app/interfaces/phrasier/sous-categorie';
import { ApiPhrasierService }                   from 'src/app/services/api-phrasier.service';
import { PersoSnackbarService }                 from 'src/app/services/perso-snackbar.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-form-phrasier',
    templateUrl: './form-phrasier.component.html',
    styleUrls: ['./form-phrasier.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatInputModule, MatButtonModule, MatIconModule]
})
export class FormPhrasierComponent implements OnInit {

  // Constantes utilisées pour afficher/masquer la nouvelle catégorie/sous-caégorie
  const_form_new_categorie        = '999999' as const; 
  const_form_new_sous_categorie   = '999998' as const; 

  get titre()               { return this.formGroupPhrasier.get('titre'); }
  get phrase()              { return this.formGroupPhrasier.get('phrase'); }
  get categorie()           { return this.formGroupPhrasier.get('categorie'); }
  get new_categorie()       { return this.formGroupPhrasier.get('new_categorie'); }
  get sous_categorie()      { return this.formGroupPhrasier.get('sous_categorie'); }
  get new_sous_categorie()  { return this.formGroupPhrasier.get('new_sous_categorie'); }

  // Variables pour gérer le phrasier (chargement, création, modification)
  id                    : number                = 0;
  phrasier              : Phrasier              = <Phrasier>{}

  // Gestion de la création ou modification en affichage
  bModeCreation         : boolean               = false;
  bAfficheNewCategorie  : boolean               = false;
  bAfficheNewSousCategorie: boolean             = false;
  titre_page            : string                = '';
  categories            : Array<Categorie>      = new Array<Categorie>();
  sous_categories       : Array<SousCategorie>  = new Array<SousCategorie>();
  sous_categoriesFiltre : Array<SousCategorie>  = new Array<SousCategorie>();

  categorieSelected!      : Categorie
  sousCategorieSelected!  : SousCategorie

  // Variables utilisées pour le formulaire
  formGroupPhrasier = this.fBuilder.group({
    new_categorie           : new FormControl('',[this.verificationFormatNewCategorie]),
    categorie               : new FormControl('',[Validators.required, this.verificationFormatCategorie]),
    new_sous_categorie      : new FormControl('',[this.verificationFormatNewSouscategorie()]),  
    sous_categorie          : new FormControl('',[Validators.required]),
    titre                   : new FormControl('',[Validators.required, this.verificationFormatTitre]),
    phrase                  : new FormControl('',[Validators.required, this.verificationFormatPhrase])
    
    // categorie_old           : ['', [Validators.required, this.verificationFormatCategorie]],
    // new_categorie_old       : [{value: '', disabled : false}, [this.verificationFormatNewCategorie.bind(this)]],
    // sous_categorie_old      : ['',[Validators.required, this.verificationFormatSouscategorie]],
    // new_sous_categorie_old  : [{value: '', disabled : false}, [this.verificationFormatNewSouscategorie.bind(this)]],
  });

  constructor(  private apiPhrasier : ApiPhrasierService
              , private fBuilder    : FormBuilder
              , private toast       : PersoSnackbarService
              , private routed      : ActivatedRoute
              , private router      : Router) { }

  ngOnInit(): void {
    this.id = this.routed.snapshot.params['id'];        
    // Chargement des catégories
    this.getCategories();

    if(this.id > 0 ){
      // Mode édition : on récupère le phrasier
      this.bModeCreation = false;
      this.titre_page = 'Modifier une phrase';      
    } else {
      // Mode création : on start from scratch
      this.titre_page = 'Créer une phrase';
      this.bModeCreation = true;      
    }    
  }

  verificationFormatCategorie(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors|null =>{

      let categorie: any = control.value;
  
      
        if(categorie.id != undefined) {
          if(categorie.id > 0) {
            return null
          }
        } else {
          return { erreur: 'Saisie obligatoire' }  
        }    
      
      return null;
    }
  }

  verificationFormatNewCategorie() : ValidatorFn  {
    return (control:AbstractControl):ValidationErrors|null =>{

      let new_categorie: any = control.value;
  
      if(this.formGroupPhrasier == undefined) {
        return null
      }
      if (this.categorieSelected) {
        if(this.categorieSelected.id > 0) {
          if(this.categorieSelected.id == +this.const_form_new_categorie) {
            if(new_categorie == '') {
              return { erreur: 'Saisie obligatoire'}
            } else {
              return null
            }
          } else {
            return null
          }      
        } else {
         return null
        }
      }else{
        return null
      }
    }
  }

  verificationFormatSouscategorie(): ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null=>{

      let sous_categorie: any = control.value;
  
      if (sous_categorie == undefined || sous_categorie == '') {    
        return { erreur: 'Saisie obligatoire' }
      }  
  
      return null;
    }
  }

  verificationFormatNewSouscategorie() : ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null =>{

      let new_sous_categorie: any = control.value;
      
      if(this.formGroupPhrasier == undefined) {
        return null
      }
      if (this.formGroupPhrasier.controls.sous_categorie.value) {
        const id : number = +this.formGroupPhrasier.controls.sous_categorie.value
        if(id > 0) {
          if(id == +this.const_form_new_sous_categorie) {
            if(new_sous_categorie == '') {
              return { erreur: 'Saisie obligatoire'}
            } else {
              return null
            }
          } else {
            return null
          }
        } else {
          return null
        }
      }else{
        return null
      }
    }
  }

  verificationFormatTitre(): ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null=>{

      let titre: string = control.value;
  
      if (titre === '') {      
        return { erreur: 'Saisie obligatoire' }
      } 
  
      return null;
    }
  }

  verificationFormatPhrase(): ValidatorFn {
    return (control:AbstractControl):ValidationErrors|null=>{
      let phrase: string = control.value;
  
      if (phrase === '') {      
        return { erreur: 'Saisie obligatoire' }
      } 
      return null;
    }
  }

  onSelectChangeCategorie(newSelect: any) {    
    this.categorieSelected   = newSelect

    this.sous_categoriesFiltre = this.sous_categories;
    this.bAfficheNewCategorie = false;

    if(newSelect.value != undefined) {
      if(newSelect.value.id > 0) {
        this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie == newSelect.value.id || item.id_categorie == 0);
        if(newSelect.value.id == this.const_form_new_categorie) {
          this.bAfficheNewCategorie = true;
        }               
      } else {
        this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === 0);  
      }  
    } else {
      this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === 0);
    }
    
    if(this.bAfficheNewCategorie) {
      this.formGroupPhrasier.controls['new_categorie'].enable();
    } else {
      this.formGroupPhrasier.controls['new_categorie'].disable();
    }
  }
  
  onSelectChangeSousCategorie(newSelect: any) {      
    this.sousCategorieSelected = newSelect

    this.bAfficheNewSousCategorie = false;

    if(newSelect.value != undefined) {
      if(newSelect.value.id == this.const_form_new_sous_categorie) {
        this.bAfficheNewSousCategorie = true;
      }      
    }
    if(this.bAfficheNewSousCategorie) {
      this.formGroupPhrasier.controls['new_sous_categorie'].enable();
    } else {
      this.formGroupPhrasier.controls['new_sous_categorie'].disable();
    }
  }

  onClickValider(){
    this.bindFormulairePhrasier()

    if(this.bModeCreation) {
      this.postPhrasier(this.phrasier);
    } else {
      this.putPhrasier(this.id, this.phrasier);
    }

    this.router.navigate(['/app/phrasier']);
  }
  
  onClickAnnuler(){
    this.router.navigate(['/app/phrasier']);
  }

  bindFormulairePhrasier() {
    let id_categorie : number = 0;

    this.phrasier = <Phrasier>{};

    if(this.bModeCreation) {
      this.phrasier.id
    } else {
      this.phrasier.id = this.id;
    }

    if(this.categorieSelected.id == +this.const_form_new_categorie) {
      // Si nouvelle catégorie : récupération dans le champ de saisie
      this.phrasier.categorie = {
        id      : 0,
        libelle : this.formGroupPhrasier.controls.new_categorie.value? this.formGroupPhrasier.controls.new_categorie.value:''
      }
    } else {
      // Si catégorie déjà existante : récupération dans la combo
      this.phrasier.categorie = {
        id      : this.categorieSelected.id,
        libelle : this.categorieSelected.libelle
    }  
    }

    // Si nouvelle sous-catégorie
    if(this.sousCategorieSelected.id == +this.const_form_new_sous_categorie){
      // Si nouvelle catégorie
      if(this.categorieSelected.id == +this.const_form_new_categorie) {
        // On rattache la sous-catégorie à une nouvelle catégorie
        id_categorie = 0
      } else {
        // On rattache la sous-catégorie à une catégorie déjà existante
        id_categorie = this.categorieSelected.id; 
      }

      this.phrasier.sous_categorie = {
        id            : 0,
        id_categorie  : id_categorie,
        libelle       : this.formGroupPhrasier.controls.new_sous_categorie.value? this.formGroupPhrasier.controls.new_sous_categorie.value : ''        
      } 
    } else {
      // On récupère dans la combo
      this.phrasier.sous_categorie = {
        id            : this.sousCategorieSelected.id,
        id_categorie  : this.sousCategorieSelected.id_categorie,
        libelle       : this.sousCategorieSelected.libelle
      }
    }

    this.phrasier.titre   = this.formGroupPhrasier.controls.titre.value? this.formGroupPhrasier.controls.titre.value:'';
    this.phrasier.phrase  = this.formGroupPhrasier.controls.phrase.value? this.formGroupPhrasier.controls.phrase.value :'';
  }
  // Création d'un phrasier
  public postPhrasier(phrasier: Phrasier) : void { 
    this.apiPhrasier.postPhrasier(phrasier)
      .subscribe(
        (data: Phrasier) => { 
          this.phrasier = data;
          this.toast.showInfo("Phrasier créé")
        },
        (err: any) => { '/!\\ error postPhrasier: ' + console.log(err) },
        () => { console.log('complete: postPhrasier') }
      );
  }

  // Modification d'un phrasier
  public putPhrasier(id: number, phrasier: Phrasier) : void {     
    this.apiPhrasier.putPhrasier(id, phrasier)
      .subscribe(
        (data: any) => { 
          this.toast.showInfo("Phrasier modifié");
        },
        (err: any) => { '/!\\ error putPhrasier: ' + console.log(err) },
        () => { console.log('complete: putPhrasier') }
      );
  }

  public getPhrasier(id: number) : void {
    if(id > 0 ) {
      this.apiPhrasier.getPhrasier(id)
      .subscribe(
        (data: Phrasier) => { 
          this.phrasier = data;          
          
          this.formGroupPhrasier.patchValue({
            new_categorie       : '',
            categorie           : this.categories.find(cat => cat.id == this.phrasier.categorie.id)!.id.toString(),
            new_sous_categorie  : '',
            sous_categorie      : this.sous_categories.find(sous_cat => sous_cat.id == this.phrasier.sous_categorie.id)?.id.toString(),
            titre               : this.phrasier.titre,
            phrase              : this.phrasier.phrase
          });                    
      
          this.onSelectChangeCategorie(this.formGroupPhrasier.controls.categorie);
          this.onSelectChangeSousCategorie(this.formGroupPhrasier.controls.sous_categorie);
        },
        (err: any) => { 
          '/!\\ error getPhrasier: ' + console.log(err);
        },
        () => { console.log('complete: getPhrasier') }
      );
    } else {
      this.formGroupPhrasier.patchValue({
        new_categorie       : '',
        categorie           : '',
        new_sous_categorie  : '',
        sous_categorie      : '',
        titre               : '',
        phrase              : ''
      });     
      this.onSelectChangeCategorie(this.formGroupPhrasier.controls.categorie);
      this.onSelectChangeSousCategorie(this.formGroupPhrasier.controls.sous_categorie);
    }
  }

  // Chargement des catégories
  public getCategories() : void {
    this.apiPhrasier.getCategories()
      .subscribe(
        (data: Categorie[]) => { 
          let tmpCategorie : Categorie = {
            id      : 999999,
            libelle : 'Nouvelle catégorie'
          };

          this.categories = data;
          this.categories.push(tmpCategorie);
          
          this.getSousCategories();
        },
        (err: any) => { '/!\\ error getCategories: ' + console.log(err) },
        () => { console.log('complete: getCategories') }
      );
  }

  // Chargement des sous-catégories
  public getSousCategories() : void {
    this.apiPhrasier.getSousCategories()
      .subscribe(
        (data: SousCategorie[]) => { 
          let tmpSouscategorie : SousCategorie = {
            id            : 999998,
            id_categorie  : 0,
            libelle       : 'Nouvelle Sous-catégorie'
          };
          this.sous_categories = data;          
          this.sous_categories.push(tmpSouscategorie);
          this.sous_categoriesFiltre = this.sous_categories;
          
          this.getPhrasier(this.id);
          this.onSelectChangeCategorie(this.formGroupPhrasier.controls.categorie);
          this.onSelectChangeSousCategorie(this.formGroupPhrasier.controls.sous_categorie);
        },
        (err: any) => { '/!\\ error getSousCategories: ' + console.log(err) },
        () => { console.log('complete: getSousCategories') }
      );
  }
}
