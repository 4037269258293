import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ApiStatistiqueService } from 'src/app/services/api-statistique.service';
import { AnneeValidator } from 'src/app/validators/annee.validator';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { AnneeDirective } from '../../../directive/annee.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-habilitation',
    templateUrl: './habilitation.component.html',
    styleUrls: ['./habilitation.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, AnneeDirective, NgIf, MatButtonModule, MatProgressSpinnerModule, MatGridListModule]
})
export class HabilitationComponent implements OnInit {
  @Input() maxAnnee : string = ''
  @Input() minAnnee : string = ''

  filterForm!   : FormGroup
  waiting       : boolean = false
  habilitation  : string = ''
  nbOperations  : number = 0
  annee         : string =''

  constructor(private anneeValidator : AnneeValidator
              , private apiStat : ApiStatistiqueService) { }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
        annee         : new FormControl(this.minAnnee,[Validators.required,this.anneeValidator.valideAnnee(this.minAnnee,this.maxAnnee)])
      , habilitation  : new FormControl('',Validators.required)
    })
  }

  onSearch(){
    this.waiting = true
    
    const annee         = this.filterForm.value.annee
    const habilitation  = this.filterForm.value.habilitation
    
    this.apiStat.getStatParHabilitation(annee,habilitation).subscribe(
      
      (data:any)=>{
        this.waiting      = false

        this.annee        = annee
        this.habilitation = habilitation
        this.nbOperations = data
      }
      ,(error)=>this.waiting = false
    )
  }
}
