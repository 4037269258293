import { DatePipe, DecimalPipe, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MesFactures } from 'src/app/interfaces/facture/facture';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAdcService } from 'src/app/services/api-adc.service';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { ExportTableComponent } from '../../templates/export-table/export-table.component';
import { DialogHistoriqueFactureComponent } from '../dialog-historique-facture/dialog-historique-facture.component';
import { ContexteService, TYPE_FACTURE } from 'src/app/services/contexte.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-table-facture',
    templateUrl: './table-facture.component.html',
    styleUrls: ['./table-facture.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, MatIconModule, NgIf, MatCardModule, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule
              , NgStyle, MatButtonModule, ExportTableComponent, MatPaginatorModule, DecimalPipe, DatePipe, MatProgressSpinnerModule]
})
export class TableFactureComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource: any;
  
  dataSourceMatTable: MatTableDataSource<any> = new MatTableDataSource<any>();
  
  expandPannel        : boolean = true;
  isAdmin             : boolean = false;
  profilUtilisateur   : ProfilUtilisateur = <ProfilUtilisateur>{};
  
  // Tableau des factures
  displayedColumns    : string[] = [];
  readonly typeContexte : string = TYPE_FACTURE

  constructor(private authUser: ApiAuthentificationService
            , public apiFacture: ApiFactureService
            , private apiUtilitaire: ApiUtilitaireService
            , private apiADELA: ApiAdelaService
            , private apiADC: ApiAdcService
            , private toast: PersoSnackbarService
            , private route: Router
            , public dialog: MatDialog
            , private contexte        : ContexteService
            , private cdr             : ChangeDetectorRef ) { }

  ngOnInit(): void {
    

    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }

    this.profilUtilisateur = this.authUser.userConnect;
    this.isAdmin = (this.profilUtilisateur.droits_utilisateur.droit_module_admin_adela || this.profilUtilisateur.droits_utilisateur.droit_module_admin_compta);
    
/*     // est-ce un admin ?
    if (this.isAdmin) {
      // Est-ce un admin comptabilité
      if ( this.profilUtilisateur.droits_utilisateur.droit_module_admin_compta ) {
        this.displayedColumns = ['service', 'type', 'option', 'commande', 'reference', 'avoir', 'cabinet', 'date', 'mode_paiement', 'etat', 'total_ht', 'total_ttc', 'telechargerFacture', 'actions'];
      } else {
        this.displayedColumns = ['service', 'type', 'option', 'commande', 'reference', 'cabinet', 'date', 'mode_paiement', 'etat', 'total_ht', 'total_ttc', 'telechargerFacture', 'actions'];
      }
    } else {
      this.displayedColumns = ['service', 'type', 'option', 'commande', 'reference', 'cabinet', 'date', 'mode_paiement', 'etat', 'total_ht', 'total_ttc', 'telechargerFacture', 'actions'];
    } */
    this.displayedColumns = ['service', 'type', 'commande', 'reference', 'cabinet', 'date', 'etat', 'total_ht', 'total_ttc', 'resteDu', 'actions'];
  }

  ngAfterViewInit() {
    this.paginator!.pageIndex = this.contexte.getPageIndex(this.typeContexte)
    this.paginator!.pageSize = this.contexte.getPages(this.typeContexte)
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
  }

  ngOnChanges() {
    this.dataSourceMatTable.paginator = this.paginator;
    // obligatoire
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }     
  }

  public getOptionColor(code: string) {
    switch (code) {
      case 'ACHAT':
        return '#e48213'
        break;
      default:
        return 'rgb(39,58,130)'
        break;
    }
  }

  public dialogHistoriqueFacture(id: any) {
    const dialogRef = this.dialog.open(DialogHistoriqueFactureComponent);
    dialogRef.componentInstance.id = id
  }

  public getIconeService(code: string) {
    switch (code) {
      case this.apiUtilitaire.CONST_CODE_SERVICE_ADELA:
        return 'invert_colors';
      case this.apiUtilitaire.CONST_CODE_SERVICE_ADC:
        return 'key';
      case this.apiUtilitaire.CONST_CODE_SERVICE_APPEL_OFFRE:
        return 'table_view';
      case this.apiUtilitaire.CONST_CODE_SERVICE_OUTIL_DIAGNOSTIC:
        return 'work';
      case this.apiUtilitaire.CONST_CODE_SERVICE_EQUIPEMENT_EXPERT:
        return 'shopping_cart'
      default: 
        return'default'
    }
  }  

  public getIconeName(code: string) {
    switch (code) {
      case this.apiUtilitaire.CONST_CODE_SERVICE_ADELA:
        return 'ADELA';
      case this.apiUtilitaire.CONST_CODE_SERVICE_ADC:
        return 'ADC';
      case this.apiUtilitaire.CONST_CODE_SERVICE_APPEL_OFFRE:
        return 'APPEL D\'OFFRE';
      case this.apiUtilitaire.CONST_CODE_SERVICE_OUTIL_DIAGNOSTIC:
        return 'OUTIL DIAGNOSTIC';
      case this.apiUtilitaire.CONST_CODE_SERVICE_EQUIPEMENT_EXPERT:
        return 'EQUIPEMENT EXPERT'
      default: 
        return 'INCONNU'
    }
  }  

  public getIconeDownload(codeService: string) {
   switch(codeService) {
      case this.apiUtilitaire.CONST_CODE_SERVICE_ADC:
        return 'file_present'
        break;
      case this.apiUtilitaire.CONST_CODE_SERVICE_ADELA:
        return 'file_present'
        break;
      default:
        return ''
    }
  }

  public getColorFacture(type: string) {
    return this.apiFacture.getColorFacture(type);
  }

  public getBackgroundColorByStatus(status: string) {
    return this.apiFacture.getBackgroundColorByStatus(status);
  }
  
  public onDownloadFacture(facture: MesFactures) {
    facture.telechargementEnCours = true;
    this.apiFacture.getDownloadFacture(facture.id, facture.type_document)
      .subscribe(
        (data) => {
          if(data != undefined) {
            facture.telechargementEnCours = false;
            saveAs(data, facture.type_document+`_`+facture.numero.toString()+`.pdf`);
            this.toast.showInfo("Téléchargement effectué");
          }
        },
        (err) => {
          facture.telechargementEnCours = false;
          this.toast.showError('Problème rencontré lors du téléchargement de la facture.');
          console.error('/!\\ error getDownloadFacture: '+err);
        }
      );
  }

  public onDownloadRapport(facture: MesFactures) {
    if(facture.idDossierADELA > 0) {
      facture.telechargementEnCours = true;
      this.apiADELA.getTelechargerResultatAnalyse(facture.idDossierADELA)
      .subscribe(
        (data) => {
          if(data != undefined) {
            facture.telechargementEnCours = false;
            saveAs(data, `RAPPORT_ADELA_`+facture.habilitation+`_`+facture.idDossierADELA.toString()+`.pdf`);
            this.toast.showInfo("Téléchargement effectué");
          }
        },
        (err) => {
          facture.telechargementEnCours = false;
          this.toast.showError('Problème rencontré lors du téléchargement du rapport d\'analyse.');
          console.error('/!\\ error getTelechargerResultatAnalyse: '+err);
        }
      );
    }

    if(facture.idDossierADC > 0) {
      facture.telechargementEnCours = true;
      this.apiADC.downloadRapport(facture.idDossierADC)
      .subscribe(
        (data) => {
          if(data != undefined) {
            facture.telechargementEnCours = false;
            saveAs(data, 'RAPPORT_ADC_'+facture.habilitation+'_'+facture.idDossierADC.toString()+'.pdf');
            this.toast.showInfo('Téléchargement effectué');
          }          
        },
        err => {
          facture.telechargementEnCours = false;    
          this.toast.showError('Problème rencontré lors du téléchargement du rapport d\'analyse.');     
          console.error('/!\\ error downloadRapport: '+err);
        },
      );
    }
  }

  public openFacture(id: number) {
    this.route.navigate(['/app/facture/recap-facture/', id,0])
  }

  ngOnDestroy(){
    this.contexte.setPageIndex(this.dataSourceMatTable.paginator!.pageIndex)
    this.contexte.setTableData(this.dataSource)
    this.contexte.setPages(this.paginator? this.paginator.pageSize : 5)
    this.contexte.setType(this.typeContexte)
  }
}
