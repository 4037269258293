<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Gestion des paramètres</mat-card-title>
    </mat-card-header>

    <mat-card-content class=".mat-card-content">
        <ng-container *ngIf="typesParametres$ |async as typesParametres">
            <ng-container *ngIf="parametres$ |async as parametres">
                <div class="content" *ngFor="let typeParametre of typesParametres" >
                    <div class="titlebox">{{typeParametre}}</div>
                    <mat-grid-list cols="10" rowHeight="30px" class="title">
                        <mat-grid-tile colspan="3">Libellé</mat-grid-tile>
                        <mat-grid-tile colspan="3">Code</mat-grid-tile>
                        <mat-grid-tile colspan="3">Valeur</mat-grid-tile>
                    </mat-grid-list>

                    <div    *ngFor          = "let parametre of parametres; let i=index"
                            class           = "formFieldValeur"
                            appearance      = "outline">

                            <mat-grid-list cols="10" rowHeight="50"   *ngIf="parametre.type==typeParametre" >
                                <mat-grid-tile colspan="3" ><div>{{parametre.note}}</div></mat-grid-tile>
                                <mat-grid-tile colspan="3" ><div>{{parametre.code}}</div></mat-grid-tile>
                                <mat-grid-tile colspan="3" class="valeur"><div>{{parametre.valeur}}</div></mat-grid-tile>
                                <mat-grid-tile colspan="1" ><button mat-raised-button color="primary" (click)="openEditParameter(parametre)">Modifier</button></mat-grid-tile>
                            </mat-grid-list>
                    
                    </div>
                </div>
            </ng-container>

        </ng-container>
    </mat-card-content>

</mat-card>
