import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Commande } from 'src/app/interfaces/commande/commande';
import { LigneCommande } from 'src/app/interfaces/commande/ligne-commande';
import { Etat } from 'src/app/interfaces/dossier/etat';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiCommandeService } from 'src/app/services/api-commande.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { CarteAdresseComponent } from '../../templates/cards/carte-adresse/carte-adresse.component';

@Component({
    selector: 'app-recap-commande',
    templateUrl: './recap-commande.component.html',
    styleUrls: ['./recap-commande.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, MatTableModule, MatTableResponsiveLargeDirective, NgFor, NgIf,CarteAdresseComponent]
})
export class RecapCommandeComponent implements OnInit, AfterViewInit {

  CONST_MT_HT         = 0 as const;
  CONST_MT_REMISE_HT  = 1 as const;
  CONST_MT_Port       = 2 as const;
  CONST_MT_TVA        = 3 as const;
  CONST_MT_TTC        = 4 as const;
  

  CONST_REPONSE_REFUSE = 'REFUSEE' as const;
  CONST_REPONSE_VALIDE = 'VALIDEE' as const;

  isLocation        : boolean = false;
  isWaitingForAction: boolean = false;

  idCommande        : number = 0;
  isConfirmOrder    : boolean = false;
  entite$           : Entite = <Entite>{};
  commande$         : Commande = <Commande>{}; 
  status$           : Etat = <Etat>{};
  totaux$           : any = {
    total : [{
      libelle:'Total HT',
      montant: 0
    },    
    {
      libelle: 'Total Remise HT',
      montant: 0
    },
    {
      libelle:'Frais de port',
      montant: 0
    },
    {
      libelle:'Total TVA',
      montant: 0
    },
    {
      libelle:'Total TTC',
      montant: 0
    }]
  };
  dataSource        : MatTableDataSource<LigneCommande> = new MatTableDataSource<LigneCommande>();
  // Tableau récapitulatif de la commande
  displayedColumns: string[] = ['article', 'quantite', 'prix_unitaire_ht', 'total_ht', 'par_Mois', 'pourcentage_remise', 'total_remise_ht', 'tauxTVA'];

  profilUtilisateur$      : ProfilUtilisateur = <ProfilUtilisateur>{};

  constructor(
      private apiCmd    : ApiCommandeService
    , private apiAuth   : ApiAuthentificationService
    , private apiEntite : ApiEntiteService
    , private toast     : PersoSnackbarService
    , public dialogRef  : MatDialogRef<RecapCommandeComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
  ) { 

   }

  ngOnInit(): void {
    // Récupération des informations de l'utilisateur connecté
    this.profilUtilisateur$   = this.apiAuth.userConnect;
    this.apiCmd.getStatusCommand();
  }
  
  ngAfterViewInit() {
    this.chargeCommande();
  }

  private chargeCommande() {
    this.idCommande     = this.data.id;

    if ( this.idCommande > 0 ) {
      // récupération de la commande
      this.apiCmd.getCommand( this.idCommande )
      .subscribe(
        (data: any) => {
          this.commande$ = data;
          this.isWaitingForAction = (this.commande$.Etat.code == this.apiCmd.CONST_ETAT_ATTENTE && this.profilUtilisateur$.droits_utilisateur.droit_module_outil_diagnostic == true)
          this.totaux$.total[this.CONST_MT_HT].montant = this.commande$.total_ht;
          this.totaux$.total[this.CONST_MT_Port].montant = this.commande$.port_ht;
          this.totaux$.total[this.CONST_MT_TVA].montant = this.commande$.total_tva;
          this.totaux$.total[this.CONST_MT_TTC].montant = this.commande$.total_ttc;
          this.totaux$.total[this.CONST_MT_REMISE_HT].montant = this.commande$.total_remise_ht;
          this.dataSource.data = this.commande$.tab_LigneCommande;
          this.status$ = this.commande$.Etat;
          this.apiEntite.getEntiteById(this.commande$.id_entite)
          .subscribe(
            (data: Entite[]) => {
              this.entite$ = data[0];
            },
            (err) => { console.log(err) },
            () => {}
          )
        }, 
        (err) => { console.log(err)},
        () => {}
      )
    } else {
      this.toast.showInfo('Aucune correspondance pour ce numéro de commande.');
    }
  }

}
