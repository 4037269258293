<app-entite-creation 
        *ngIf           = "edition" 
        [typesEntite]   = "typesEntite"  
        [tvas]          = "tvas"        
        [waitingManuel$] = "waiting$"
        (entiteEvent)   = "setNewEntite($event)"
        >
</app-entite-creation>

<div [hidden]="edition">
    <app-filtre-liste-cabinets (newItemEvent)="addItem($event)" [typesEntite]   = "typesEntite"></app-filtre-liste-cabinets>
    <mat-card appearance="outlined" style="background-color: #fff;" class="mat-card-no-shadow" style="padding: 0px;">
        <mat-expansion-panel [expanded]="true" style="background-color: #fff">
            
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title>
                    <mat-icon color="primary">file</mat-icon>
                    <span style="width: 220px;" *ngIf="ds_Cabinets != undefined"><span class="span-primary">{{ ds_Cabinets.length }}</span>CABINET<span *ngIf="ds_Cabinets.length > 1">S</span></span>
                    <span style="width: 220px;" *ngIf="ds_Cabinets == undefined"><span class="span-primary">0</span>CABINET</span>        
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table-liste-cabinets 
                    [dataSource]    = "ds_Cabinets" 
                    (editionEvent)  = "editNewEntite()"
                    ></app-table-liste-cabinets>
        </mat-expansion-panel>
    </mat-card>
</div>