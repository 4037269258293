
<mat-card>
    <form [formGroup] ="filtreCabinet">
        <div class="div-container-left">
            <app-entite-list    [fieldName]         = "'Cabinet'"
                                (entiteEmitter)     = "onSetEntite($event)"
                                [CoordBanqDisabled] = "false"
                                            >
            </app-entite-list>
            <mat-form-field>
                <mat-label>Habilitation</mat-label>
                <input matInput type    = "text" 
                formControlName   = "habilitation" 
                >
            </mat-form-field>
            <mat-form-field style="margin-left: 0px;">
                <mat-label>Raison sociale</mat-label>
                <input matInput type    = "text" 
                formControlName   = "raisonSociale" 
                >
            </mat-form-field>
            <button 
                mat-raised-button
                *ngIf       = "!waitingCabinets" 
                color       = "primary" 
                (click)     = "rechercheCabinets()" 
                [disabled]  = "!entite"
                >Rechecher</button>
            <mat-spinner *ngIf="waitingCabinets" diameter="24"></mat-spinner>
        </div>
    </form>
    <div class="content" >
        <div class="titlebox">Cabinets vers lesquels rediriger les missions</div>

        <mat-table 
            [dataSource]    = "dataSourceCabinets" 
           
            >
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                    Sélection simple
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <mat-checkbox 
                    (click)     = "$event.stopPropagation()"
                    [checked]   = "selectionCabinet.isSelected(row)"
                    (change)    = "selectCabinet(row)"
                                >
                  </mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- habilitation -->
            <ng-container matColumnDef="habilitation">
                <mat-header-cell *matHeaderCellDef > Habilitation </mat-header-cell>
                <mat-cell *matCellDef="let cabinet"> {{ cabinet.habilitation }} </mat-cell>
            </ng-container>

            <!-- raison sociale --> 
            <ng-container matColumnDef="raison_sociale">
                <mat-header-cell *matHeaderCellDef > Raison sociale </mat-header-cell>
                <mat-cell *matCellDef="let cabinet" > {{ cabinet.raison_sociale }} </mat-cell> 
            </ng-container>            
            
            <mat-header-row *matHeaderRowDef="displayedColumnsCabinets"></mat-header-row>
            <mat-row 
                matRipple 
                *matRowDef  = "let entite; columns: displayedColumnsCabinets;" 
                style       = "cursor:pointer"
                ></mat-row>
        </mat-table>
        <mat-paginator
            #paginatorCabinets  = "matPaginator"
            [pageSizeOptions]   = "[5,10,25]"
            aria-label          = "Nombre de lignes visibles..."
            showFirstLastButtons 
            >
        </mat-paginator>
    </div>
</mat-card>

<mat-card>
    <form [formGroup]="filtreMissionForm">
        <div class="div-container-left">
            <mat-form-field >
                <mat-label>N° de sinistre</mat-label>
                <input 
                    formControlName = "numeroSinistre"
                    matInput 
                    >
            </mat-form-field>
            <button 
                mat-raised-button 
                *ngIf       = "!waitingMissions" 
                color       = "primary" 
                (click)     = "getMissions()" 
                [disabled]  = "!entite"
                >Rechercher les missions</button>
            <mat-spinner *ngIf="waitingMissions" diameter="24"></mat-spinner>
        </div>
    </form>
    <div class="content">
        <div class="titlebox">Missions à rediriger</div>
        <mat-table 
            [dataSource]    = "dataSourceMissions" 
            >
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                   Sélection multiple
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <mat-checkbox 
                    (click)     = "$event.stopPropagation()"
                    [checked]   = "selectionMission.isSelected(row)"
                    (change)    = "selectMission(row)"
                                >
                  </mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- numéro de sinistre -->
            <ng-container matColumnDef="numeroSinistre">
                <mat-header-cell *matHeaderCellDef > N° sinistre </mat-header-cell>
                <mat-cell *matCellDef="let mission">
                    {{ mission.numeroSinistre }}
                </mat-cell>
            </ng-container>

            <!-- date de création --> 
            <ng-container matColumnDef="dateCreation">
                <mat-header-cell *matHeaderCellDef > Crée le </mat-header-cell>
                <mat-cell *matCellDef="let mission" > {{ mission.dateCreation | date: 'dd/MM/yyy'}} </mat-cell> 
            </ng-container>            
            
            <!-- assuré -->
            <ng-container matColumnDef="assure">
                <mat-header-cell *matHeaderCellDef > Assuré </mat-header-cell>
                <mat-cell *matCellDef="let mission" > {{ mission.prenomLese + ' ' + mission.nomLese }} </mat-cell>
            </ng-container>

            <!-- date de passage --> 
            <ng-container matColumnDef="datePassage">
                <mat-header-cell *matHeaderCellDef > Passage le </mat-header-cell>
                <mat-cell *matCellDef="let mission" > {{ mission.datePassage.substring(0,1)!='0'? (mission.datePassage | date: 'dd/MM/yyy') : '' }} </mat-cell> 
            </ng-container>     

            <mat-header-row *matHeaderRowDef="displayedColumnsMissions"></mat-header-row>
            <mat-row 
                matRipple 
                *matRowDef="let mission; columns: displayedColumnsMissions;"
                
                ></mat-row>
        </mat-table>

        <mat-paginator
            #paginatorMissions  = "matPaginator"
            [pageSizeOptions]   = "[5,10,25]"
            aria-label="Nombre de lignes visibles...">
        </mat-paginator>
       
    </div>
</mat-card>

<mat-card *ngIf="selectionCabinet.selected.length>0 || selectionMission.selected.length>0">
    <div class="content" style="margin-top: 4px;" >
        <div class="titlebox">Effectuer la redirection des missions</div>
        <div class="div-container">
            <div  class="rbracket">
                <div *ngFor="let mission of selectionMission.selected">
                  <mat-list-item>{{mission.numeroSinistre}} - {{mission.prenomLese}} {{mission.nomLese}} </mat-list-item>
                </div>
            </div>
    
            <button 
                *ngIf="!waiting"
                mat-raised-button 
                color="primary" 
                (click)="rediriger()"
                [disabled]="selectionCabinet.selected.length===0 || selectionMission.selected.length===0"
                >
                A rediriger vers
             </button>
            
             <mat-spinner 
                *ngIf="waiting"
                diameter="24">
            </mat-spinner>
            
            <div *ngIf="selectionCabinet.selected.length===1" class="cabinet">{{selectionCabinet.selected[0].raison_sociale}}</div>
        </div>
    </div>
</mat-card>
