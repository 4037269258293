<app-nav-bar [bModeConnecte]="true" (triggerEvent)="triggered()"></app-nav-bar>

<mat-sidenav-container  class       = "app-drawer-container" 
                        [ngClass]   = "serviceFFEA === true? 'serviceFFEA' : 'extranet'"
                        fullscreen 
                        >

    <mat-sidenav #sidenav
                mode        = "side" 
                class       = "sidenav"
                [(opened)]  = "opened" 
                position    = "end"
                style       = "margin-top: 60px"
                [ngClass]   = "serviceFFEA === false? 'serviceFFEA' : 'extranet'"
    >
        <app-sidenav [serviceFFEA]="serviceFFEA"></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content style="margin-top: 50px ;" >
        <div style="margin-bottom: 100px;" >
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
