import { Component, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FILTRE_UTI_PARAM, UtilisateurParamService, UtilisateurParamVal, UtilisateurParamValExtended } from 'src/app/services/utilisateur-param.service';
import { ParametreUtilisateurComponent } from "../fiche/parametre-utilisateur.component";

@Component({
  selector    : 'app-liste-parametres-utilisateur',
  templateUrl : './liste-parametres-utilisateur.component.html',
  styleUrls   : ['./liste-parametres-utilisateur.component.scss'],
  standalone  : true,
  imports: [MatCardModule, NgFor, NgIf, ParametreUtilisateurComponent, MatIconModule]
})
export class ListeParametreUtilisateurComponent {

  @Input() idUtilisateur  : number = 0;
  @Input() idEntite       : number = 0;
  @Input() type      : string = ''

  utiParamVals  : UtilisateurParamValExtended[] = [];

  constructor(private apiUtiParam:UtilisateurParamService){}

  ngOnInit(){
    this.loadParamValues();
  }

  private loadParamValues(): void {
    const filtre : FILTRE_UTI_PARAM = {'idEntite':this.idEntite,'idUtilisateur':this.idUtilisateur, 'type':this.type}
    this.apiUtiParam.getParamValeurs(filtre).subscribe((data: UtilisateurParamVal[]) => {
      this.utiParamVals = data.map(paramVal => ({
        ...paramVal,
        isActivated: paramVal.utl_pva_valeur !== null && paramVal.utl_pva_valeur !== '', // Activer si une valeur existe
        value: paramVal.utl_pva_valeur || '' // Valeur existante ou vide
      }));
      
    });
  }
  
  getPageTitle() : string {
    let title : string;

    switch (this.type) {
      case this.apiUtiParam.CONST_UTI_PARAM_TYPE_RAPPORT_AUTOMATIQUE:
        title = "Rapports automatiques";
        break;
      default :
        title = "Paramètres utilisateur"
        break;
    }
    
    return title
  }

}
