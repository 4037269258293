<mat-toolbar *ngIf="entite != undefined && !creer_succursale && display" class="navbar tabretrait">
      <mat-label class="label-accent" style="font-size: 18px;">
            <mat-icon class="img-logo" *ngIf="!entite.logo">apartment</mat-icon>
            <img class="img-logo" style="margin-right: 8px; margin-left: 8px;"
                  *ngIf="entite.logo" 
                  [src]="_sanitizer.bypassSecurityTrustResourceUrl(entite.logo)">
            {{ entite.raison_sociale }}
      </mat-label>   
</mat-toolbar>
<mat-tab-group 
      #tab
      class             = "tabretrait" 
      >      
      <mat-tab  [disabled]="disabled_informations_generales">
            <ng-template mat-tab-label>
                  <div class="div-container-left">
                        <mat-icon>feed</mat-icon>
                        <span class="mat-tab-label">Informations générales</span>
                  </div>
            </ng-template>

            <app-entite-informations-generales (cellClicked)="onChildCellClicked2($event)"></app-entite-informations-generales>          
      </mat-tab>            
      
      <div *ngIf="!creer_succursale && utl_profil.droits_utilisateur != undefined && display">

            <mat-tab *ngIf="ent_id > 0 && (RelUtilisateurEntite.est_dirigeant == true || est_dirigeant_principal == true || utl_profil.droits_utilisateur.droit_coord_bancaire===true|| utl_profil.droits_utilisateur.est_admin)">
                  <ng-template mat-tab-label>
                        <div class="div-container-left">
                              <mat-icon>account_balance</mat-icon>
                              <span class="mat-tab-label">Coordonnées bancaires</span>
                        </div>
                  </ng-template>
                  
                  <app-entite-coordonnee-bancaire [RelUtilisateurEntite]="RelUtilisateurEntite" [entite]="entite"></app-entite-coordonnee-bancaire>                
                  
            </mat-tab>

            <mat-tab *ngIf="!entite_est_succursale && ent_id > 0 && (((RelUtilisateurEntite.est_dirigeant == true || est_dirigeant_principal == true || utl_profil.droits_utilisateur.droit_concentrateur===true) && entite.typ_ent_code!=apiEntite.CONST_CODE_TYPE_ENTITE_ADELA) || utl_profil.droits_utilisateur.est_admin)">
                  <ng-template mat-tab-label>
                        <div class="div-container-left">
                              <mat-icon>center_focus_strong</mat-icon>
                              <span class="mat-tab-label">Accès cabinet</span>
                        </div>
                  </ng-template>
                  
                  <app-entite-acces-concentrateur (cellClicked)="onChildCellClicked($event)"  [indice]="entite.indice" [habilitation]="entite.habilitation" [acces_concentrateur]="acces_concentrateur"></app-entite-acces-concentrateur>
                  
            </mat-tab>
            
            <mat-tab *ngIf="(RelUtilisateurEntite.est_dirigeant == true  || est_dirigeant_principal == true || utl_profil.droits_utilisateur.droit_liste_utilisateurs || utl_profil.droits_utilisateur.est_admin === true) && ent_id > 0">
                  <ng-template mat-tab-label>
                        <div class="div-container-left">
                              <mat-icon>account_circle</mat-icon>
                              <span class="mat-tab-label">Liste des utilisateurs</span>
                        </div>
                  </ng-template>
                  
                  <app-liste-utilisateurs (cellClicked)="onChildCellCliked4($event)" [entite]="entite" [isDirigeantPrincipal]="RelUtilisateurEntite.est_dirigeant"></app-liste-utilisateurs>                              
                  
            </mat-tab>

            <mat-tab *ngIf="!entite_est_succursale && ( utl_profil.droits_utilisateur.est_admin === true || (entite.typ_ent_code!=apiEntite.CONST_CODE_TYPE_ENTITE_ADELA && ( utl_profil.droits_utilisateur.droit_liste_succursalles || RelUtilisateurEntite.est_dirigeant == true || est_dirigeant_principal == true )))">
                  <ng-template mat-tab-label>
                        <div class="div-container-left">
                              <mat-icon>apartment</mat-icon>
                              <span class="mat-tab-label">Liste des succursales</span>
                        </div>
                  </ng-template>

                  <app-liste-succursale [RelUtilisateurEntite]="getSuccursale(ent_id)" [est_dirigeant]="RelUtilisateurEntite.est_dirigeant" *ngIf="ent_id > 0 && entite.typ_ent_nom == this.CONST_NOM_TYPE_ENTITE_PRINCIPALE"></app-liste-succursale>                                   

            </mat-tab>
                                    
            <mat-tab *ngIf="ent_id > 0 && (RelUtilisateurEntite.est_dirigeant == true  || est_dirigeant_principal == true || utl_profil.droits_utilisateur.droit_inviter_collaborateur || utl_profil.droits_utilisateur.est_admin===true)">
                  <ng-template mat-tab-label>
                        <div class="div-container-left">
                              <mat-icon>mail</mat-icon>
                              <span class="mat-tab-label">Inviter un collaborateur</span>
                        </div>
                  </ng-template>

                  <app-invitation-collaborateur 
                        [invitationCollaborateur]     = "invitationCollaborateur" 
                        [RelUtilisateurEntite]        = "RelUtilisateurEntite" 
                        [est_dirigeant_principal]     = "RelUtilisateurEntite.est_dirigeant" 
                        [id_entite_succursale]        = "0" 
                        >
                  </app-invitation-collaborateur>
            </mat-tab>

            <!-- US328 : Ajout des prélèvements : -->
            <mat-tab *ngIf="ent_id > 0 && (RelUtilisateurEntite.est_dirigeant === true || est_dirigeant_principal === true || utl_profil.droits_utilisateur.droit_prelevement===true || utl_profil.droits_utilisateur.est_admin === true)">
            
                  <ng-template mat-tab-label>
                        <div class="div-container-left">
                              <mat-icon>credit_card</mat-icon>
                              <span class="mat-tab-label">Paiements</span>
                        </div>
                  </ng-template>
                  
                  <app-liste-historique-prelevements [entiteEnCours]="this.entite"></app-liste-historique-prelevements>                
                  
            </mat-tab>
      </div>  
      
      <div *ngIf="creer_succursale && utl_profil.droits_utilisateur != undefined && display">
            <mat-tab    [disabled]="disabled_liste_collaborateur" 
                        *ngIf="((RelUtilisateurEntite.est_dirigeant === true  || est_dirigeant_principal === true) && utl_profil.droits_utilisateur.droit_liste_utilisateurs)|| utl_profil.droits_utilisateur.est_admin === true">
                  <ng-template mat-tab-label>
                        <div class="div-container-left">
                              <mat-icon>account_circle</mat-icon>
                              <span class="mat-tab-label">Liste des utilisateurs</span>
                        </div>
                  </ng-template>

                  <app-invitation-liste-collaborateur (cellClicked)="onChildCellClicked3($event)" [id_entite_principale]="entite.id_entite_principale" [id_entite_succursale]="entite.id"></app-invitation-liste-collaborateur>
            </mat-tab>

            <mat-tab    [disabled]="disabled_invitation_collaborateur" 
                        *ngIf="((RelUtilisateurEntite.est_dirigeant == true || est_dirigeant_principal == true) && utl_profil.droits_utilisateur.droit_inviter_collaborateur)|| utl_profil.droits_utilisateur.est_admin == true">
                  <ng-template mat-tab-label>
                        <div class="div-container-left">
                              <mat-icon>mail</mat-icon>
                              <span class="mat-tab-label">Inviter un collaborateur</span>
                        </div>
                  </ng-template>

                  <app-invitation-collaborateur [invitationCollaborateur]="invitationCollaborateur" [RelUtilisateurEntite]="RelUtilisateurEntite" [est_dirigeant_principal]="RelUtilisateurEntite.est_dirigeant" [id_entite_succursale]="entite.id"></app-invitation-collaborateur>

                  <mat-divider></mat-divider>
                  
                  <div style="display: flex; justify-content: center !important; margin-top: 16px;">                  
                        <button (click)="onClickReturnProfilePage()" type="submit" mat-raised-button color="primary">
                              Terminer la création de la succursale
                        </button>
                  </div>                  
                  
            </mat-tab>
      </div>

      <mat-tab  [disabled]="disabled_informations_generales"
                  *ngIf="
                        ((utl_profil.droits_utilisateur? utl_profil.droits_utilisateur.droit_adresse_livraison : false) 
                        || RelUtilisateurEntite.est_dirigeant == true 
                        || est_dirigeant_principal == true 
                        || (utl_profil.droits_utilisateur? utl_profil.droits_utilisateur.est_admin : false))
                        && !creer_succursale
                        "
      >
            <ng-template mat-tab-label>
                  <div class="div-container-left">
                        <mat-icon>local_shipping</mat-icon>
                        <span class="mat-tab-label">Adresses de livraison</span>
                  </div>
            </ng-template>
            <app-shell-adresse 
                  [adresses]  = "entite.adresses"
                  [idTable]   = "entite.id!"
                  [types]     = "'LIV'"
                  >
            </app-shell-adresse>
      </mat-tab>
      
      <mat-tab [disabled]="disabled_informations_generales" *ngIf="ent_id > 0 && !creer_succursale && utl_profil.id > 0">
            <ng-template mat-tab-label>
                  <div class="div-container-left">
                        <mat-icon>report</mat-icon>
                        <span class="mat-tab-label">Rapports automatiques</span>
                  </div>
            </ng-template>
            <app-liste-parametres-utilisateur [idUtilisateur]="utl_profil.id" [idEntite]="ent_id" [type]="apiUtiParam.CONST_UTI_PARAM_TYPE_RAPPORT_AUTOMATIQUE"></app-liste-parametres-utilisateur>
      </mat-tab>

      <!-- SERVICE FFEA -->
      <mat-tab *ngIf="isServiceFFEA">
            <ng-template mat-tab-label>
                  <div class="div-container-left">
                        <mat-icon>car_repair</mat-icon>
                        <span class="mat-tab-label">Comptes réparateurs</span>
                  </div>
            </ng-template>
            
      </mat-tab>
</mat-tab-group>