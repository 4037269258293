import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Article } from '../interfaces/commande/article';
import { Commande, CommandeFacturee, FiltreCommande } from '../interfaces/commande/commande';
import { HistoEtatCommande } from '../interfaces/commande/histo-etat-commande';
import { Etat } from '../interfaces/dossier/etat';
import { Categorie } from '../interfaces/phrasier/categorie';
import { ApiAuthentificationService } from './api-authentification.service';
import { PersoSnackbarService } from './perso-snackbar.service';
import { RecapCommandeComponent } from '../components/commande/recap-commande/recap-commande.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogHistoEtatComponent } from '../components/commande/dialog-histo-etat/dialog-histo-etat.component';

export interface filtreCommande {
    idCommande    : [0],
    idUtilisateur : [0],
    idEntite      : [0],
    reference     : [''],
    etats         : [[]],
    dateDebut     : [''],
    dateFin       : ['']
}

export const FiltreCommandeInitial : FiltreCommande = {"dateDebut":"","dateFin":"","idContact":"","idEtats":[],'idEntite':0}


@Injectable({
  providedIn: 'root'
})
export class ApiCommandeService {
  url: string = '/api';
  // Option de commande
  CONST_OPTION_ACHAT     = 'ACHAT' as const;
  CONST_OPTION_LOCATION  = 'LOCATION' as const;
  //*
  CONST_CATEGORIE_KIT_KITADELA  = 'KIT ADELA' as const;
  CONST_CATEGORIE_OUTIL_DIAG    = 'DIAG'      as const;
  CONST_CATEGORIE_OPTION_DIAG   = 'OPT_DIAG'  as const;
  //* Liste des états de commande
  CONST_ETAT_ATTENTE          = 'ATTENTE' as const;
  CONST_ETAT_REFUSEE          = 'REFUSEE' as const;
  CONST_ETAT_VALIDEE          = 'VALIDEE' as const;
  CONST_ETAT_ANNULEE          = 'ANNULEE' as const;
  CONST_ETAT_CLOTURE          = 'CLOTUREE' as const;
  
  private readonly dialog = inject(MatDialog);

  constructor(private http: HttpClient
            , private toast: PersoSnackbarService
            , private apiAuthentification: ApiAuthentificationService
            ) { }


  public getCommandesAFacturer(filtre:FiltreCommande){
    return this.http.patch<any>(this.url + '/commande/commandes/afacturer',filtre)
  }

  // Récupérer la liste des articles selon leur catégorie (id = 1 : kits)
  public getArticleByCategorie(id: number) : Observable<Article[]> {    
    return this.http.get<Article[]>(this.url+"/article/categorie/"+id)
    .pipe(
      tap((article : Article[]) => {
        return article;        
      })
      ,catchError((error : HttpErrorResponse) => {
        this.toast.showError(error.error);
        return throwError(error);
      })
    )
  }

  //Facturer manuellement les commandes du tableau fourni:
  public facturerCommandes(commandes:Commande[]):Observable<CommandeFacturee[]>{
    return this.http.post<CommandeFacturee[]>( this.url +'/commande/commandes/facturer',commandes)
  }

  // Récupérer la liste catégorie d'article ou commande
  public getAnyCategorie() : Observable<any> {
    return this.http.get<any>( this.url +'/article/categorie')
  }

  // Récupérer toutes les catégories dans un tableau NON ASSOCIATIF
  public getAllCategories() : Observable<any> {
    return this.http.get<any>( this.url +'/article/categories')
  }

  // Récupérer la liste catégorie d'article ou commande
  public getAllCategorie() : Observable<Categorie[]> {
    return this.http.get<Categorie[]>( this.url +'/article/categorie?opt=all')
  }

  //
  public getStatusCommand(): Observable<Etat[]> {
    return this.http.get<Etat[]>( this.url + '/commande/info/etat')
  }

  // Récupération d'un article 
  public getArticle(id: number, ref: string) {
    let urlParametre: string = '';
    urlParametre += (id > 0 ? 'id='+id : '');
    urlParametre += (urlParametre != '' ? '&' : '')
    urlParametre += (ref != '' ? 'ref='+ref : '');

    return this.http.get<Article>(this.url + '/article/fiche?' + urlParametre)
  }

  // Ajouter une commande
  public postCommand(commande: Commande): Observable<Commande> {
    return this.http.post<Commande>(this.url+'/commande', commande);
  }

  // Chargement des commandes
  public getAllCommand(filtre: any): Observable<Commande[]> {
    return this.http.patch<Commande[]>( this.url+'/commande', filtre);
  }
  // Chargement d'une commande
  public getCommand(id: number): Observable<Commande> {
    return this.http.get<Commande>( this.url+'/commande/' + id);
  }

  // Modification d'un status/etat de commande
  public updateStatus(id: number, status: string) {
    return this.http.put<any>( this.url+ '/commande/etat', {id: id, etat: status,idUtilisateur:this.apiAuthentification.authUser.id});
  }

  //Chargement de l'historique de l'état d'une commande:
  public getHistoEtatCommande(idCommande: number): Observable<HistoEtatCommande[]> {
    return this.http.get<HistoEtatCommande[]>(this.url+'/commande/histoetat/'+idCommande)
  }

  public getIcone(code: string) {
    switch (code) {
      case this.CONST_ETAT_ATTENTE:
        return 'rule_folder';
        break;
      case this.CONST_ETAT_REFUSEE:
        return 'close';
        break;
      case this.CONST_ETAT_VALIDEE:
        return 'done';
        break;
      case this.CONST_ETAT_CLOTURE:
        return 'lock';
        break;
      case this.CONST_ETAT_ANNULEE:
        return '';
        break;
      default:
        return '';
        break;

    }
  }

  public getEtatColor(code: string) {
    switch (code) {
      case this.CONST_ETAT_ATTENTE:
        return '#e48213';
        break;
      case this.CONST_ETAT_REFUSEE:
      case this.CONST_ETAT_ANNULEE:
        return '#f44336';
        break;
      default:
        return 'rgb(39,58,130)'
        break;
    }
  }

  public getOptionColor(code: string) {
    switch (code) {
      case 'ACHAT':
        return '#e48213'
        break;
      default:
        return 'rgb(39,58,130)'
        break;
    }
  }

  public getIconCategorie(code:string){
    switch (code) {
      case 'KIT_ADELA':
        return 'add_shopping_cart'
        break;
      
      case 'DIAG':
        return 'home_repair_service'
        break;

      case 'OPT_DIAG':
        return 'add_shopping_cart'
        break;

      default:
        return '';
        break;
    }
  }

  public openCommandDetails(id: number) {
    const dialogRef = this.dialog.open(RecapCommandeComponent, {width: '90%', data: {id: id}});

    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case this.CONST_ETAT_VALIDEE:
          this.updateStatus(id, this.CONST_ETAT_VALIDEE)
          .subscribe(
            (data: Commande) => {
              console.info(data);
            },
            (err) => { console.error(err) },
            () => {}
          )
          break;

        case this.CONST_ETAT_REFUSEE:
          this.updateStatus(id, this.CONST_ETAT_REFUSEE)
          .subscribe(
            (data: Commande) => {
              console.info(data);
            },
            (err) => { console.error(err) },
            () => {}
          )
          break;

        default:
          break;
      }
    });
  }

  public openHistoCommande(idCommande:number,refCommande:string){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.getHistoEtatCommande(idCommande).subscribe(
      (data: any) => {    
        dialogConfig.data = {
          histoCommande: data,
          refCommande: refCommande     
        };
      if (!data) {
        this.toast.showInfo("Aucun historique d'état pour cette facture");
        return;
      }
      const dialogRef = this.dialog.open(DialogHistoEtatComponent,dialogConfig)
    },
    (err) => { console.error(err) },
    () => {}
  );


  }
}
