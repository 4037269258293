<mat-card appearance="outlined">
      <div>
            <h2>ADELA : Analyse Diagnostics et Expertise des Lubrifiants Automotive</h2>
        </div>
        <div class="display-warning label-error">
              <p><mat-icon color="warn" class="icon-warning">error</mat-icon>À compter du 1er janvier 2021, les échantillons envoyés à l'ancienne adresse seront automatiquement renvoyés à l'expéditeur.</p>
              <p><mat-icon color="warn" class="icon-warning">error</mat-icon>Pour éviter tout problème d'acheminement de vos échantillons, nous vous invitons à les expédier par COLISSIMO et non par CHRONOPOST, ce service n'assurant pas la distribution dans les boîtes postales.</p>
        </div>
        <br>
        <div class="display-adresse">
              <p>Nouvelle adresse d'expédition de vos échantillons</p>
              <ul style="list-style-type: none;">
                  <li style="font-weight: 600;"><mat-icon color="accent">mail</mat-icon>ADELA</li>
                  <li>ZI RUE LAVOISIER</li>
                  <li>BP 813</li>
                  <li>VERNEUIL-SUR-AVRE CEDEX, 27138</li>
                  <li>FRANCE</li>
            </ul>
      </div>
      <br>

      <mat-stepper orientation="vertical" [ngClass]="{'openUpStepper' : modeCreation == false}" [linear]="isLinear" #stepper>
            <!-- Référence du dossier -->
            <mat-step [stepControl]="Etape1FormGroup">
                  <form [formGroup]="Etape1FormGroup">
                        <ng-template matStepLabel>
                              <mat-icon class="icon-display-32" color="accent">folder</mat-icon>&nbsp;&nbsp;&nbsp;
                              <mat-label>Préparation du dossier {{ Etape1FormGroup.numEtiquette }}</mat-label>
                        </ng-template>
                        <div class="content">
                              <div class="titlebox">Référence du dossier</div>

                              <mat-form-field>
                                    <mat-label>Référence</mat-label>
                                    <input matInput type="text" 
                                          id                = "reference" 
                                          formControlName   = "reference" 
                                          oninput           = "this.value = this.value.toUpperCase().replace(/[^a-zA-Z0-9]/g,'')" 
                                          minlength         = "7"
                                          maxlength         = "24"
                                          required
                                          >
                                    <mat-hint>Alphanumérique entre 7 et 24 caractères</mat-hint>
                                    <mat-error *ngIf="reference?.invalid && (reference?.dirty || reference?.touched)">{{ reference.errors?.erreur }}</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Cabinet à facturer</mat-label>
                                    <mat-select name="entite" formControlName="entite"
                                    (selectionChange)="onSelectEntite($event)" required>
                                          <mat-option *ngFor      = "let entite of filterEntitesOfType(allEntite)" 
                                                      [value]     = "entite.id" 
                                                      [disabled]  = "entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE">
                                                {{ entite.raison_sociale }}
                                                <span *ngIf="entite.coordonnees_bancaire.etat.code!=apiEntite.CONST_CODE_ETAT_COORDONNEES_BANCAIRE_VALIDE" style="color: red;"> -- Coordonnées bancaires non validées</span>
                                          </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="entite?.invalid && (entite?.dirty || entite?.touched)">{{ entite.errors?.erreur }}</mat-error>
                              </mat-form-field>
                              
                              <mat-form-field *ngIf="modeCreation" style="max-width:150px ;">
                                    <mat-label>N° étiquette</mat-label>
                                    <mat-select formControlName="numEtiquette" required>
                                          <mat-option *ngFor="let etiquette of etiquettes" [value]="etiquette.numero">{{etiquette.numero}}</mat-option>
                                    </mat-select>
                              </mat-form-field>

                              <mat-form-field *ngIf="!modeCreation" style="max-width:150px ;">
                                    <mat-label>N° étiquette</mat-label>
                                    <input matInput formControlName="numEtiquette" required>
                                </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Compagnie d'assurance</mat-label>
                                    <mat-select name="compagnie" id="compagnie" formControlName="compagnie" (selectionChange)="onSelectChangeCompagnie($event)" required>                              
                                          <mat-option *ngFor="let compagnie of compagnies$" [value]="compagnie">
                                                {{ compagnie.code_gta }} - {{ compagnie.nom }}
                                          </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="compagnie?.invalid && (compagnie?.dirty || compagnie?.touched)">{{ compagnie.errors?.erreur }}</mat-error>
                              </mat-form-field>

                              <mat-form-field *ngIf="compagnie.id == const_autre_compagnie">
                                    <mat-label>Autre compagnie d'assurance</mat-label>
                                    <input matInput type="text" id="autreCompagnie" formControlName="autreCompagnie" oninput="this.value = this.value.toUpperCase()" required>
                                    <mat-error *ngIf="autreCompagnie?.invalid && (autreCompagnie?.dirty || autreCompagnie?.touched)">{{ autreCompagnie.errors?.erreur }}</mat-error>
                              </mat-form-field>
                        </div>
                        <div class="content">
                              <div class="titlebox">Expéditeur de la demande</div>

                              <mat-form-field>
                                    <mat-label>Email du destinataire</mat-label>
                                    <input matInput type="mail" id="emailExpert" formControlName="emailExpert" readonly required>
                                    <mat-error *ngIf="emailExpert?.invalid && (emailExpert?.dirty || emailExpert?.touched)">Le format de l'adresse email est incorrect</mat-error>
                              </mat-form-field>
                        </div>
                        <div class="content">
                              <div class="titlebox">Demande d'analyse pour un particulier</div>
                              <div *ngIf="this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela">
                                    <mat-form-field>
                                          <mat-label>Nom</mat-label>
                                          <input matInput 
                                                type              = "text" 
                                                formControlName   = "nom_particulier" 
                                                oninput           = "this.value = this.value.toUpperCase()" 
                                                maxLength         = "50"
                                                required>
                                          <mat-error *ngIf="nom_particulier?.invalid && (nom_particulier?.dirty || nom_particulier?.touched)">{{ nom_particulier.errors?.erreur }}</mat-error>
                                    </mat-form-field>

                                    <mat-form-field>
                                          <mat-label>Prénom</mat-label>
                                          <input matInput 
                                                type              = "text" 
                                                formControlName   = "prenom_particulier" 
                                                appTitleCase
                                                required>
                                          <mat-error *ngIf="prenom_particulier?.invalid && (prenom_particulier?.dirty || prenom_particulier?.touched)">{{ prenom_particulier.errors?.erreur }}</mat-error>
                                    </mat-form-field>

                                    <mat-form-field>
                                          <mat-label>Email</mat-label>
                                          <input matInput type="mail" id="email_particulier" formControlName="email_particulier" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
                                          <mat-error *ngIf="email_particulier?.invalid && (email_particulier?.dirty || email_particulier?.touched)">{{ email_particulier.errors?.erreur }}</mat-error>
                                    </mat-form-field>
                                    
                                    <app-adresse  controlKey="adresse" [disabledFields]="disableFields" [adresse]="adresseParticulier"></app-adresse>
                              </div>                                                                      
                        
                              <mat-checkbox formControlName="expertiseJudiciaire" style="margin-top: 15px;">Expertise Judiciaire</mat-checkbox>
                        </div>
                        <div class="content">
                              <div class="titlebox">Analyse</div>
            

                              <mat-form-field>
                                    <mat-label>Type d'analyse</mat-label>
                                    <mat-select name="typeAnalyse" id="typeAnalyse" formControlName="typeAnalyse" (selectionChange)="onSelectChangeTypeAnalyse($event)" required>
                                          <mat-option *ngFor="let typeAnalyse of typeAnalyses$" [value]="typeAnalyse">
                                                {{ typeAnalyse.nom }}
                                          </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="typeAnalyse?.invalid && (typeAnalyse?.dirty || typeAnalyse?.touched)">{{ typeAnalyse.errors?.erreur }}</mat-error>
                              </mat-form-field>
                              <mat-label class="label-alert">{{ info_bilan_moteur }}</mat-label>
                        </div>
                        <div class="div-button-right" style="margin-top: 16px;" *ngIf="modeCreation">                              
                              <button mat-flat-button matStepperNext 
                                          color="primary" 
                                          (click)="selectDefaultValue()"
                                          [disabled]="Etape1FormGroup.invalid"><span class="span-button">Suivant</span><mat-icon>east</mat-icon></button>
                        </div>
                  </form>
            </mat-step>
            
            <!-- Informations générales du véhicule -->
            <mat-step [stepControl]="Etape2FormGroup" *ngIf="(form_avarie_sur_vehicule || form_huile_neuve || form_bilan_moteur_preventif || form_vehicule_incendie)">
                  <form [formGroup]="Etape2FormGroup">
                        <ng-template matStepLabel>
                              <mat-icon class="icon-display-32" color="accent">directions_car_filled</mat-icon>&nbsp;&nbsp;&nbsp;
                              Informations générales du véhicule
                        </ng-template>
                        <div>
                              <mat-label *ngIf="form_bilan_moteur_preventif">
                                    <i>Pour rappel, le Bilan Santé Moteur est un contrôle préventif. Ce type d'analyse ne convient absolument pas à une analyse suite à avarie. Veuillez également noter que ce type d'analyse ne comporte pas de diagnostic rédigé.</i>
                              </mat-label>
                        </div>
                        <mat-form-field>
                              <mat-label>Immatriculation</mat-label>
                              <input 
                                    matInput 
                                    type              = "text" 
                                    formControlName   = "immatriculation"
                                    (blur)            = "getInfosVehicule($event)"
                                    appImmat
                              >
                              <mat-error *ngIf="immatriculation?.invalid && (immatriculation?.dirty || immatriculation?.touched)">{{ immatriculation.errors?.erreur }}</mat-error>
                        </mat-form-field>                        
                                          
                        <mat-form-field>
                              <mat-label>N° VIN</mat-label>
                              <input matInput 
                                      type                = "text" 
                                      formControlName     = "vin" 
                                      oninput             = "this.value = this.value.toUpperCase()" 
                                      length              = "17"
                                      >
                              <mat-hint>Alphanumérique de 17 caractères</mat-hint>
                              <mat-error *ngIf="vin?.invalid && (vin?.dirty || vin?.touched)">{{ vin?.errors?.erreur }}</mat-error>
                          </mat-form-field>
                        
                        <ng-container *ngIf="(form_avarie_sur_vehicule || form_bilan_moteur_preventif || form_vehicule_incendie)">
                              <mat-form-field>
                                    <mat-label>Date MEC</mat-label>
                                    <input matInput type="date" id="mec" formControlName="mec">
                                    <mat-error *ngIf="mec?.invalid && (mec?.dirty || mec?.touched)">{{ mec.errors?.erreur }}</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Marque</mat-label>
                                    <input matInput type="text" id="marqueVehicule" formControlName="marqueVehicule" oninput="this.value = this.value.toUpperCase()">
                                    <mat-error *ngIf="marqueVehicule?.invalid && (marqueVehicule?.dirty || marqueVehicule?.touched)">{{ marqueVehicule.errors?.erreur }}</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Modèle</mat-label>
                                    <input matInput type="text" id="modele" formControlName="modele" oninput="this.value = this.value.toUpperCase()">
                                    <mat-error *ngIf="modele?.invalid && (modele?.dirty || modele?.touched)">{{ modele.errors?.erreur }}</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Type Moteur</mat-label>
                                    <input matInput type="text" id="typeMoteur" formControlName="typeMoteur" oninput="this.value = this.value.toUpperCase()">
                                    <mat-error *ngIf="typeMoteur?.invalid && (typeMoteur?.dirty || typeMoteur?.touched)">{{ typeMoteur.errors?.erreur }}</mat-error>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Energie</mat-label>
                                    <mat-select name="energie" id="energie" formControlName="energie">
                                          <mat-option *ngFor="let energie of energies$" [value]="energie">
                                                {{ energie.nom }}
                                          </mat-option>
                                    </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Usage</mat-label>
                                    <mat-select name="usage" id="usage" formControlName="usage">
                                          <mat-option *ngFor="let usage of usages$" [value]="usage">
                                                {{ usage.nom }}
                                          </mat-option>
                                    </mat-select>
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Kms ou Heures</mat-label>
                                    <input matInput type="number" id="kmsHeures" formControlName="kmsHeures" min="0" step="1">
                              </mat-form-field>

                              <mat-form-field>
                                    <mat-label>Capacité Carter</mat-label>
                                    <input matInput type="number" id="capaciteCarter" formControlName="capaciteCarter" min="0.00" step="0.01">
                              </mat-form-field>
      
                              <mat-form-field *ngIf="form_vehicule_incendie">
                                    <mat-label>Incendie</mat-label>
                                    <mat-select name="incendie" id="incendie" formControlName="incendie">
                                          <mat-option *ngFor="let incendie of incendies$" [value]="incendie">
                                                {{ incendie.nom }}
                                          </mat-option>
                                    </mat-select>
                              </mat-form-field>
      
                              <mat-checkbox id="appoint" formControlName="appoint" (change)="isDisabledQuantite($event.checked)">Appoints</mat-checkbox>
                                                                                                            
                              <mat-form-field>          
                                    <mat-label>Qté (L)</mat-label>                    
                                    <input matInput type="number" id="quantite" formControlName="quantite"  min="0.00" step="0.01">
                                    <mat-error *ngIf="quantite?.invalid && (quantite?.dirty || quantite?.touched)">{{ quantite.errors?.erreur }}</mat-error>
                              </mat-form-field>                                                       
                        </ng-container>    
                        
                        <div class="div-button-right" *ngIf="modeCreation">
                              <button mat-flat-button matStepperPrevious type="button" color="basic"><mat-icon>west</mat-icon><span class="span-button">Précédent</span></button>
                              <button mat-flat-button 
                                    matStepperNext 
                                    color="primary"
                                    [disabled]="Etape2FormGroup.invalid"><span class="span-button">Suivant</span><mat-icon>east</mat-icon></button>
                        </div>
                  </form>
            </mat-step>
            
            <!-- Identification du fluide -->
            <mat-step [stepControl]="Etape3FormGroup">
                  <form [formGroup]="Etape3FormGroup">
                        <ng-template matStepLabel>
                              <mat-icon class="icon-display-32" color="accent">science</mat-icon>&nbsp;&nbsp;&nbsp;
                              Identification du fluide
                        </ng-template>

                        <mat-form-field *ngIf="!form_huile_neuve">
                              <mat-label>Type de Fluide</mat-label>
                              <mat-select name="typeFluide" id="typeFluide" formControlName="typeFluide" (selectionChange)="onSelectChangeTypeFluide($event)">
                                    <mat-option *ngFor="let typeFluide of typeFluides$" [value]="typeFluide">
                                          {{ typeFluide.nom }}
                                    </mat-option>
                              </mat-select>                              
                        </mat-form-field>
                        
                        <ng-container *ngIf="form_avarie_sans_vehicule && select_huile_en_service"><i>Le fluide 'Huile en service' n'est pas autorisé pour un contrôle produit. En effet, si c'est une huile en service alors c'est un contrôle sur véhicule.</i></ng-container>
                        
                        <mat-form-field>
                              <mat-label>Grade SAE</mat-label>
                              <input matInput type="text" id="gradeSAE" formControlName="gradeSAE" oninput="this.value = this.value.toUpperCase()">
                              <mat-error *ngIf="gradeSAE?.invalid && (gradeSAE?.dirty || gradeSAE?.touched)">{{ gradeSAE.errors?.erreur }}</mat-error>
                        </mat-form-field> 
                        
                        <mat-form-field *ngIf="!form_huile_neuve">
                              <mat-label>Combustible</mat-label>
                              <mat-select name="combustible" id="combustible" formControlName="combustible">
                                    <mat-option *ngFor="let combustible of combustibles$" [value]="combustible">
                                          {{ combustible.nom }}
                                    </mat-option>
                              </mat-select>                              
                        </mat-form-field>

                        <mat-form-field>
                              <mat-label>Marque du fluide</mat-label>
                              <input matInput type="text" id="marqueFluide" formControlName="marqueFluide" oninput="this.value = this.value.toUpperCase()">
                              <mat-error *ngIf="marqueFluide?.invalid && (marqueFluide?.dirty || marqueFluide?.touched)">{{ marqueFluide.errors?.erreur }}</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                              <mat-label>Type Commercial</mat-label>
                              <input matInput type="text" id="typeCommercial" formControlName="typeCommercial" oninput="this.value = this.value.toUpperCase()">
                              <mat-error *ngIf="typeCommercial?.invalid && (typeCommercial?.dirty || typeCommercial?.touched)">{{ typeCommercial.errors?.erreur }}</mat-error>
                        </mat-form-field> 

                        <mat-form-field *ngIf="!form_huile_neuve">
                              <mat-label>Organe de Prélèvement</mat-label>
                              <mat-select name="organePrelevement" id="organePrelevement" formControlName="organePrelevement" (selectionChange)="onSelectChangeOrganePrelevement($event)">
                                    <mat-option *ngFor="let organePrelevement of organesPrelevement$" [value]="organePrelevement">
                                          {{ organePrelevement.nom }}
                                    </mat-option>
                              </mat-select>                              
                        </mat-form-field>

                        <mat-form-field *ngIf="!form_huile_neuve">
                              <mat-label>Lieu de Prélèvement</mat-label>
                              <mat-select name="lieuPrelevement" id="lieuPrelevement" formControlName="lieuPrelevement">
                                    <mat-option *ngFor="let lieuPrelevement of lieuxPrelevement$" [value]="lieuPrelevement">
                                          {{ lieuPrelevement.nom }}
                                    </mat-option>
                              </mat-select>                              
                        </mat-form-field>

                        <mat-form-field *ngIf="!form_huile_neuve">
                              <mat-label>Température de Prélèvement</mat-label>
                              <mat-select name="temperaturePrelevement" id="temperaturePrelevement" formControlName="temperaturePrelevement">
                                    <mat-option *ngFor="let temperaturePrelevement of temperaturesPrelevement$" [value]="temperaturePrelevement">
                                          {{ temperaturePrelevement.nom }}
                                    </mat-option>
                              </mat-select>                              
                        </mat-form-field>

                        <mat-form-field *ngIf="!form_huile_neuve">
                              <mat-label>Date de Prélèvement</mat-label>
                              <input matInput type="date" id="datePrelevement" formControlName="datePrelevement">
                              <mat-error *ngIf="datePrelevement?.invalid && (datePrelevement?.dirty || datePrelevement?.touched)">{{ datePrelevement.errors?.erreur }}</mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="!form_huile_neuve">
                              <mat-label>Kms ou Heures depuis la dernière vidange</mat-label>
                              <input matInput type="number" id="kmsHeures" formControlName="kmsHeures" min="0" step="1">
                        </mat-form-field>                  

                        <div class="div-button-right" *ngIf="modeCreation">
                              <button mat-flat-button matStepperPrevious type="button" color="basic"><mat-icon>west</mat-icon><span class="span-button">Précédent</span></button>
                              <button     mat-flat-button 
                                          matStepperNext 
                                          color="primary"
                                          [disabled]="Etape3FormGroup.invalid"><span class="span-button">Suivant</span><mat-icon>east</mat-icon></button>
                        </div>
                  </form>
            </mat-step>
      
            <!-- Commentaire ou objet de la demande -->
            <mat-step [stepControl]="Etape4FormGroup">
                  <form [formGroup]="Etape4FormGroup">
                        <ng-template matStepLabel>
                              <mat-icon class="icon-display-32" color="accent">comment</mat-icon>&nbsp;&nbsp;&nbsp;
                              Commentaire ou objet de la demande
                        </ng-template>

                        <mat-form-field style="max-width: 100%" class="textarea">
                              <mat-label>Commentaires</mat-label>
                              <textarea matInput 
                                          formControlName   = "commentaire" 
                                          rows              = "4"
                                          ></textarea>
                              <mat-error *ngIf="commentaire?.invalid && (commentaire?.dirty || commentaire?.touched)">{{ commentaire.errors?.erreur }}</mat-error>
                        </mat-form-field>
                        <!-- EMPLACEMENT ANCIEN UPLOAD -->
                        
                        <app-upload-files   (uploadedFiles)  = "getFiles($event)"
                              [uploadParameter]="uploadParameter"
                                >
                        </app-upload-files>

                        <div class="div-button-right" *ngIf="modeCreation">
                              <button mat-flat-button matStepperPrevious type="button" color="basic"><mat-icon>west</mat-icon><span class="span-button">Précédent</span></button>
                              <button     mat-flat-button 
                                          matStepperNext 
                                          color="primary"
                                          [disabled]="Etape4FormGroup.invalid"><span class="span-button">Suivant</span><mat-icon>east</mat-icon></button>
                        </div>

                  </form>
            </mat-step>

            <!-- Analyse à effectuer -->
            <mat-step [stepControl]="Etape5FormGroup">
                  <form [formGroup]="Etape5FormGroup">

                  <ng-template matStepLabel>
                        <mat-icon class="icon-display-32" color="accent">analytics</mat-icon>&nbsp;&nbsp;&nbsp;
                        Analyse à effectuer
                  </ng-template>
                        <mat-radio-group formControlName="analyse">
                              <table mat-table matTableResponsive [dataSource]="dataSource" class="table-primary">
                                    <!-- Selected Column -->
                                    <ng-container matColumnDef="selecteur">
                                          <th mat-header-cell *matHeaderCellDef class="premiere-colonne"></th>
                                          <td mat-cell *matCellDef="let analyse" class="td-premiere-colonne"><mat-radio-button [value]="analyse.id"></mat-radio-button></td>
                                    </ng-container>

                                    <!-- Analyse Column -->
                                    <ng-container matColumnDef="categorie">
                                          <th mat-header-cell *matHeaderCellDef> Analyse </th>
                                          <td mat-cell *matCellDef="let analyse"> {{analyse.categorie}} </td>
                                    </ng-container>

                                    <!-- Détail Column -->
                                    <ng-container matColumnDef="designation">
                                          <th mat-header-cell *matHeaderCellDef> Détail </th>
                                          <td mat-cell *matCellDef="let analyse"> {{analyse.designation}} </td>
                                    </ng-container>
                  
                                    <!-- Tarif Column -->
                                    <ng-container matColumnDef="tarif">
                                          <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Tarif </th>
                                          <td mat-cell *matCellDef="let analyse" style="text-wrap: nowrap;"> {{analyse.tarif}} € </td>
                                    </ng-container>
                 
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAnalyseAEffectuer"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalyseAEffectuer;"></tr>
                              </table>
                        </mat-radio-group>

                        <br>
                        <div class="div-button-right" *ngIf="modeCreation">
                              <button mat-flat-button matStepperPrevious type="button" color="basic"><mat-icon>west</mat-icon><span class="span-button">Précédent</span></button>                                                            
                              <div class="button-container">                                    
                                    <button     *ngIf="!waiting_Save"
                                                mat-flat-button 
                                                matStepperNext 
                                                style="text-align: center;" 
                                                color="primary" 
                                                [disabled]="Etape1FormGroup.invalid || (Etape2FormGroup.invalid && (form_avarie_sur_vehicule || form_huile_neuve || form_bilan_moteur_preventif || form_vehicule_incendie)) || Etape3FormGroup.invalid || Etape4FormGroup.invalid || Etape5FormGroup.invalid" 
                                                (click)="enregistrerAnalyse()">
                                      <mat-icon>done</mat-icon>
                                      <span class="span-button">Valider ma demande</span>
                                    </button>
                              </div>
                              <mat-spinner *ngIf="waiting_Save" diameter="24" ></mat-spinner>
                        </div>                        
                  </form>
            </mat-step>            

            <mat-step *ngIf="form_confirmation_analyse || form_lecture_seule_analyse_retenue">
                  <form [formGroup]="Etape6FormGroup">

                        <ng-template matStepLabel>
                              <mat-icon class="icon-display-32" color="accent">analytics</mat-icon>&nbsp;&nbsp;&nbsp;
                              Analyse retenue
                        </ng-template>
                              <mat-radio-group formControlName="analyseRetenue">
                                    <table mat-table matTableResponsive [dataSource]="dataSource2" class="table-primary">
                                          <!-- Selected Column -->
                                          <ng-container matColumnDef="selecteur">
                                                <th mat-header-cell *matHeaderCellDef class="premiere-colonne"></th>
                                                <td mat-cell *matCellDef="let analyse" class="td-premiere-colonne"><mat-radio-button [value]="analyse.id" class="td-premiere-colonne"></mat-radio-button></td>
                                          </ng-container>
      
                                          <!-- Analyse Column -->
                                          <ng-container matColumnDef="categorie">
                                                <th mat-header-cell *matHeaderCellDef> Analyse </th>
                                                <td mat-cell *matCellDef="let analyse"> {{analyse.categorie}} </td>
                                          </ng-container>

                                          <!-- Détail Column -->
                                          <ng-container matColumnDef="designation">
                                                <th mat-header-cell *matHeaderCellDef> Détail </th>
                                                <td mat-cell *matCellDef="let analyse"> {{analyse.designation}} </td>
                                          </ng-container>

                                          <!-- Type analyse -->
                                           <ng-container matColumnDef="typeAnalyse">
                                                <th mat-header-cell *matHeaderCellDef> Type d'analyse </th>
                                                <td mat-cell *matCellDef="let analyse"> {{getTypeAnalyse(analyse.idTypeAnalyse)}} </td>
                                          </ng-container>

                                          <!-- fluide Column -->
                                          <ng-container matColumnDef="fluide">
                                                <th mat-header-cell *matHeaderCellDef> Type de fluide </th>
                                                <td mat-cell *matCellDef="let analyse"> {{getTypeFluide(analyse.idFluide)}} </td>
                                          </ng-container>

                                          <!-- Organe de prélèvement Column -->
                                          <ng-container matColumnDef="Organe">
                                                <th mat-header-cell *matHeaderCellDef> Organe de prélèvement </th>
                                                <td mat-cell *matCellDef="let analyse"> {{getOrganePrelevement(analyse.idOrgane)}} </td>
                                          </ng-container>
                        
                                          <!-- Tarif Column -->
                                          <ng-container matColumnDef="tarif">
                                                <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Tarif </th>
                                                <td mat-cell *matCellDef="let analyse" style="text-wrap: nowrap;"> {{analyse.tarif}} € </td>
                                          </ng-container>
                       
                                          <tr mat-header-row *matHeaderRowDef="displayedColumnsAnalyseRetenue"></tr>
                                          <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalyseRetenue;"></tr>
                                    </table>
                              </mat-radio-group>
      
                              <br>
                              <div class="div-button-right" *ngIf="form_confirmation_analyse" style="gap: 8px;">
                                    <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted)">                                          
                                          <button mat-flat-button 
                                                      matStepperNext 
                                                      style       = "text-align: center;" 
                                                      color       = "warn" 
                                                      [disabled]  = "waitingRefused" 
                                                      (click)     = "refuserDemandeADELA()">
                                            <mat-icon>close</mat-icon>
                                            <span class="span-button">Refuser la demande</span>
                                          </button>
                                    </div>                                    
                                    <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted)">                                          
                                          <button mat-flat-button 
                                                      matStepperNext 
                                                      style       = "text-align: center;" 
                                                      color       = "primary" 
                                                      [disabled]  = "waitingAccepted || !Etape6FormGroup.valid" (click)="validerDemandeADELA()">
                                            <mat-icon>done</mat-icon>
                                            <span class="span-button">Envoyer code VERNOLAB</span>
                                          </button>
                                    </div>
                                    <div class="button-container" *ngIf="!(waitingModified || waiting_Save || waitingRefused || waitingAccepted)">                                          
                                          <button     mat-flat-button 
                                                      matStepperNext 
                                                      style       = "text-align: center;" 
                                                      color       = "primary" 
                                                      [disabled]  = "waitingModified || !Etape6FormGroup.valid" 
                                                      (click)     = "modifierDemandeADELA()">
                                            <mat-icon>save</mat-icon>
                                            <span class="span-button">Enregistrer les modifications</span>
                                          </button>
                                    </div>    
                                    <mat-spinner diameter="24" *ngIf="waitingModified || waiting_Save || waitingRefused || waitingAccepted"></mat-spinner>                                
                              </div>                        
                        </form>
            </mat-step>
            
            <mat-step *ngIf="!form_bilan_moteur_preventif && form_resultat_analyse">
                  <ng-template matStepLabel>
                        <mat-icon class="icon-display-32" color="accent">event</mat-icon>&nbsp;&nbsp;&nbsp;
                        Date de réception de l'échantillon
                  </ng-template>
                  <form [formGroup]="Etape7FormGroup">
                        <mat-form-field>
                              <mat-label>Date réception échantillon</mat-label>
                              <input matInput type="date" id="depot_flacon" formControlName="depot_flacon" readonly>
                        </mat-form-field>
                  </form>
            </mat-step>
      </mat-stepper>
      <mat-card-actions align="end">
            <button mat-raised-button color="primary" (click)="closeDossier()">Fermer</button>
      </mat-card-actions>
</mat-card>

<app-resultat-analyse-adela *ngIf="form_resultat_analyse" 
      [idDossier]="form_adela.idDossier" 
      [idEtatDossier]="form_adela.idEtatDossier"
      [form_bsm]="form_bilan_moteur_preventif"
      [habilitation]="form_adela.habilitation"
      [destinataire]="Etape1FormGroup.controls.emailExpert.value"
      [particulier]="form_adela.email_particulier">
      (closeDossier)="closeDossier()"
</app-resultat-analyse-adela>

<br>
<div class="footer">
      <i>Les informations collectées vous concernant sont destinées à l'ARD dans le cadre de sa gestion du service ADELA.
      Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée (art. 38 s.), vous disposez d'un droit d'accès, de modification, de rectification et de suppression
      des données vous concernant. Pour toute demande, adressez-vous par courrier à : ARD, 41-43, rue des Plantes, 75014 PARIS ou par courrier électronique à : ard@ffea.fr.</i>
</div>