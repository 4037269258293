import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { SuiviFiltre, SuiviFiltreInitial } from '../../interfaces/suivi';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { NgFor } from '@angular/common';
import { ImmatDirective } from 'src/app/directive/immat.directive';

@Component({
  selector: 'app-filter-suivi',
  templateUrl: './filter-suivi.component.html',
  styleUrls: ['./filter-suivi.component.scss'],
  standalone:true,
  imports:[MatCardModule,MatFormFieldModule,MatSelectModule,MatInputModule,MatButtonModule,
    ReactiveFormsModule, FormsModule, MatProgressSpinnerModule,MatSlideToggleModule,NgFor
    , ImmatDirective]
})

export class FilterSuiviComponent {
  
  @Input()  relUtiEntites:RelationUtilisateurEntite[]  = []
  @Output() filterSelected    = new EventEmitter<SuiviFiltre>();
  
  filterDossier           : string=''
  filterDateCreationDebut : string=''
  filterDateCreationFin   : string=''
  filterDateEnvoiDebut    : string=''
  filterDateEnvoiFin      : string=''
  filterImmat             : string=''
  filterArchive           : boolean = false
  filterEntiteId          : number = 0

  selectFilter(){
    const FiltreSuivi:SuiviFiltre = {...SuiviFiltreInitial, 
                                        'date_creation_debut' :this.filterDateCreationDebut
                                      , 'date_creation_fin'   : this.filterDateCreationFin
                                      , 'date_envoi_debut'    : this.filterDateEnvoiDebut
                                      , 'date_envoi_fin'      : this.filterDateEnvoiFin
                                      , 'dossier'             : this.filterDossier
                                      , 'immat'               : this.filterImmat.replaceAll('-','')
                                      , 'archive'             : this.filterArchive
                                      , 'idEntite'            : this.filterEntiteId
                                    }
    this.filterSelected.emit(FiltreSuivi)
  }

}
