import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Parametre } from 'src/app/interfaces/parametre';
import { ApiParametreService } from 'src/app/services/api-parametre.service';
import { ParametreEditComponent } from './parametre-edit/parametre-edit.component';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';



@Component({
    selector: 'app-gestion-parametre',
    templateUrl: './gestion-parametre.component.html',
    styleUrls: ['./gestion-parametre.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, NgFor, MatGridListModule, MatButtonModule, AsyncPipe]
})
export class GestionParametreComponent implements OnInit {
  
  parametres$       : Observable<Parametre[]> = this.apiParametres.parametres$
  typesParametres$  : Observable<string[]>    = this.apiParametres.typesParametres$

  constructor(private apiParametres : ApiParametreService
              , public dialog:MatDialog
              , private toast: PersoSnackbarService
              ) { }

  ngOnInit(): void {
   
  }

  openEditParameter(parametre:Parametre):void{
    const dialogRef = this.dialog.open(ParametreEditComponent,{data:parametre})

    dialogRef.afterClosed().subscribe((parametreChanged:Parametre|boolean)=>{
      
      
        if (typeof parametreChanged != "boolean") {
          this.apiParametres.putParametre([parametreChanged]).subscribe(()=>this.toast.validate('Le paramètre a bien été modifié')
          )
        }
      }
    )
  }
}
