import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Parametre } from 'src/app/interfaces/parametre';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';


@Component({
    selector: 'app-parametre-edit',
    templateUrl: './parametre-edit.component.html',
    styleUrls: ['./parametre-edit.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class ParametreEditComponent implements OnInit {

  parametreForm!    : FormGroup
  parametreChanged  : Parametre = this.data

  constructor( public dialogRef: MatDialogRef<ParametreEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Parametre
              , private fb :FormBuilder) { }

  ngOnInit(): void {
    this.initForm()
  }

  initForm(){
    
    this.parametreForm = this.fb.group({
      parametreNote:[this.data.note],
      parametreValeur:[this.data.valeur],
      parametreType:[this.data.type],
      parametreCode:[this.data.code]
    })
  }

  onValidate(){
    
    this.parametreChanged.code    = this.parametreForm.controls.parametreCode.value
    this.parametreChanged.note    = this.parametreForm.controls.parametreNote.value
    this.parametreChanged.type    = this.parametreForm.controls.parametreType.value
    this.parametreChanged.valeur  = this.parametreForm.controls.parametreValeur.value

    this.dialogRef.close(this.parametreChanged)
  }

  onCancel(){
    this.dialogRef.close(false)
  }

}
