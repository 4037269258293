
        <!-- Logo FFEA en haut à gauche -->
        <ng-container *ngIf="isServiceFFEA; then isService else isExtranet"></ng-container>

        
        <!-- SERVICE FFEA : -->
        <ng-template #isService>
            <mat-toolbar color="primary" class="navbar-service" [ngClass]="classNavBar">
                <div>
                    <a mat-button routerLinkActive="active" (click)="naviguerVers()">
                        <img src="../../../../assets/img/charte/FFEA MINI 1 ligne aplat color.png" height="48">
                    </a>
                </div>
                
                <div *ngIf="bModeConnecte">
                    <!-- Nom + Prénom -->
                    <a mat-button routerLinkActive="active" (click)="clickOnAccount()">
                        <mat-icon class="img-profil" *ngIf="!utl_profil?.photo_profil?.profile_image">account_circle</mat-icon>
                        <img class="img-profil" style="margin-right: 5px;" *ngIf="utl_profil?.photo_profil?.profile_image" [src]="_sanitizer.bypassSecurityTrustResourceUrl(utl_profil.photo_profil.profile_image)">
                        <span class="span-a">{{utl_profil.nom }} {{ utl_profil.prenom}}</span>
                    </a>

                    <!-- Besoin d'aide ? -->
                    <a mat-button routerLinkActive="active" routerLink="/svc/support/nous-contacter">
                        <mat-icon>support</mat-icon>
                        <span class="span-a">Besoin d'aide ?</span>
                    </a>
                    
                    <button mat-icon-button aria-label="Menu" (click)="triggerToolbar()">
                        <mat-icon>menu</mat-icon>
                    </button>

                </div>
            </mat-toolbar>
        
        </ng-template>
        
        <!-- EXTRANET FFEA : -->
        <ng-template #isExtranet>
            <mat-toolbar color="primary" class="navbar" [ngClass]="classNavBar">
                <div>
                    <a mat-button routerLinkActive="active" (click)="naviguerVers()">
                        <img src="../../../../assets/img/charte/FFEA 1 ligne negat blanc.png" height="48">
                    </a>
                </div>
                <span class="span-a" >{{title.getTitle()}}</span>
                <div *ngIf="bModeConnecte">
            
                    <!-- installation de l'application sur la plateforme -->
                    <a mat-button 
                        class="label-basic"
                        (click)="install()" *ngIf="(canInstall$ | async)">
                        <mat-icon color="basic">install_desktop</mat-icon>
                        <span class="span-a">Ajouter à l'écran d'accueil</span>
                    </a>
                    
                    <!-- mise à jour de l'application sur la plateforme -->
                    <a mat-button 
                        class="label-basic"
                        (click)="updateApp()" *ngIf="hasNewUpdate$ | async">
                        <mat-icon color="basic">browser_updated</mat-icon>
                        <span class="span-a">Recharger</span>
                    </a>
            
                    <!-- Nom + Prénom -->
                    <a mat-button routerLinkActive="active" (click)="clickOnAccount()">
                        <mat-icon class="img-profil" *ngIf="!utl_profil?.photo_profil?.profile_image">account_circle</mat-icon>
                        <img class="img-profil" style="margin-right: 5px;" *ngIf="utl_profil?.photo_profil?.profile_image" [src]="_sanitizer.bypassSecurityTrustResourceUrl(utl_profil.photo_profil.profile_image)">
                        <span class="span-a">{{utl_profil.nom }} {{ utl_profil.prenom}}</span>
                    </a>
                    

                    <ng-container *ngIf="notifications$ |async as notifications">
                        <button  
                            mat-mini-fab
                            [color]             = "notifications.length === 0 ? 'primary' : 'accent'" 
                            [matMenuTriggerFor] = "menu" 
                            >
                            <mat-icon   
                                        [matBadge]          = "notifications.length" 
                                        matBadgeColor       = "warn"
                                        [matBadgeHidden]    = "notifications.length === 0"
                                        >notifications</mat-icon>
                        </button>

                        <mat-menu #menu="matMenu">
                            <button mat-menu-item 
                                    (click)="notificationService.changeStateLastNotifications()">
                                Afficher les {{notificationService.NbLastNotifications}} dernières notifications
                            </button>
                            <button mat-menu-item 
                                    *ngIf="notifications.length>0"
                                    (click)="notificationService.lireToutesNotifications()">
                                Marquer toutes les notifications comme lues
                            </button>
                            <button mat-menu-item 
                                    *ngFor = "let notification of notifications" 
                                    (click) = "notificationService.clickItemMenu(notification)"
                                    >
                                <mat-icon>{{notificationService.getIconeNotification(notification)}}</mat-icon>
                                <span>{{notification.notifies![0].notification}}</span>
                            </button>
                            
                        </mat-menu>

                    </ng-container>
                    

                    <!-- Besoin d'aide ? -->
                    <a mat-button routerLinkActive="active" routerLink="/app/support/nous-contacter">
                        <mat-icon>support</mat-icon>
                        <span class="span-a">Besoin d'aide ?</span>
                    </a>
                    
                    <!-- <button mat-icon-button [matMenuTriggerFor]="main_menu" aria-label="Menu" (click)="triggerToolbar()">
                        <mat-icon>menu</mat-icon>
                    </button> -->
                    <button mat-icon-button aria-label="Menu" (click)="triggerToolbar()">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>

            </mat-toolbar>
        </ng-template>
    
        
        
