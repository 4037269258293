import { Injectable } from '@angular/core';

export const TYPE_CABINET       = 'cabinet'
export const TYPE_UTILISATEUR   = 'utilisateur'
export const TYPE_RDEA          = "rdea"
export const TYPE_FOURNISEUR     = "fournisseur"
export const TYPE_DEMANDE        = "demande"
export const TYPE_FACTURE        = "facture"
export const TYPE_INFOBANCAIRE   = "infobancaire"
export const TYPE_ARTICLE         = "article"

export interface Contexte{
  type        : string
  liste       : any[]
  filtre      : any
  listeFiltre : any[]
  pageIndex   : number
  pages       : number
}

@Injectable({
  providedIn: 'root'
})

export class ContexteService {
  private contexte : Contexte={'type':'','filtre':null,'liste':[],'pageIndex':0,'pages':5,listeFiltre:[]}

  constructor() { }

  setType(type:string){
    this.contexte.type = type
  }

  setContexte(contexte:Contexte){
    this.contexte = contexte
  }

  setFiltreListe(liste:any[]){
    this.contexte.listeFiltre = liste
  }

  getFiltreListe(type:string){
    if (type===this.contexte.type) {
      return this.contexte? this.contexte.listeFiltre : []
    }else{
      return []
    }
  }

  getContexte(){
    return this.contexte
  }

  setTableData(data:any[]){
    this.contexte.liste = data
  }

  getTableData(type:string){
    if (type===this.contexte.type) {
      return this.contexte? this.contexte.liste : []
    }else{
      return []
    }
  }

  setPageIndex(index:number){
    this.contexte.pageIndex = index
  }

  getPageIndex(type:string){
    if (type===this.contexte.type) {
      return this.contexte? this.contexte.pageIndex :0
    }else{
      return 0
    }
  }

  setFiltre(filtre:any){
    this.contexte.filtre = filtre
  }

  getFiltre(type:string){
    if (type===this.contexte.type) {
      return this.contexte.filtre
    }else{
      return null
    }
  }
  setPages(pages:number){
    this.contexte.pages = pages
  }

  getPages(type:string){
    if (type===this.contexte.type) {
      return this.contexte.pages
    }else{
      return 5
    }
  }
}
