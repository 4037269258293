<fieldset class="cadre">
    <legend class="legend">{{label}}</legend>
    <div [formGroupName]="controlKey" class="container">
                <mat-form-field>
                    <mat-label>Adresse</mat-label>
                    <input 
                        matInput 
                        type                = "text" 
                        formControlName     = "adresseLigne1" 
                        [matAutocomplete]   = "auto"
                        (keyup)             = "getAdresse($event)"
                    >
                        <mat-autocomplete #auto = "matAutocomplete">
                                <mat-option
                                    *ngFor      = "let adresse of adresses$|async"
                                    [value]     = "adresse.properties.name"
                                    (onSelectionChange)="optionSelectionChange(
                                            adresse
                                    )"
                                >
                                {{adresse.properties.label}}
                                </mat-option>
                        </mat-autocomplete>
                    <!-- <mat-error *ngIf="adresseLigne1?.invalid && (adresseLigne1?.dirty || adresseLigne1?.touched)">{{ adresseLigne1?.errors?.erreur }}</mat-error> -->
                    <mat-error *ngIf="adresseLigne1?.hasError('required')">L'adresse est obligatoire</mat-error>
                </mat-form-field>
        
                <mat-form-field *ngIf="adresse.ligne2!=undefined">
                    <mat-label>Complément</mat-label>
                    <input matInput 
                        type            = "text"  
                        formControlName = "adresseLigne2"
                        placeholder     ="N° appartement, boite aux lettres, étage, couloir" 
                        >
                </mat-form-field>

                <mat-form-field *ngIf="adresse.ligne3!=undefined">
                    <mat-label>Complément</mat-label>
                    <input matInput 
                        type            = "text"  
                        formControlName = "adresseLigne3"
                        placeholder     = "entrée, tour, bâtiment, immeuble, résidence" 
                        >
                </mat-form-field>

                <mat-form-field style="max-width: 110px;">
                    <mat-label>Code Postal</mat-label>
                    <input matInput 
                        type                = "text"
                        oninput             = "this.value=this.value.replace(/[^0-9]/g,'');"
                        formControlName     = "adresseCP" 
                        maxlength           = "5"
                        minlength           = "5"
                        >
                    <!-- <mat-error *ngIf="adresseCP?.invalid && (adresseCP?.dirty || adresseCP?.touched)">CP incorrect</mat-error> -->
                    <mat-error *ngIf="adresseCP?.hasError('required')">Le code postal est obligatoire</mat-error>
                </mat-form-field>
                
                <mat-form-field >
                    <mat-label>Ville</mat-label>
                    <input matInput 
                        type                = "text" 
                        formControlName     = "adresseVille" 
                        oninput             = "this.value = this.value.toUpperCase()" 
                        >
                    <!-- <mat-error *ngIf="adresseVille?.invalid && (adresseVille?.dirty || adresseVille?.touched)">{{ adresseVille?.errors?.erreur }}</mat-error> -->
                    <mat-error *ngIf="adresseVille?.hasError('required')">La ville est obligatoire</mat-error>
                </mat-form-field>
    </div>
</fieldset>