<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Sélectionner votre filtre</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mat-card-content">
    
        
        <mat-form-field>
            <mat-label>Numéro de dossier contient</mat-label>
            <input  type        = "text" 
                    matInput 
                    [(ngModel)] = "filterDossier"
                    (keyupX)     = "setFilter.click()">
                    
        </mat-form-field>

        <mat-form-field>
            <mat-label>Immatriculation</mat-label>
            <input  type        = "text" 
                    matInput 
                    appImmat
                    [(ngModel)] = "filterImmat"
                    (keyupX)     = "setFilter.click()">
                    
        </mat-form-field>
        
        <mat-form-field>
            <mat-label>Cabinet</mat-label>
            <mat-select [(ngModel)]="filterEntiteId">
                <mat-option [value]="0">Tous</mat-option>
                <mat-option *ngFor="let relUtient of relUtiEntites" [value]="relUtient.entite.id">
                    {{relUtient.entite.raison_sociale}}
                  </mat-option>
            </mat-select>
        </mat-form-field>
        <div>

            <mat-form-field class="formDate">
                <mat-label>Créé à partir de</mat-label>
                <input  matInput 
                        type ="date"
                        [(ngModel)]="filterDateCreationDebut"
                        (changeX)="setFilter.click()"
                        >
                        
            </mat-form-field>
    
            <mat-form-field class="formDate">
                <mat-label>Créé jusqu'au</mat-label>
                <input  matInput  
                        type="date"
                        [(ngModel)]="filterDateCreationFin"
                        (changeX)="setFilter.click()"
                        >
                        
            </mat-form-field>
    
            <mat-form-field class="formDate">
                <mat-label>Envoyé à partir de</mat-label>
                <input  matInput 
                        type ="date"
                        [(ngModel)]="filterDateEnvoiDebut"
                        (changeX)="setFilter.click()">
                        
            </mat-form-field>
    
            <mat-form-field class="formDate">
                <mat-label>Envoyé jusqu'au</mat-label>
                <input  matInput  
                        type="date"
                        [(ngModel)]="filterDateEnvoiFin"
                        (changeX)="setFilter.click()"
                        >
                        
            </mat-form-field>
        </div>

        <mat-slide-toggle [(ngModel)]="filterArchive">{{filterArchive? "Archivés" : "En cours"}}</mat-slide-toggle>

    </mat-card-content>
    <mat-card-actions  align="end">
        <button mat-raised-button color="primary" (click)="selectFilter()">Rechercher</button>
        <button style="display: none;" #setFilter (click)="selectFilter()"></button>
    </mat-card-actions>
</mat-card>

