import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { BehaviorSubject } from 'rxjs';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Entite, EntiteInit } from 'src/app/interfaces/profil-user/entite';
import { EntiteTva } from 'src/app/interfaces/profil-user/entite-tva';
import { TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { Utilisateur, UtilisateurEntite, UtilisateurInit } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { TitleCaseDirective } from '../../../directive/title-case.directive';
import { GroupeService } from '../../groupe/data-access/groupe.service';
import { Groupe } from '../../groupe/groupe-interface';
import { AdresseComponent } from '../../templates/adresse/adresse.component';
import { SirenComponent } from '../../templates/siren/siren.component';

@Component({
    selector: 'app-entite-creation',
    templateUrl: './entite-creation.component.html',
    styleUrls: ['./entite-creation.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf,
             MatInputModule, SirenComponent, AdresseComponent, TitleCaseDirective, MatButtonModule,
              MatProgressSpinnerModule, MatGridListModule]
})
export class EntiteCreationComponent implements OnInit,OnChanges {

  @Input() typesEntite  : TypeEntite[]      = []
  @Input() waitingManuel$! : BehaviorSubject<boolean>
  @Input() tvas             : EntiteTva[] = []
  @Output() entiteEvent = new EventEmitter<UtilisateurEntite|null>()

  entiteForm  = this.formBuilder.group({
    typeEntite    : new FormControl<TypeEntite|null>(null,Validators.required),
    compteTiers   : new FormControl(''),
    habilitation  : new FormControl(''),
    telephone     : new FormControl(''),
    groupe        : new FormControl<number>(0,Validators.required),
    mail          : new FormControl('',Validators.required),
    nom           : new FormControl('',Validators.required),
    prenom        : new FormControl('',Validators.required),
    agrement      : new FormControl(''),
    tva             : new FormControl<number>(0,{nonNullable: true,validators:[Validators.required]}),
    mailfacturation : new FormControl<string>('',{nonNullable:true}),
    mailcontact     : new FormControl<string>('',{nonNullable:true})
  })

  get mail()            { return this.entiteForm.get('mail'); }
  get nom()             { return this.entiteForm.get('nom'); }
  get prenom()          { return this.entiteForm.get('prenom'); }
  get agrement()        { return this.entiteForm.get('agrement'); }
  get tva()             { return this.entiteForm.get('tva'); }
  get mailfacturation() { return this.entiteForm.get('mailfacturation'); }
  get mailcontact()     { return this.entiteForm.get('mailcontact'); }
  get habilitation()    {return this.entiteForm.get('habilitation')}
  get groupe()           {return this.entiteForm.get('groupe')}

  adresse               : Adresse = AdresseInit
  siretValid            : boolean = false
  waiting               : boolean = false
  typeEntiteSelected    : TypeEntite={'code':'','env_id':0,'id':0,'libelle':'','defaut':0}
  breakpointSmall       : boolean = true
  groupesDisplayed      : Groupe[]=[]
  habilitationRequired  : boolean = true

  constructor(  
              private formBuilder     : FormBuilder
            , private apiGroupe       : GroupeService
            , private apiAuth         : ApiAuthentificationService
            , private apiEntite       : ApiEntiteService
              ) { }

  ngOnInit(): void {
    //For responsiveness:
    this.breakpointSmall = (window.innerWidth <= 600) ? true : false;
    
    this.waitingManuel$.subscribe(
      (data:boolean)=>this.waiting = data
    )
    this.initialiseTva();
    this.initialiseTypeEntite();
  }
  
  onResize(event:any) {
    //For responsiveness:
    this.breakpointSmall = (event.target.innerWidth <= 600) ? true : false;
  }

  ngOnChanges(){
  }
  
  initialiseTva(){
    const tvaId = this.tvas.find((tva:EntiteTva)=> tva.libelle==='FR')? this.tvas.find((tva:EntiteTva)=> tva.libelle==='FR')!.id : 0
    this.tva?.setValue(tvaId)
  }

  initialiseTypeEntite() {
        // Pour les administrateurs adela
        // Le type d'entité Adela doit être sélectionné par défaut
        if (this.apiAuth.userConnect.droits_utilisateur.droit_module_admin_adela && !this.apiAuth.userConnect.droits_utilisateur.est_admin) {
          let typeEntite : TypeEntite | undefined;
          typeEntite = this.typesEntite.find(type => type.code === this.apiEntite.CONST_CODE_TYPE_ENTITE_ADELA);
    
          if (typeEntite != undefined) {
            this.entiteForm.patchValue({'typeEntite': typeEntite});
            this.entiteForm.controls['typeEntite'].disable();
            this.setTypeEntite(typeEntite);
          }
        }  
  }

  setAdresse(adresse:Adresse){
    this.adresse = adresse
  }
  
  annuler(){
    this.entiteEvent.next(null)
  }
  
  setTypeEntite(typeEntite:TypeEntite){
    let groupe : Groupe|undefined;
    this.typeEntiteSelected = typeEntite;
    
    this.apiGroupe.getGroupesParCodeEntite(typeEntite.code)
    .subscribe(
      data => {
        this.groupesDisplayed = data;

        if (this.groupesDisplayed.length===1) {
          //we display the only item provided:
          this.entiteForm.patchValue({'groupe':this.groupesDisplayed[0].id});
        }else{
          // On affiche la valeur par défaut
          groupe =  this.groupesDisplayed.find( (groupe) => ( groupe.GroupeParDefaut == true ));
          this.entiteForm.patchValue({'groupe': groupe != undefined? groupe.id:0});
        }
      }
    )

    this.checkValidators();
  }

  enregistrer(){
    this.waiting=true
    const entite : Entite = {...EntiteInit
                              , 'habilitation'    : this.habilitation!.value!
                              , 'adresse'         : this.entiteForm.get('groupAdresse.adresseLigne1')!.value
                              , 'adresse_cplt'    : this.entiteForm.get('groupAdresse.adresseLigne2')!.value
                              , 'adresse_ligne3'  : this.entiteForm.get('groupAdresse.adresseLigne3')!.value
                              , 'code_postal'     : this.entiteForm.get('groupAdresse.adresseCP')!.value
                              , 'ville'           : this.entiteForm.get('groupAdresse.adresseVille')!.value
                              , 'raison_sociale'  : this.entiteForm.get('groupSiren.raisonSociale')!.value
                              , 'siren'           : this.entiteForm.get('groupSiren.siren')!.value
                              , 'siret'           : this.entiteForm.get('groupSiren.siret')!.value
                              , 'tva'             : {'id': this.tva!.value,'libelle':'', 'pourcentage':''}
                              , 'mail_facturation': this.mailfacturation!.value
                              , 'mail_contact'    : this.mailcontact!.value
                              , 'EstActif'        : true
                              , 'EstInscrit'      : true
                              , 'id_type_entite'  : this.typeEntiteSelected.id
                            }
    
    const utilisateur : Utilisateur = {...UtilisateurInit 
                                        , 'agrement_ve' : this.entiteForm.get('agrement')!.value!
                                        , 'email'       : this.entiteForm.get('mail')!.value!
                                        , 'idgrp'       : +this.entiteForm.get('groupe')!.value!
                                        , 'nom'         : this.entiteForm.get('nom')!.value!
                                        , 'prenom'      : this.entiteForm.get('prenom')!.value!
                                      }
    
    const utilisateurEntite:UtilisateurEntite = {'entite':entite,'utilisateur':utilisateur}
    
    this.entiteEvent.next(utilisateurEntite)
  }

  private checkValidators() {
    // selon le type d'entité sélectionné, le formulaire doit réagir différemment     
    switch (this.typeEntiteSelected.code) {
      case this.apiEntite.CONST_CODE_TYPE_ENTITE_ADELA :
        this.habilitationRequired = false;
        this.habilitation?.clearValidators()
        
        break;

      default:
        this.habilitationRequired = true;
        // this.entiteForm.controls.habilitation.setValidators([Validators.required]);
        // this.entiteForm.controls.agrement.setValidators([Validators.required]);
        break;
    }
  }
}
