import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {  FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatSlideToggleModule, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { UtilisateurParam, UtilisateurParamService, UtilisateurParamVal, UtilisateurParamValExtended } from 'src/app/services/utilisateur-param.service';
import { MailValidator } from 'src/app/validators/mail.validator';

@Component({
  selector: 'app-parametre-utilisateur',
  standalone: true,
  imports: [CommonModule, MatSlideToggleModule, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, NgFor, NgIf, ReactiveFormsModule],
  templateUrl: './parametre-utilisateur.component.html',
  styleUrls: ['./parametre-utilisateur.component.scss']
})
export class ParametreUtilisateurComponent implements OnInit, OnChanges {

  @Input() inputParamVal  : UtilisateurParamValExtended = <UtilisateurParamValExtended>{};
  @Input() idUtilisateur  : number = 0;
  @Input() idEntite       : number = 0;
  
  paramVal       : UtilisateurParamValExtended = <UtilisateurParamValExtended>{};
  
  paramForm = this.fb.group({
    valeur        : new FormControl<string>(this.inputParamVal.utl_pva_valeur, [Validators.required, this.MailValidator.checkValidatorMail()])
  })

  get valeur()   { return this.paramForm.get('valeur'); }

  constructor(  private apiUtiParam     : UtilisateurParamService
              , private apiToast        : PersoSnackbarService
              , private fb              : FormBuilder
              , private MailValidator   : MailValidator){
  }

  ngOnChanges(): void {
    if(this.inputParamVal != undefined) {
      this.paramForm.patchValue({valeur : this.inputParamVal.utl_pva_valeur}) 
      this.paramForm.markAllAsTouched();
      this.paramVal =  this.inputParamVal;
    }
  }

  ngOnInit(): void {
    this.ngOnChanges();
  }

  handleButtonClick(paramVal: UtilisateurParamValExtended): void {
    
    if (paramVal.isActivated) {
      // Si activé, créer ou mettre à jour
      if (paramVal.utl_pva_id > 0) {
        this.onUpdate(paramVal);
      } else {
        this.onCreate(paramVal);
      }
    } else {
      // Si désactivé, supprimer
      if (paramVal.utl_pva_id > 0) {
        this.onDelete(paramVal.utl_pva_id);
      }
    }
  }

  toggleParam(paramVal: UtilisateurParamValExtended, event: MatSlideToggleChange): void {
    paramVal.isActivated = event.checked;
    if (!event.checked) {
      paramVal.value = '';  // Réinitialise la valeur si désactivé
      // paramVal.utl_pva_id = 0;  // Réinitialise l'ID pour indiquer qu'il n'est pas actif
    }
  }

  onCreate(paramVal: UtilisateurParamValExtended): void {
    const utiParam : UtilisateurParam = {'utl_prm_code':'','utl_prm_id':paramVal.utiParam!.utl_prm_id,'utl_prm_note':'','utl_prm_nom':'','utl_prm_type':''}
    const newParamVal :UtilisateurParamVal= {
      utl_pva_id        : 0,
      utl_pva_utl_id    : this.idUtilisateur,
      utl_pva_ent_id    : this.idEntite,
      utl_pva_valeur    : this.paramForm.controls.valeur.value as string,
      utiParam          : utiParam
    };

    this.apiUtiParam.createParamValeur(newParamVal).subscribe((response:UtilisateurParamVal) => {
      this.apiToast.showInfo('Le paramètre a bien été créé');
      paramVal.utl_pva_id = response.utl_pva_id;  // Mettre à jour l'ID après création
    });
  }

  onUpdate(paramVal: UtilisateurParamVal): void {
    const updatedParamVal = {
      utl_pva_id        : paramVal.utl_pva_id,
      utl_pva_utl_id    : this.idUtilisateur,
      utl_pva_ent_id    : this.idEntite,
      utl_pva_param_id  : paramVal.utiParam!.utl_prm_id,
      utl_pva_valeur    : this.paramForm.controls.valeur.value as string
    };
    
    this.apiUtiParam.updateParamValeur(updatedParamVal).subscribe(response => {
      this.apiToast.showInfo('Le paramètre a bien été modifié');
    });
  }

 onDelete(valueId: number): void {
  
    if (valueId > 0) {
      this.apiUtiParam.deleteParamValeur(valueId).subscribe(response => {
        this.apiToast.showInfo('Le paramètre a bien été supprimé');
         // Réinitialiser l'état après suppression
          this.paramVal.isActivated = false;
          this.paramVal.value       = '';
          this.paramVal.utl_pva_id  = 0;
          
          this.paramForm.patchValue({valeur : ''})
          this.paramForm.updateValueAndValidity(); 
      });
    }
  }

  getPlaceHoler(codeParametre: string | undefined): string {
    let placeholder : string = "valeur";

    if (codeParametre != undefined) {
      switch (codeParametre) {
        case this.apiUtiParam.CONST_UTI_PARAM_CODE_RAPPORT_1B_NON_ENVOYEES:
          placeholder = "Adresse email";
          break;
      }
    }

    return placeholder
  }

}
