<mat-card appearance="outlined">
    <div>
          <h2>ADELA : Analyse de poudre</h2>
      </div>

      <div *ngIf="!modeCreation" 
            class="div-container-left etat"
            [ngStyle]="{ color: apiAdela.getColorByStatus(etatCode) }">
        <mat-icon color="primary" svgIcon="{{ apiAdela.getIcone(etatCode) }}">
        </mat-icon>
        <div>{{etatDossier}}</div>
      </div>
     
      <form [formGroup]="adelaPoudreForm">
        <div class="content">
            <div class="titlebox">Informations générales</div>
                <mat-form-field>
                    <mat-label>Référence</mat-label>
                    <input  matInput 
                            type="text" 
                            appAlphaNum
                            formControlName   = "reference" 
                            oninput           = "this.value = this.value.toUpperCase()" 
                            minlength         = "7"
                            maxlength         = "24"
                            required
                        >
                    <mat-hint>Alphanumérique entre 7 et 24 caractères</mat-hint>
                    <mat-error *ngIf="reference?.invalid && (reference?.dirty || reference?.touched)">{{ reference?.errors?.erreur }}</mat-error>
            </mat-form-field>
        
                <mat-form-field >
                    <mat-label>Prestation</mat-label>
                    <mat-select required="required"
                                formControlName     = "prestation"
                                (selectionChange)   = "onSetPrestation($any($event).value)">
                        <mat-option *ngFor="let prestation of prestations" 
                                        [value]     = "prestation.id" 
                                        >
                                {{ prestation.designation }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        
                <app-entite-list [fieldName]    = "'Cabinet à facturer'"
                                [idEntite]      = "idSelectedEntite"
                                [activated]     = "modeCreation"
                                [allEntities]   = "apiAuth.userConnect.droits_utilisateur.droit_module_admin_adela"
                                (entiteEmitter) = "onSetEntite($event)"
                                ></app-entite-list>
        
                <mat-form-field *ngIf="modeCreation" style="max-width:170px ;">
                    <mat-label>N° étiquette</mat-label>
                    <mat-select 
                        formControlName="etiquette" 
                        required
                        >
                            <mat-option 
                                *ngFor="let etiquette of etiquettes" 
                                [value]="etiquette.numero"
                                >
                                {{etiquette.numero}}
                            </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="!modeCreation" style="max-width:170px ;">
                    <mat-label>N° étiquette</mat-label>
                    <input matInput formControlName="etiquette" required>
                </mat-form-field>
        </div>

        <div class="content">
            <div class="titlebox">Informations du véhicule</div>
            <div class="titlebox">Informations du véhicule</div>
                
                <mat-form-field style="max-width: 140px;">
                    <mat-label>Immatriculation</mat-label>
                    <input 
                        matInput 
                        type            = "text" 
                        formControlName = "immat" 
                        (blur)            = "getInfosVehicule($event)"
                        appImmat
                        >
                </mat-form-field>
        
                <mat-form-field style="max-width: 200px;">
                    <mat-label>N° VIN</mat-label>
                    <input matInput 
                            type                = "text" 
                            formControlName     = "vin" 
                            oninput             = "this.value = this.value.toUpperCase()" 
                            length              = "17"
                            >
                    <mat-hint>Alphanum. 17 caractères</mat-hint>
                    <mat-error *ngIf="vin?.invalid && (vin?.dirty || vin?.touched)">{{ vin?.errors?.erreur }}</mat-error>
                </mat-form-field>
                
                <mat-form-field class="formDate">
                    <mat-label>Date MEC</mat-label>
                    <input matInput type="date" formControlName="mec">
                    <mat-error *ngIf="mec?.invalid && (mec?.dirty || mec?.touched)">{{ mec?.errors?.erreur }}</mat-error>
                </mat-form-field>
        
                <mat-form-field>
                        <mat-label>Marque</mat-label>
                        <input matInput type="text" formControlName="marqueVehicule" oninput="this.value = this.value.toUpperCase()">
                        <mat-error *ngIf="marqueVehicule?.invalid && (marqueVehicule?.dirty || marqueVehicule?.touched)">{{ marqueVehicule?.errors?.erreur }}</mat-error>
                </mat-form-field>
        
                <mat-form-field>
                        <mat-label>Modèle</mat-label>
                        <input matInput type="text" id="modele" formControlName="modele" oninput="this.value = this.value.toUpperCase()">
                        <mat-error *ngIf="modele?.invalid && (modele?.dirty || modele?.touched)">{{ modele?.errors?.erreur }}</mat-error>
                </mat-form-field>
        
                <mat-form-field>
                        <mat-label>Type Moteur</mat-label>
                        <input matInput type="text" id="typeMoteur" formControlName="typeMoteur" oninput="this.value = this.value.toUpperCase()">
                        <mat-error *ngIf="typeMoteur?.invalid && (typeMoteur?.dirty || typeMoteur?.touched)">{{ typeMoteur?.errors?.erreur }}</mat-error>
                </mat-form-field>
        
                <mat-form-field>
                        <mat-label>Energie</mat-label>
                        <mat-select name="energie" formControlName="energie">
                            <mat-option *ngFor="let energie of energies" [value]="energie.id">
                                    {{ energie.nom }}
                            </mat-option>
                        </mat-select>
                </mat-form-field>
        
                <mat-form-field>
                        <mat-label>Usage</mat-label>
                        <mat-select name="usage" formControlName="usage">
                            <mat-option *ngFor="let usage of usages" [value]="usage.id">
                                    {{ usage.nom }}
                            </mat-option>
                        </mat-select>
                </mat-form-field>
        
                <mat-form-field style="max-width: 110px;">
                        <mat-label>Kms ou Heures</mat-label>
                        <input matInput type="number" formControlName="kilometrage" min="0" step="1">
                </mat-form-field>
        
                <mat-form-field style="max-width: 110px;">
                        <mat-label>Capacité Carter</mat-label>
                        <input matInput type="number" formControlName="capaciteCarter" min="0.00" step="0.01">
                </mat-form-field>
                                                                                                
                <mat-form-field style="max-width: 110px;">          
                        <mat-label>Qté (L)</mat-label>                    
                        <input matInput type="number" id="quantite" formControlName="quantite"  min="0.00" step="0.01">
                        <mat-error *ngIf="quantite?.invalid && (quantite?.dirty || quantite?.touched)">{{ quantite?.errors?.erreur }}</mat-error>
                </mat-form-field>                                                       
            </div>

            <div class="content">
                <div class="titlebox">Nature de la demande</div>
                <mat-form-field style="max-width: 100%;min-height: 140px;" class="textarea">
                    <mat-label>Note</mat-label>
                    <textarea 
                        [disabled]          = "!modeCreation"
                        matInput type       = "text" 
                        formControlName     = "comment"
                        rows="5">
                    </textarea>
    
                </mat-form-field>
            </div>

            <br>
            <app-upload-files (uploadedFiles)  = "getFiles($event)"
                                [uploadParameter]="uploadParameter">
            </app-upload-files>

            <div *ngIf="formAdelaPoudre.tab_PiecesJointes">
                <div class = "pjTitle">Pièces jointes :</div>
                <div  *ngFor="let file of formAdelaPoudre.tab_PiecesJointes">
                    
                    <a class= "linked" (click)="dowloadFile(file.name)">
                        {{file.name}}
                        <br>
                    </a>
                                                                    
                </div>
            </div>
        </form>

        <div *ngIf="!idDossier || idDossier===0" class = "cadreInfoPrix">
            <p>La facturation de cette analyse sera effectuée lors de votre prochain relevé de factures mensuel</p>
            <p>Le tarif de l'analyse est de {{this.selectedPrestation!.tarif}} € HT</p>
        </div>

        <div class="div-container-right" >
                
                    <button     mat-flat-button style="text-align: center;" 
                                color       = "primary"
                                (click)="closeDossier()"
                            >
                        <mat-icon>cancel</mat-icon>
                        <span class="span-button">Fermer</span>
                    </button>  
                                                
                    <button    mat-flat-button style="text-align: center;" 
                            color       = "primary" 
                            [disabled]  = "waiting || adelaPoudreForm.invalid || idSelectedEntite < 1"  
                            (click)     = "validateAnalyse()"
                            *ngIf="validateButtonLabel !='' && !waiting">
                        <mat-icon>done</mat-icon>
                        <span  class="span-button">{{validateButtonLabel}}</span>
                    </button>
                    
                    <button mat-flat-button style   = "text-align: center;" 
                            color                   = "warn"
                            *ngIf="etatCode===apiAdela.CONST_ETAT_ADELA_1_Depose && !waiting"
                            (click)="refuserDemande()"
                            >
                            
                            <mat-icon>cancel</mat-icon>
                            <span  class="span-button">Refuser la demande</span>
                    </button>
                    <mat-spinner *ngIf="waiting" diameter="40"></mat-spinner>
        </div>  
         
</mat-card>
<app-resultat-analyse-edit *ngIf="AfficherResultat" [idDossier]="idDossier" [etatCode]="etatCode" (closeDossier)="closeDossier()"></app-resultat-analyse-edit>

<br>
<div class="footer">
      <i>Les informations collectées vous concernant sont destinées à l'ARD dans le cadre de sa gestion du service ADELA.
      Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée (art. 38 s.), vous disposez d'un droit d'accès, de modification, de rectification et de suppression
      des données vous concernant. Pour toute demande, adressez-vous par courrier à : ARD, 41-43, rue des Plantes, 75014 PARIS ou par courrier électronique à : ard@ffea.fr.</i>
</div>
