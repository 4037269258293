import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { EnregistrerResultatAnalyse } from 'src/app/interfaces/adela/enregistrer-resultat-analyse';
import { Etat } from 'src/app/interfaces/adela/etat';
import { NotesBsm } from 'src/app/interfaces/adela/notes-bsm';
import { ResultatAnalyse } from 'src/app/interfaces/adela/resultat-analyse';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { DialogPhotoResultatAnalyseAdelaComponent } from '../dialog-photo-resultat-analyse-adela/dialog-photo-resultat-analyse-adela.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PhrasierComponent } from '../../phrasier/espace-phrasier/phrasier.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

export interface NoteGlobale {
  id: number;
  libelle: string;
  chemin: string;
}

@Component({
    selector: 'app-resultat-analyse-adela',
    templateUrl: './resultat-analyse-adela.component.html',
    styleUrls: ['./resultat-analyse-adela.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, NgFor, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatSelectModule
              , MatOptionModule, MatGridListModule, MatButtonModule, MatIconModule, PhrasierComponent, MatProgressSpinnerModule]
})
export class ResultatAnalyseAdelaComponent implements OnInit {

  const_etat_depose                 = 'DEPOSE' as const;
  const_etat_valide                 = 'VALIDE' as const;
  const_etat_en_attente_echantillon = 'ATTENTE_ECHANTILLON' as const;
  const_etat_en_cours_analyse       = 'ANALYSE' as const;
  const_etat_en_cours_diagnostic    = 'DIAGNOSTIC' as const;
  const_etat_cloture                = 'CLOTURE' as const;
  const_etat_refuse                 = 'REFUSE' as const;

  const_categorie_spectrometrie       = 'SPECTROMETRIE' as const;
  const_categorie_physicochimique     = 'PHYSICOCHIMIQUE' as const;
  const_categorie_physicochimique_bsm = 'PHYSICOCHIMIQUE_BSM' as const;

  @Input() form_bsm       : boolean = false;
  @Input() idDossier      : number = 0;  // Identifiant du dossier transmis par le component parent : app-analyse-adela
  @Input() idEtatDossier  : number = 0;  // Identifiant état du dossier transmis par le component parent : app-analyse-adela
  @Input() habilitation   : string = ''; // Habilitation de l'entité transmis par le component parent : app-analyse-adela
  @Input() destinataire   : string = ''; // @ Email du destinataire
  @Input() particulier    : string = ''; // @ Email du particulier
  @Output() closeDossier = new EventEmitter<boolean>();
  etat : any = '';

  resultat_analyse                        : ResultatAnalyse = <ResultatAnalyse>{}; // Contient toutes les valeurs de résultats de l'analyse
  resultat_categorie_spectrometrie        : ResultatAnalyse = <ResultatAnalyse>{}; // Contient toutes les valeurs de résultats de l'analyse lié à la catégorie spectrométrie
  resultat_categorie_physicochimique      : ResultatAnalyse = <ResultatAnalyse>{}; // Contient toutes les valeurs de résultats de l'analyse lié à la catégorie physico-chimique
  resultat_categorie_physicochimique_bsm  : ResultatAnalyse = <ResultatAnalyse>{}; // Contient toutes les valeurs de résultats de l'analyse lié à la catégorie physico-chimique BSM
  
  etats$: Etat[] = [];
  
  notation$         : Array<number> = [1,2,3,4,5];
  notation_globale$ : Array<NoteGlobale> = [{id: 1, libelle: 'Rouge', chemin: 'assets/img/BSM_Rouge.png'}, {id: 2, libelle: 'Orange', chemin: 'assets/img/BSM_Orange.png'}, {id: 3, libelle: 'Vert', chemin: 'assets/img/BSM_Vert.png'}];
  
  notes_bsm: NotesBsm = <NotesBsm>{};

  form_notation_bsm: any;
  PhrasierFormGroup: any;

  cheminImage : string = '';
  imageToShow: Array<any> = [];
    
  showPhrasier    : boolean = false;
  waitingModified : boolean = false;
  waitingClosed   : boolean = false;
  waitingPreview  : boolean = false;
  waitingSending  : boolean = false;
  
  enregistrerResultatAnalyse : EnregistrerResultatAnalyse = <EnregistrerResultatAnalyse>{};

  constructor(  private apiAdela            : ApiAdelaService
              , private apiSnackBar         : PersoSnackbarService
              , private _fb                 : FormBuilder
              , public _sanitizer           : DomSanitizer
              , private toast               : PersoSnackbarService
              , public dialog               : MatDialog
              , private router              : Router) { }

  ngOnInit(): void {    
    this.form_notation_bsm = this._fb.group({
      polluant_combustion     : [0],
      polluant_injection      : [0],
      polluant_filtration     : [0],
      polluant_refroidissement: [0],
      polluant_haut_moteur    : [0],
      polluant_bas_moteur     : [0],
      note_globale            : [0]
    });

    this.PhrasierFormGroup = this._fb.group({
      interpretation : [{value : '', disabled: false}, [Validators.required]]
    });

    if(this.idDossier > 0) {
      this.getEtat();            
    } else {     
      this.apiSnackBar.showError('Une erreur est survenue lors du chargement des résultats d\'analyse.Identifiant dossier incorrect.');
    }
  }

  onClickPhrasier() {
    this.showPhrasier = !this.showPhrasier;
  }

  onClickZoomIn(photoBase64 : any) {
    const dialogRef = this.dialog.open(DialogPhotoResultatAnalyseAdelaComponent, {data : {photo: photoBase64}});
  }

  addPhrasier(newItem: string) {
    this.PhrasierFormGroup.controls['interpretation'].setValue(this.PhrasierFormGroup.controls.interpretation.value + newItem);
  }

  public getEtat(){
    this.apiAdela.getEtat()
      .subscribe(
        (data: Etat[]) => {
          this.etats$ = data;
          this.etat = this.etats$.find(etat => etat.id == this.idEtatDossier);
          
          // On récupère les résultats de l'analyse uniquement si le dossier est en status 'DIAGNOSTIC' ou 'CLOTURE'
          // Dans le cas où le dossier est en ANALYSE, un message s'affiche
          if(this.etat.code === this.const_etat_en_cours_diagnostic || this.etat.code === this.const_etat_cloture) {
            if(this.etat.code === this.const_etat_cloture) {
              this.PhrasierFormGroup.controls['interpretation'].disable();
              this.form_notation_bsm.controls['polluant_combustion'].disable();
              this.form_notation_bsm.controls['polluant_injection'].disable();
              this.form_notation_bsm.controls['polluant_filtration'].disable();
              this.form_notation_bsm.controls['polluant_refroidissement'].disable();
              this.form_notation_bsm.controls['polluant_haut_moteur'].disable();
              this.form_notation_bsm.controls['polluant_bas_moteur'].disable();
              this.form_notation_bsm.controls['note_globale'].disable();
            } else {
              this.PhrasierFormGroup.controls['interpretation'].enable();
              this.form_notation_bsm.controls['polluant_combustion'].enable();
              this.form_notation_bsm.controls['polluant_injection'].enable();
              this.form_notation_bsm.controls['polluant_filtration'].enable();
              this.form_notation_bsm.controls['polluant_refroidissement'].enable();
              this.form_notation_bsm.controls['polluant_haut_moteur'].enable();
              this.form_notation_bsm.controls['polluant_bas_moteur'].enable();
              this.form_notation_bsm.controls['note_globale'].enable();
            }
            this.getResultatAnalyse(this.idDossier);
          }
        },
        (err) => { console.error('/!\\ error getEtat: '+err);}
      );
  }

  public getPhotosResultatAnalyse(id: number, numEtiquette: string) {
    this.apiAdela.postPhotosResultatAnalyse(id, numEtiquette)
    .subscribe(
      (data : ResultatAnalyse) => {
        if(data.tabs_Photos != undefined) {          
          for(let i = 0; i < data.tabs_Photos.length; i++) {
            this.imageToShow.push(data.tabs_Photos[i]);
          }          
        }
      },
      (err) => { console.error('/!\\ error getPhotosResultatAnalyse: '+err);}
    );
  }  

  // Enregistrement du dossier
  public onEnregistrer() {
    this.waitingModified = true;    
    this.bindDataWithApi(false);

    this.apiAdela.enregistrerResultatAnalyse(this.enregistrerResultatAnalyse)
      .subscribe(
        (data) => {
          this.toast.showInfo("Enregistrement effectué");
          this.waitingModified = false;
        },
        (err) => { 
          console.error('/!\\ error enregistrerResultatAnalyse: '+err);
          this.waitingModified = false;
        }
      );
  }

  // Clôture du dossier
  public onCloturer() { 
    this.waitingClosed = true;
    this.bindDataWithApi(true);

    this.apiAdela.enregistrerResultatAnalyse(this.enregistrerResultatAnalyse)
    .subscribe(
      (data) => {
        this.toast.showInfo("Dossier clôturé");
        this.waitingClosed = false;
        this.closeDossier.next(true)
      },
      (err) => { 
        console.error(err);
        this.waitingClosed = false;
      }
    );
  }

  public onApercuRapport() { 
    this.waitingPreview = true;

    this.apiAdela.getTelechargerResultatAnalyse(this.idDossier)
    .subscribe(
      (data) => {
        saveAs(data, `CLOTURE_`+this.habilitation+`_`+this.idDossier.toString()+`.pdf`);
        this.toast.showInfo("Téléchargement effectué");
        this.waitingPreview = false;
      },
      (err) => {
        console.error('/!\\ error getTelechargerResultatAnalyse: '+err);
        this.waitingPreview = false;
      }
    );
  }

  public onEnvoyerRapport() { 
    this.waitingSending = true;

    this.apiAdela.getRenvoyerResultatAnalyseParMail(this.idDossier)
    .subscribe(
      (data) => {
        let emailParticulier : string = '';

        if(this.particulier != '') {
          emailParticulier = ' et ' + this.particulier;
        }

        this.toast.showInfo("Mail envoyé à "+this.destinataire+emailParticulier);
        this.waitingSending = false;
      },
      (err) => {
        console.error('/!\\ error getRenvoyerResultatAnalyseParMail: '+err);
        this.waitingSending = false;
      }
    );
  }

  public bindDataWithApi (appelPourCloture : boolean) {
    this.enregistrerResultatAnalyse.appel_pour_bsm = this.form_bsm;
    this.enregistrerResultatAnalyse.appel_pour_cloture = appelPourCloture;
    this.enregistrerResultatAnalyse.id_dossier = this.idDossier;
    this.enregistrerResultatAnalyse.interpretation = this.PhrasierFormGroup.controls.interpretation.value;
    
    if(this.form_bsm) {
      this.enregistrerResultatAnalyse.note_bsm = <NotesBsm>{};
      this.enregistrerResultatAnalyse.note_bsm.id = this.notes_bsm.id
      if(this.form_notation_bsm.value.note_globale != undefined) {
        this.enregistrerResultatAnalyse.note_bsm.note_globale = this.form_notation_bsm.controls.note_globale.value;
      }
      if(this.form_notation_bsm.value.polluant_bas_moteur != undefined) {
        this.enregistrerResultatAnalyse.note_bsm.quantite_bas_moteur = this.form_notation_bsm.controls.polluant_bas_moteur.value;
      }
      if(this.form_notation_bsm.value.polluant_refroidissement != undefined){
        this.enregistrerResultatAnalyse.note_bsm.quantite_circuit_refroidissement = this.form_notation_bsm.controls.polluant_refroidissement.value;
      }
      if(this.form_notation_bsm.value.polluant_combustion != undefined) {
        this.enregistrerResultatAnalyse.note_bsm.quantite_combustion = this.form_notation_bsm.controls.polluant_combustion.value;
      }
      if(this.form_notation_bsm.value.polluant_filtration != undefined) {
        this.enregistrerResultatAnalyse.note_bsm.quantite_filtre_air = this.form_notation_bsm.controls.polluant_filtration.value;
      }
      if(this.form_notation_bsm.value.polluant_haut_moteur != undefined) {
        this.enregistrerResultatAnalyse.note_bsm.quantite_haut_moteur = this.form_notation_bsm.controls.polluant_haut_moteur.value;
      }
      if(this.form_notation_bsm.value.polluant_injection != undefined) {
        this.enregistrerResultatAnalyse.note_bsm.quantite_injection = this.form_notation_bsm.controls.polluant_injection.value;
      }      
    }
  }

  public getNotesBSM(idResultat: number) {
    this.apiAdela.getNotesBSM(idResultat)
    .subscribe(
      (data: NotesBsm) => {
        let note_globale : NoteGlobale | undefined;
        
        this.notes_bsm = data;

        note_globale = this.notation_globale$.find(notation => notation.id = this.notes_bsm.note_globale)        
        
        if(note_globale != undefined) {
          this.cheminImage = note_globale.chemin;
          this.form_notation_bsm.patchValue({          
            note_globale: note_globale.id
          });  
        }

        this.form_notation_bsm.patchValue({
          polluant_combustion     : this.notes_bsm.quantite_combustion,
          polluant_injection      : this.notes_bsm.quantite_injection,
          polluant_filtration     : this.notes_bsm.quantite_filtre_air,
          polluant_refroidissement: this.notes_bsm.quantite_circuit_refroidissement,
          polluant_haut_moteur    : this.notes_bsm.quantite_haut_moteur,
          polluant_bas_moteur     : this.notes_bsm.quantite_bas_moteur
        });
      },
      (err) => { console.error('/!\\ error getNotesBSM: '+err);}
    );
  }

  // Fonction de chargement des résultats d'analyse
  public getResultatAnalyse(idDossier : number) {
    this.apiAdela.getResultatAnalyse(idDossier,true)
      .subscribe(
        (data : ResultatAnalyse) => {           
          this.resultat_analyse = data;   

          this.PhrasierFormGroup.patchValue({
            interpretation : data.interpretation
          });          

          this.getPhotosResultatAnalyse(this.resultat_analyse.id, this.resultat_analyse.etiquette);

          // Met dans le tableau resultat_categorie_spectrometrie toutes les valeurs de la categorie 'SPECTROMETRIE'
          this.resultat_analyse.tabst_ValeurResultat.forEach(valeurResultat => {
            if(valeurResultat.st_TypeValeurResultat.code_categorie === this.const_categorie_spectrometrie) {
              if(this.resultat_categorie_spectrometrie.tabst_ValeurResultat != undefined) {
                this.resultat_categorie_spectrometrie.tabst_ValeurResultat.push(valeurResultat);
              } else {
                this.resultat_categorie_spectrometrie.tabst_ValeurResultat = new Array;
                this.resultat_categorie_spectrometrie.tabst_ValeurResultat.push(valeurResultat);
              }              
            }

            // Met dans le tableau resultat_categorie_physicochimique toutes les valeurs de la categorie 'PHYSICOCHIMIQUE'
            if(valeurResultat.st_TypeValeurResultat.code_categorie === this.const_categorie_physicochimique) {
              if(this.resultat_categorie_physicochimique.tabst_ValeurResultat != undefined) {
                this.resultat_categorie_physicochimique.tabst_ValeurResultat.push(valeurResultat);
              } else {
                this.resultat_categorie_physicochimique.tabst_ValeurResultat = new Array;
                this.resultat_categorie_physicochimique.tabst_ValeurResultat.push(valeurResultat);
              }              
            }
            
            // Met dans le tableau resultat_categorie_physicochimique_bsm toutes les valeurs de la categorie 'PHYSICOCHIMIQUE_BSM'
            if(valeurResultat.st_TypeValeurResultat.code_categorie === this.const_categorie_physicochimique_bsm) {
              if(this.resultat_categorie_physicochimique_bsm.tabst_ValeurResultat != undefined) {
                this.resultat_categorie_physicochimique_bsm.tabst_ValeurResultat.push(valeurResultat);
              } else {
                this.resultat_categorie_physicochimique_bsm.tabst_ValeurResultat = new Array;
                this.resultat_categorie_physicochimique_bsm.tabst_ValeurResultat.push(valeurResultat);
              }              
            }            
          });
          
          if(this.form_bsm) {
            this.getNotesBSM(this.resultat_analyse.id);
          }
        },
        (err) => { 
          console.error('/!\\ error getResultatAnalyse: '+err);          
        }
      );
  }

  public autoGrowTextZone(event : any) {
    if (event.target.style.height != null && event.target.style.height != undefined) {
      event.target.style.height = "0px";
      event.target.style.height = (event.target.scrollHeight + 25)+"px";
    }
  }
  
}