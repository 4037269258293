<div  [formGroupName]="controlKey" class="container">
    
            <mat-form-field >
                <mat-label>SIRET</mat-label>
                <input      matInput 
                            type              = "text" 
                            formControlName   = "siret" 
                            placeholder       = "Numéro de SIRET"
                            maxlength         = "14" 
                            oninput           = "this.value = this.value.replace(/[^0-9]/g, '')"
                            required
                            >
                <mat-error *ngIf="sirenFormGroup.get('siret')?.hasError('required')">Le SIRET est obligatoire</mat-error>
                <mat-error *ngIf="sirenFormGroup.get('siret')?.hasError('erreur')">{{sirenFormGroup.get('siret')?.errors?.erreur}}</mat-error>
            </mat-form-field>

            <mat-form-field >
                <mat-label>SIREN</mat-label>
                <input  matInput 
                        type            = "text" 
                        formControlName = "siren" 
                        placeholder     = "Numéro de SIREN" 
                        readonly
                        required
                        >
                <mat-error *ngIf="sirenFormGroup.get('siren')?.hasError('required')">Le SIREN est obligatoire</mat-error>
            </mat-form-field>
            
            <mat-form-field >
            <mat-label>Raison Sociale</mat-label>
            <input    matInput 
                        required 
                        type            = "text" 
                        placeholder     = "Raison Sociale"
                        formControlName = "raisonSociale"
                        >
                <mat-error *ngIf="sirenFormGroup.get('raisonSociale')?.hasError('required')">La raison sociale est obligatoire</mat-error>
            </mat-form-field>
    
</div>