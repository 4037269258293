<div *ngIf="suivisSiv">
    <mat-card>
        <mat-card-title *ngIf="suivisSiv.length ===0">
            Pas de suivi SIV pour le dossier
        </mat-card-title>
        
        <mat-card-title *ngIf="suivisSiv.length >0">
            Suivi SIV du dossier {{dossier}}
        </mat-card-title>
    </mat-card>
    
    <div *ngFor="let suivi of suivisSiv">
        <mat-card>
            <mat-card-header>
                <div mat-card-avatar class="typeOpe">
                    <div class="type">{{getType(suivi.type)}}</div>
                </div>
                <div class="info div-container">
                    <div class="libelle">VGE :</div>
                    <div class="valeur">{{getVge(suivi.envoisiv)}}</div>
                </div>
                <div class="info div-container">
                    <div class="libelle">Statut : </div> 
                    <div class="valeur">{{getStatut(suivi.code_traitement)}}</div>
                </div>
                <div class="info div-container">
                    <div class="libelle">Date d'envoi : </div> 
                    <div class="valeur">{{suivi.date_recu | date:'dd/MM/yyyy HH:mm:ss'}}</div>
                </div>
                <div class="info div-container">
                    <div class="libelle">Date de retour : </div> 
                    <div class="valeur" *ngIf="suivi.date_reponse.toString().substring(0,1) !='0'">{{suivi.date_reponse|date:'dd/MM/yyyy HH:mm:ss'}}</div>
                </div>
            </mat-card-header>
            <mat-card-content *ngIf="suivi.erreurs.length!=0">
                <div *ngFor="let error of suivi.erreurs" style="color:#ba2805">
                    Code erreur : {{error.code}} - {{error.libelle}}
                </div>
            </mat-card-content>
        </mat-card>   
    </div>
</div>
<mat-card>
    <mat-card-actions align="end">
        <button mat-raised-button color="primary" (click)="setRetour()">Retour</button>
    </mat-card-actions>
</mat-card>
