<mat-card appearance="outlined" *ngIf="isDirigeant || userConnect.droits_utilisateur.est_admin || isDirigeantPrincipal">
      
      <mat-card-title>
            <div class="div-container-left">
                  <mat-icon color="primary">account_circle</mat-icon>
                  <span>Liste des utilisateurs</span>
            </div>
      </mat-card-title>
      <br>
      <mat-card-subtitle>
            <div class="div-container-left">
                  <mat-slide-toggle *ngIf="RelUtilisateurEntite.length > 0" 
                                    [(ngModel)]       = "filtreDesactive"
                                    (change)          ="setFilter.click()">Voir les utilisateurs désactivés</mat-slide-toggle>
                  
                  <mat-form-field>
                        <mat-label>Nom</mat-label>
                        <input 
                              matInput 
                              type              = "text" 
                              [(ngModel)]       = "filtreNom"
                              (keyup)           = "setFilter.click()">
                  </mat-form-field>
                  
                  <mat-form-field>
                        <mat-label>Prénom</mat-label>
                        <input matInput 
                              type        = "text" 
                              [(ngModel)] = "filtrePrenom"
                              (keyup)     = "setFilter.click()">
                  </mat-form-field>
                  
                  <mat-form-field *ngIf="!isAdela">
                        <mat-label>Agrément</mat-label>
                        <input matInput 
                              type        = "text" 
                              [(ngModel)] = "filtreAgrement"
                              (keyup)     = "setFilter.click()">
                  </mat-form-field>

                  <button style="display: none;" #setFilter (click)="onFilterChanged()"></button>
            </div>
      </mat-card-subtitle>
      <br>
      <mat-card-content  class=".mat-card-content">

            <table mat-table [dataSource]="dataSource">

                <!-- Agrement Column -->
                <ng-container matColumnDef="agrement">
                    <th mat-header-cell *matHeaderCellDef> Agrément VE </th>
                    <td mat-cell *matCellDef="let user"> {{user.utilisateur.agrement_ve}} </td>
                </ng-container>

                  <!-- Nom Column -->
                  <ng-container matColumnDef="nom">
                      <th mat-header-cell *matHeaderCellDef> Nom </th>
                      <td mat-cell *matCellDef="let user"> {{user.utilisateur.nom}} </td>
                  </ng-container>
      
                  <!-- Prénom Column -->
                  <ng-container matColumnDef="prenom">
                      <th mat-header-cell *matHeaderCellDef> Prénom </th>
                      <td mat-cell *matCellDef="let user"> {{user.utilisateur.prenom}} </td>
                  </ng-container>
      
                  <!-- Email Column -->
                  <ng-container matColumnDef="email">
                      <th mat-header-cell *matHeaderCellDef> Email </th>
                      <td mat-cell *matCellDef="let user"> {{user.utilisateur.email}} </td>
                  </ng-container>
      
                  <!-- Téléphone Column -->
                  <ng-container matColumnDef="telephone">
                      <th mat-header-cell *matHeaderCellDef> Téléphone </th>
                      <td mat-cell *matCellDef="let user"> {{user.utilisateur.telephone}} </td>
                  </ng-container>
      
                  <!-- Dirigeant Column -->
                  <ng-container matColumnDef="dirigeant">
                      <th mat-header-cell *matHeaderCellDef> Dirigeant </th>
                      <td mat-cell *matCellDef="let user">
                          <mat-icon *ngIf="user.est_dirigeant">done</mat-icon>
                      </td>
                  </ng-container>
      
                   <!-- Group Column -->
                   <ng-container matColumnDef="groupe">
                        <th mat-header-cell *matHeaderCellDef> Groupe </th>
                        <td mat-cell *matCellDef="let user">
                              {{user.groupName}}
                        </td>
                    </ng-container>

                  <!-- Accès Concentrateur Column -->
                  <ng-container matColumnDef="accesConcentrateur">
                      <th mat-header-cell *matHeaderCellDef> Accès Concentrateur </th>
                      <td mat-cell *matCellDef="let user"><mat-icon *ngIf="getAccesConcentrateur(user)">done</mat-icon></td>
                  </ng-container>
      
                  <!-- Détail Column -->
                  <ng-container matColumnDef="details">
                      <th mat-header-cell *matHeaderCellDef> Détails </th>
                      <td mat-cell *matCellDef="let user">
                          <button (click)="openProfilUtilisateur(user.utilisateur.id, $event)" mat-raised-button stopPropagation color="primary" type="submit">
                              <mat-icon>zoom_in</mat-icon>
                              <span class="mdc-button__label">Détails</span>
                          </button>
                      </td>
                  </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <mat-paginator [length]="dataSource.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons ></mat-paginator>

      </mat-card-content>
      
</mat-card>