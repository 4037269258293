<mat-card appearance="outlined">
    <mat-card-title>Statistiques CONCENTRATEUR  par taux de TVA</mat-card-title>
    <mat-card-content  class=".mat-card-content">
        <form [formGroup]="filterForm">
            <div class = "div-container-left">
                <div>
                    <mat-form-field>
                        <mat-label>Taux de TVA</mat-label>
                        <mat-select formControlName = "tauxTva">
                            <mat-option *ngFor="let taux of entitesTva" [value]="taux.libelle">
                                {{taux.libelle}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Saisie obligatoire</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field style="max-width: 100px;">
                        <mat-label>Année</mat-label>
                        <input  matInput
                                appAnnee 
                                [minAnnee]      = minAnnee
                                [maxAnnee]      = maxAnnee
                                formControlName = "annee"
                        >
                        <mat-error>Année invalide</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <button *ngIf="!waiting"
                            mat-raised-button 
                            color       = "primary" 
                            [disabled]  = "!filterForm.valid"
                            (click)     = "onSearch()"
                            >Rechercher
                    </button>
                    <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>
                </div>
            </div>
        </form>
        <div>
            <mat-grid-list cols="12"  rowHeight="40px" style="margin-top: 30px;">

                <mat-grid-tile colspan="4" rowspan="1">
                    <div class="tileColor">Taux de TVA :</div>
                </mat-grid-tile>
                <mat-grid-tile colspan="8" rowspan="1">
                    <div>{{tauxTVA}}</div>
                </mat-grid-tile>

                <mat-grid-tile colspan="4" rowspan="1">
                    <div class="tileColor">Année :</div>
                </mat-grid-tile>
                <mat-grid-tile colspan="8" rowspan="1">
                    <div>{{annee}}</div>
                </mat-grid-tile>

                <mat-grid-tile colspan="4" rowspan="1">
                    <div class="tileColor">Nombre d’experts :</div>
                </mat-grid-tile>
                <mat-grid-tile colspan="8" rowspan="1">
                    <div>{{nbOperations}}</div>
                </mat-grid-tile>

            </mat-grid-list>

            
        </div>
    </mat-card-content>
</mat-card>

