import { Component, OnInit, Output, Input, EventEmitter, ViewChild }    from '@angular/core';
import { Categorie }            from 'src/app/interfaces/phrasier/categorie';
import { Phrasier }             from 'src/app/interfaces/phrasier/phrasier';
import { SousCategorie }        from 'src/app/interfaces/phrasier/sous-categorie';
import { ApiPhrasierService }   from 'src/app/services/api-phrasier.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { FormBuilder, ReactiveFormsModule }          from '@angular/forms';
import { MatDialog }            from '@angular/material/dialog';
import { DialogConfirmationSuppressionComponent } from '../dialog-confirmation-suppression/dialog-confirmation-suppression.component';
import { Router }               from '@angular/router';
import { Clipboard }            from '@angular/cdk/clipboard';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-phrasier',
    templateUrl: './phrasier.component.html',
    styleUrls: ['./phrasier.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, MatIconModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, NgIf, MatButtonModule, MatProgressSpinnerModule, MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule]
})
export class PhrasierComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch       : boolean               = false;
  ExpandPannel          : boolean               = false;
  sous_categoriesFiltre : Array<SousCategorie>  = new Array<SousCategorie>();

  // Gestion de la pagination de la table
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;

  // Variables pour gérer l'envoi/réception avec l'apiPhrasier
  phrasier              : Phrasier              = <Phrasier>{};
  phrasiers             : Array<Phrasier>       = new Array<Phrasier>();
  categories            : Array<Categorie>      = new Array<Categorie>();
  sous_categories       : Array<SousCategorie>  = new Array<SousCategorie>();

  // Variable utilisé pour le formulaire de Filtres
  formGroupFiltre = this.fBuilder.group({
    categorie     : [<Categorie>{}],
    sous_categorie: [<SousCategorie>{}]
  });

  // Tableau des phrasiers
  displayedColumns: string[] = ['categorie', 'sous-categorie', 'titre', 'phrase', 'coller', 'modifier', 'supprimer'];
  dataSource      : MatTableDataSource<Phrasier>;

  // Variable utilisé pour copié le(s) phrasier(s) vers le diagnostic de l'analyse des résultats ADELA
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() showAllTable : boolean = true;
  
  constructor(  private apiPhrasier : ApiPhrasierService
              , private fBuilder    : FormBuilder
              , private toast       : PersoSnackbarService
              , public dialog       : MatDialog
              , private router      : Router
              , private clipboard   : Clipboard) { 
    //Initialisation de la source de données de la table
    this.dataSource   = new MatTableDataSource(); 
  }

  ngOnInit(): void {        
    this.getCategories();        
    this.getPhrasiers();
  }

  ngOnChanges(): void {
    this.getCategories();        
    this.getPhrasiers();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  onCopyContentPhrasier(phrase: string) {
    this.newItemEvent.emit(phrase);
    this.clipboard.copy(phrase);
    this.toast.showInfo("Phrasier copié")
  }

  onCreerPhrasier() { 
    this.router.navigate(['/app/phrasier/nouveau'])
  }

  onModifierPhrasier(phrasier: Phrasier) {
    this.router.navigate(['/app/phrasier/'+phrasier.id]);
  }

  onSupprimerPhrasier(phrasier: Phrasier) {    
    if(phrasier != undefined) {
      if(phrasier.id > 0) {        
        const dialogRef = this.dialog.open(DialogConfirmationSuppressionComponent);
        dialogRef.componentInstance.id = phrasier.id;
        dialogRef.afterClosed()
        .subscribe(
          (data: boolean) => { 
            if(data) {
              this.getPhrasiers();
            }
          },
          (err: any) => { 
            '/!\\ error dialogConfirmationSuppression: ' + console.log(err);
            this.waitingResearch = false;
          },
          () => { console.log('complete: dialogConfirmationSuppression') }
        );
      } else {
        this.toast.showError("Aucun phrasier sélectionné.");
      }
    } else {
      this.toast.showError("Une erreur est survenue, si l'erreur persiste veuillez contacter le support.");
    }
  }

  onSelectChangeCategorie(newSelect: any) {  
    this.sous_categoriesFiltre = this.sous_categories;

    if(newSelect != undefined) {
      if(newSelect.value != undefined) {
        if(newSelect.value.id > 0) {
          this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === newSelect.value.id);         
        } else {
          this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === 0);
        }      
      } else {
        this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === 0);  
      }      
    } else {
      this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === 0);
    }       
  }  

  // Chargements des phrasiers
  public getPhrasiers() : void {    
    let idCategorie     : number = 0;
    let idSousCategorie : number = 0;

    if(this.formGroupFiltre.controls.categorie.value != undefined) {
      idCategorie = this.formGroupFiltre.controls.categorie.value.id
    }

    if(this.formGroupFiltre.controls.sous_categorie.value) {
      idSousCategorie = this.formGroupFiltre.controls.sous_categorie.value.id
    }
    this.waitingResearch = true;
    this.apiPhrasier.getPhrasiers(idCategorie, idSousCategorie)
      .subscribe(
        (data: Phrasier[]) => { 
          this.phrasiers = data;
          this.dataSource.data = this.phrasiers;
          this.waitingResearch = false;
        },
        (err: any) => { 
          '/!\\ error getPhrasiers: ' + console.log(err);
          this.waitingResearch = false;
        },
        () => { console.log('complete: getPhrasiers') }
      );
  }

  // Création d'un phrasier
  public postPhrasier() : void { 
    this.apiPhrasier.postPhrasier(this.phrasier)
      .subscribe(
        (data: Phrasier) => { 
          this.phrasier = data;
        },
        (err: any) => { '/!\\ error postPhrasier: ' + console.log(err) },
        () => { console.log('complete: postPhrasier') }
      );
  }

  // Modification d'un phrasier
  public putPhrasier(id: number) : void { 
    this.apiPhrasier.putPhrasier(id, this.phrasier)
      .subscribe(
        (data: any) => { 
          this.toast.showInfo(data);
        },
        (err: any) => { '/!\\ error putPhrasier: ' + console.log(err) },
        () => { console.log('complete: putPhrasier') }
      );
  }


  // Chargement des catégories
  public getCategories() : void {
    this.apiPhrasier.getCategories()
      .subscribe(
        (data: Categorie[]) => { 
          this.categories = data;
          this.getSousCategories();
        },
        (err: any) => { '/!\\ error getCategories: ' + console.log(err) },
        () => { console.log('complete: getCategories') }
      );
  }

  // Chargement des sous-catégories
  public getSousCategories() : void {
    this.apiPhrasier.getSousCategories()
      .subscribe(
        (data: SousCategorie[]) => { 
          this.sous_categories = data;
          this.sous_categoriesFiltre = this.sous_categories;
          const id = this.formGroupFiltre.controls.categorie.value? this.formGroupFiltre.controls.categorie.value.id:0
          this.onSelectChangeCategorie(id);          
        },
        (err: any) => { '/!\\ error getSousCategories: ' + console.log(err) },
        () => { console.log('complete: getSousCategories') }
      );
  }

}
