<!--test-->
<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>                                
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner un cabinet et une immatriculation...
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field class="chip-list">
                <mat-label>Immatriculations</mat-label>
                <mat-chip-grid #chipListImmat formArrayName   = "immatriculations">
                    <mat-chip-row *ngFor        = "let immat of immatControls?.value" 
                                [removable]     = "removable" 
                                (removed)       = "removeImmat(immat)">
                      {{immat}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip-row>
                    <input placeholder                      = "Nouvelle immatriculation..."
                            type                            = "text"
                            oninput                         = "this.value = this.value.toUpperCase()"
                            appImmat
                           [matChipInputFor]                = "chipListImmat"
                           [matChipInputSeparatorKeyCodes]  = "separatorKeysCodes"
                           [matChipInputAddOnBlur]          = "addOnBlur"
                           (matChipInputTokenEnd)           = "addImmat($event)">
                  </mat-chip-grid>
            </mat-form-field>

             <mat-form-field class="chip-list">
                <mat-label>Numéros de dossier</mat-label>
                <mat-chip-grid #chipListDossier formArrayName   = "numerosDossier">
                    <mat-chip-row *ngFor        = "let dossier of numerosDossierControls?.value" 
                                [removable]     = "removable" 
                                (removed)       = "removeDossier(dossier)">
                                {{dossier}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip-row>
                    <input placeholder                      = "Nouveau dossier..."
                            type                            = "text"
                            maxlength                       = "25"
                            oninput                         = "this.value = this.value.toUpperCase()"
                           [matChipInputFor]                = "chipListDossier"
                           [matChipInputSeparatorKeyCodes]  = "separatorKeysCodes"
                           [matChipInputAddOnBlur]          = "addOnBlur"
                           (matChipInputTokenEnd)           = "addDossier($event)">
                  </mat-chip-grid>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Cabinet</mat-label>
                <mat-select name="entite" id="entite" formControlName="entite">
                    <mat-option *ngFor="let entite of filterEntitesOfType(true)" [value]="entite">{{ entite.raison_sociale }}</mat-option>
                </mat-select>
                <mat-error *ngIf="entite?.hasError('required')">La sélection d'un cabinet est obligatoire</mat-error>
            </mat-form-field> 
            
            <mat-form-field>
                <mat-label>Type opération</mat-label>
                <mat-select name="type_operation" id="type_operation" formControlName="type_operation" multiple>
                    <mat-option *ngFor="let type_operation of type_operations" [value]="type_operation">
                        {{ type_operation.libelle }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="type_operation?.hasError('required')">Vous devez sélectionner au moins un type d'opération pour votre recherche</mat-error>
            </mat-form-field>

            <mat-form-field class="formDate">
                <mat-label>Date de début</mat-label>
                <input matInput type="date" id="date_debut" formControlName="date_debut">
                <mat-error *ngIf="date_debut?.hasError('required')">La date de début est obligatoire</mat-error>
                <mat-error *ngIf="date_debut?.hasError('FormatDateDebut')">{{date_debut?.errors?.FormatDateDebut}}</mat-error>
            </mat-form-field>
    
            <mat-form-field class="formDate">
                <mat-label>Date de fin</mat-label>
                <input matInput type="date" id="date_fin" formControlName="date_fin">
                <mat-error *ngIf="date_fin?.hasError('required')">La date de fin est obligatoire</mat-error>
                <mat-error *ngIf="date_fin?.hasError('FormatDateFin')">{{date_fin?.errors?.FormatDateFin}}</mat-error>
            </mat-form-field>

            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">                        
                        <button mat-flat-button 
                                *ngIf="!waitingResearch"
                                color       = "primary" 
                                (click)     = "onSearchingSuivi()"
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid">
                            <mat-icon>search</mat-icon>Rechercher
                        </button>
                        <mat-spinner *ngIf="waitingResearch" diameter="24"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>                
    </form>
</mat-card>

<app-table-acquittement [ELEMENT_DATA]="suivis" [entiteFiltre]="this.FiltreFormGroup.controls.entite.value"></app-table-acquittement>