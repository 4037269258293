
<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">
        
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>                                
                <mat-icon color="primary">file</mat-icon>                
                <div *ngIf="dataSource">
                    <span style="width: 220px;" *ngIf="dataSource != undefined"><span class="span-primary">{{ dataSource.data.length }}</span>ACQUITTEMENT<span *ngIf="dataSource.data.length > 1">S</span></span>
                    <span style="width: 220px;" *ngIf="dataSource == undefined"><span class="span-primary">0</span>ACQUITTEMENT</span>
                </div>                                                        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

            <mat-card-subtitle>Mes Acquittements</mat-card-subtitle>
            
            <div class="div-container-between" style="padding: 8px;">
                <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                    <button mat-flat-button 
                            (click)     = "postRemettreADisposition()" 
                            color       = "accent" 
                            [disabled]  = "selection.selected.length <= 0"
                            *ngIf       = "!waitingAcquittement"
                            >

                        <mat-icon>autorenew</mat-icon>
                        <span class="hide">Remettre à disposition les acquittements</span>
                    </button>
                    <mat-spinner diameter="24" *ngIf="waitingAcquittement"></mat-spinner>     
                </div>
            </div>            

            <div class="container">
                <table mat-table matTableResponsive [dataSource]="dataSource" class="table-primary">
                    
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne" style="padding-left: 24px;">
                        <mat-checkbox type="checkbox" (change)="$event ? masterToggle() : null" class="checkbox"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()"></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" class="premiere-colonne" style="padding-left: 24px;">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)"
                                        class="checkbox"></mat-checkbox>
                        </td>
                    </ng-container>
                    
                    <!-- Position Column -->
                    <ng-container matColumnDef="position" >
                        <th mat-header-cell *matHeaderCellDef> No. </th>
                        <td mat-cell *matCellDef="let suivi"> {{suivi.position}} </td>
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> Date </th>
                        <td mat-cell *matCellDef="let suivi"> 
                            <div *ngIf="suivi.date_recu_anea >= '19000101'">{{suivi.date_recu_anea | date: 'dd/MM/yyyy'}}</div>
                            <div *ngIf="suivi.date_recu_anea < '19000101'"> __/__/____</div>
                        </td>
                    </ng-container>

                    <!-- Immatriculation Column --> 
                    <ng-container matColumnDef="immatriculation">
                        <th mat-header-cell *matHeaderCellDef> Immatriculation </th>
                        <td mat-cell *matCellDef="let suivi">{{ suivi.immatriculation }}</td>
                    </ng-container>

                    <!-- Dossier Column --> 
                    <ng-container matColumnDef="dossier">
                        <th mat-header-cell *matHeaderCellDef> Dossier </th>
                        <td mat-cell *matCellDef="let suivi">{{ suivi.numero_dossier }}</td>
                    </ng-container>

                    <!-- Type Opération Column--> 
                    <ng-container matColumnDef="type_op">
                        <th mat-header-cell *matHeaderCellDef> Type Opération </th>
                        <td mat-cell *matCellDef="let suivi">{{ getLibelleOperation(suivi.type_op) }}</td>
                    </ng-container>

                     <!-- Agrément Column -->
                     <ng-container matColumnDef="agrement">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Agrément </th>
                        <td mat-cell *matCellDef="let suivi">{{ suivi.agrement_ve }}</td>
                    </ng-container>

                     <!-- Prénom Column -->
                     <ng-container matColumnDef="prenom">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Prénom </th>
                        <td mat-cell *matCellDef="let suivi">{{ suivi.prenomExpert }}</td>
                    </ng-container>

                     <!-- Nom Column -->
                     <ng-container matColumnDef="nom">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Nom </th>
                        <td mat-cell *matCellDef="let suivi">{{ suivi.nomExpert }}</td>
                    </ng-container>

                     <!-- Resultat Column -->
                     <ng-container matColumnDef="message">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne"> Résultat </th>
                        <td mat-cell *matCellDef="let suivi">{{ suivi.message }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-card>

