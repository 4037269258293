import { Filtre } from "src/app/interfaces/acquittement/filtre"

export interface Operation{
    id          : number,
    eml_id?     : number
    code        : string,
    note        : string,
    actif       : boolean,
    nom         : string,
    contenu?    : Contenu
}

export interface Contenu{
    id          : number,
    contenu     : string,
    sujet       : string,
    note        : string,
    operations  : Operation[]
}

export interface FiltreContenu{
    sujet       : string,
    contenu     : string,
    idOperation : number
}

export const FiltreContenuInit : FiltreContenu= {
    'contenu':''
    ,'idOperation':-1
    ,'sujet':''
}

export const ContenuInit:Contenu = {
    'id'            : 0,
    'contenu'       : '',
    'operations'    : [],
    'sujet'         : '',
    'note'          : ''
}