import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { EntiteTva } from 'src/app/interfaces/profil-user/entite-tva';
import { TypeEntite } from 'src/app/interfaces/profil-user/type-entite';
import { UtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { EntiteCreationComponent } from '../../../profil-user/entite-creation/entite-creation.component';
import { FiltreListeCabinetsComponent } from '../filtre-liste-cabinets/filtre-liste-cabinets.component';
import { TableListeCabinetsComponent } from '../table-liste-cabinets/table-liste-cabinets.component';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
    selector: 'app-fiche-liste-cabinets',
    templateUrl: './fiche-liste-cabinets.component.html',
    styleUrls: ['./fiche-liste-cabinets.component.scss'],
    standalone: true,
    imports: [NgIf, EntiteCreationComponent, FiltreListeCabinetsComponent, MatCardModule, MatExpansionModule, MatIconModule, TableListeCabinetsComponent]
})
export class FicheListeCabinetsComponent implements OnInit {
  @Input() entite: any;
  @Output() closeEvent = new EventEmitter<void>();

  
  ds_Cabinets         : Array<Entite> = Array<Entite>();
  typesEntite : TypeEntite[]      = []
  edition     : boolean = false
  waiting$     = new BehaviorSubject<boolean>(false)
  tvas        : EntiteTva[] = []
  filtreInit  : any
  currentElement    : string = ''

  constructor(private apiEntite       : ApiEntiteService
            , private toast           : PersoSnackbarService
            , private apiAuth         : ApiAuthentificationService
            , private route           : Router
            , private activatedRoute  : ActivatedRoute
          ) { }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(url => {
      
      const elements    = this.route.url.split('/');
      this.currentElement = elements[elements.length - 1];
      
      switch (this.currentElement) {
        
        case 'cabinets':
          this.edition           = false
          break;
        
        case 'edit':
          this.edition         = true
          break;

        default:
          break;
      }
    });

    forkJoin({
      typesEntite  : this.apiEntite.getAllTypeEntite(this.apiAuth.GetEnvironmentCode())
      , tvas        : this.apiEntite.getTVA()
    })
    .subscribe(
      ({typesEntite,tvas})=> {
        this.typesEntite  = typesEntite
        this.tvas         = tvas
      }
    )
  }

  // Méthode pour fermer la fiche
  closeFiche() {
    this.closeEvent.emit();
  }

  public addItem(newItem: Array<Entite>) : void {
    this.ds_Cabinets = newItem;
  }

  public editNewEntite(){
    //on va générer un mot de passe
      this.edition = true
      this.route.navigate(['app/administration-extranet/cabinets/edit']);
  }

  setNewEntite(utilisateurEntite:UtilisateurEntite|null){
    if (utilisateurEntite) {
      this.apiEntite.postUtilisateurEntite(utilisateurEntite).subscribe(
        ()=>{
          this.toast.showInfo('Le cabinet a bien été créé')
          this.edition = false
          this.route.navigate(['app/administration-extranet/cabinets']);
        }
        ,(error) => {
          this.toast.showError(error.error)
          this.waiting$.next(false)
        }
        
      )
    }else{
      //the users canceled
      this.edition = false
      this.route.navigate(['app/administration-extranet/cabinets']);
    }
    
  }

  editOneEntite(idEntite:number){
    
    this.route.navigate(['/app/entite', idEntite])
  }
}
