<mat-card appearance="outlined" class="mat-card-no-shadow" style="padding: 0px;">
    <form [formGroup]="FiltreFormGroup">
        <mat-expansion-panel id="expandFilter" [(expanded)]="expandPannel" #expandFilter>                                
            <mat-expansion-panel-header style="background-color: #fff">
                <mat-panel-title class="label-accent">
                    <mat-icon>search</mat-icon>Veuillez sélectionner les options de votre recherche...
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Habilitation</mat-label>
                <input matInput type="text" placeholder="Habilitation" name="habilitation" id="habilitation" formControlName="habilitation" oninput="this.value = this.value.toUpperCase()">
                <mat-error *ngIf="habilitation?.invalid && (habilitation?.dirty || habilitation?.touched)">{{ habilitation?.errors?.erreur }}</mat-error>
            </mat-form-field> 

            <mat-form-field>
                <mat-label>Numéro Contact</mat-label>
                <input matInput type="text" placeholder="Numéro Contact" name="numeroContact" id="numeroContact" formControlName="numeroContact">
                <mat-error *ngIf="numeroContact?.invalid && (numeroContact?.dirty || numeroContact?.touched)">{{ numeroContact?.errors?.erreur }}</mat-error>
            </mat-form-field> 
           
            <mat-form-field>
                <mat-label>Raison sociale</mat-label>
                <input matInput type="text" placeholder="Raison sociale" name="raison_sociale" id="raison_sociale" formControlName="raison_sociale">
                <mat-error *ngIf="raison_sociale?.invalid && (raison_sociale?.dirty || raison_sociale?.touched)">{{ raison_sociale?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>SIREN</mat-label>
                <input matInput type="text" placeholder="SIREN" name="siren" id="siren" formControlName="siren">
                <mat-error *ngIf="siren?.invalid && (siren?.dirty || siren?.touched)">{{ siren?.errors?.erreur }}</mat-error>
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Ville</mat-label>
                <input matInput type="text" placeholder="Ville" name="ville" id="ville" formControlName="ville">
                <mat-error *ngIf="ville?.invalid && (ville?.dirty || ville?.touched)">{{ ville?.errors?.erreur }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Type d'entité</mat-label>
                <mat-select 
                    formControlName="typeEntite"
                    >
                    <mat-option value="">Tous</mat-option>
                    <mat-option 
                        *ngFor  = "let type of typesEntite" 
                        [value] = "type.code" 
                    >{{ type.libelle }}</mat-option>
                </mat-select>
         </mat-form-field>

            <mat-action-row>
                <div class="div-container-between" style="padding: 8px;">
                    <div class="div-container-center" style="width:100%; display: flex; justify-content: center;">
                        <button mat-flat-button 
                                *ngIf       = "!waitingResearch"
                                submit 
                                color       = "primary" 
                                [disabled]  = "waitingResearch || !FiltreFormGroup.valid" 
                                (click)     = "onSearchingCabinets()">
                            <mat-icon>search</mat-icon>Rechercher                                    
                        </button>
                        <mat-spinner diameter="24" *ngIf="waitingResearch"></mat-spinner>
                    </div>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </form>
</mat-card>