<mat-accordion>
    <mat-expansion-panel [(expanded)]="expandPannel" style="background-color: #fff">
        <mat-expansion-panel-header style="background-color: #fff">
            <mat-panel-title>
                <mat-icon color="primary">file</mat-icon>
                <span style="width: 220px;" *ngIf="dataSource != undefined"><span class="span-primary">{{ dataSource.length }}</span>FACTURE<span *ngIf="dataSource.length > 1">S</span></span>        
                <span style="width: 220px;" *ngIf="dataSource == undefined"><span class="span-primary">0</span>FACTURE</span>        
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
            <mat-card-subtitle>Mes Factures</mat-card-subtitle>

            <div class="container">
                <table mat-table matTableResponsiveLarge matTableExporter [dataSource]="dataSourceMatTable" #exporter="matTableExporter" [hiddenColumns]="[11]" class="table-primary">

                    <!-- Service Column -->
                    <ng-container matColumnDef="service">
                        <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 16px;" class="premiere-colonne colonne-invisible"> Service </th>
                        <td mat-cell *matCellDef="let facture" style="text-align: left;" class="colonne-invisible">
                            <mat-icon [ngStyle]="{'color': getColorFacture(facture.type_document) }" svgIcon="{{getIconeService(facture.Service.code) }}">
                                <span class="cdk-visually-hidden">{{ getIconeName(facture.Service.code) }}</span>
                            </mat-icon>
                        </td>
                    </ng-container>

                    <!-- Type Column -->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef class="colonne-invisible"> Type </th>
                        <td mat-cell *matCellDef="let facture" [ngStyle]="{'color': getColorFacture(facture.type_document) }" class="colonne-invisible">
                            {{ facture.type_document }}
                        </td>
                    </ng-container>

                    <!-- Option Column
                    <ng-container matColumnDef="option">
                        <th mat-header-cell *matHeaderCellDef class="colonne-invisible" style="padding-right: 16px; padding-left: 16px;"> Option </th>
                        <td mat-cell *matCellDef="let facture" class="colonne-invisible" style="padding-right: 16px; padding-left: 16px;"  [ngStyle]="{ 'color': getOptionColor(facture.option) }">{{ facture.option }}</td>
                    </ng-container>
                    -->

                    <!-- N° Commande Column-->
                    <ng-container matColumnDef="commande">
                        <th mat-header-cell *matHeaderCellDef> N° Référence </th>
                        <td mat-cell *matCellDef="let facture">{{ facture.reference }}</td>
                    </ng-container>

                    <!-- Reference Column -->
                    <ng-container matColumnDef="reference">
                        <th mat-header-cell *matHeaderCellDef > N° Facture/Avoir </th>
                        <td mat-cell *matCellDef="let facture">
                            <button mat-flat-button title="Visualiser la facture" (click)="openFacture(facture.id)"
                                class="custom-button" style="text-decoration: underline;">{{facture.numero}}</button>
                        </td>
                    </ng-container>

                    <!-- Cabinet Column -->
                    <ng-container matColumnDef="cabinet">
                        <th mat-header-cell *matHeaderCellDef> Cabinet </th>
                        <td mat-cell *matCellDef="let facture" class="cabinet">{{ facture.raisonSociale }}</td>
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef style="width: 92px;"> Date </th>
                        <td mat-cell *matCellDef="let facture">
                            <div *ngIf="facture.dateCreation >= '19000101'">{{facture.dateCreation | date: 'dd/MM/yyyy'}}</div>
                            <div *ngIf="facture.dateCreation < '19000101'">__/__/____</div>
                        </td>
                    </ng-container>

                    <!-- Mode Paiement Column
                    <ng-container matColumnDef="mode_paiement">
                        <th mat-header-cell *matHeaderCellDef class="mode-paiement" style="padding-right: 8px; padding-left: 8px;"> Mode Paiement </th>
                        <td mat-cell *matCellDef="let facture" class="mode-paiement">
                            {{ facture.ModePaiement.libelle }}
                        </td>
                    </ng-container>
                    -->

                    <!-- Etat Column -->
                    <ng-container matColumnDef="etat">
                        <th mat-header-cell *matHeaderCellDef class="etat-padding"> État </th>
                        <td mat-cell *matCellDef="let facture" class="etat-padding">
                            <button mat-stroked-button class="button-etat button-width" color="primary"
                            [ngStyle]="{ color: getBackgroundColorByStatus(facture.Etat.code) }"
                            (click)="dialogHistoriqueFacture(facture.id)">{{ facture.Etat.libelle }}</button>
                        </td>
                    </ng-container>

                    <!-- Total HT Column -->
                    <ng-container matColumnDef="total_ht">
                        <th mat-header-cell *matHeaderCellDef class="total" style="padding-right: 8px; padding-left: 8px;"> Total HT </th>
                        <td mat-cell *matCellDef="let facture" class="total">
                            {{ facture.totalHT | number:'1.2-2':'fr' }}€
                        </td>
                    </ng-container>

                    <!-- Total TTC Column -->
                    <ng-container matColumnDef="total_ttc">
                        <th mat-header-cell *matHeaderCellDef class="total" style="padding-right: 8px; padding-left: 8px;"> Total TTC </th>
                        <td mat-cell *matCellDef="let facture" class="total">
                            {{ facture.totalTTC | number:'1.2-2':'fr' }}€
                        </td>
                    </ng-container>

                    <!-- Reste du -->
                    <ng-container matColumnDef="resteDu">
                        <th mat-header-cell *matHeaderCellDef class="total" style="padding-right: 8px; padding-left: 8px;"> Reste dû </th>
                        <td mat-cell *matCellDef="let facture" class="total">
                            <div *ngIf="facture.type_document == apiFacture.CONST_TYPE_FACTURE">{{ facture.resteDu | number:'1.2-2':'fr' }}€</div>
                        </td>
                    </ng-container>
                    
                    <!-- Rapport ADC/ADELA + facture -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne"></th>
                        <td mat-cell *matCellDef="let facture">
                            <div class="div-button-left">
                                <button 
                                    mat-icon-button 
                                    *ngIf       = "facture.id>0"
                                    color       = "primary"
                                    title       = "Télécharger la facture"
                                    (click)     = "onDownloadFacture(facture)">
                                    <mat-icon>download</mat-icon>
                                </button>
                                <button *ngIf = "facture.idDossierADELA > 0 || facture.idDossierADC >0 "
                                    mat-icon-button
                                    color="primary"
                                    title  = "Télécharger le rapport"
                                    (click)="onDownloadRapport(facture.habilitation, facture.idDossierADELA, facture.idDossierADC)">
                                    <mat-icon color="primary">{{ getIconeDownload(facture.Service.code) }}</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="div-container-between" style="align-items: center;background-color:white">
                    <app-export-table [fileExportName]="'FACTURE'" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>

                    <mat-paginator [pageSizeOptions]="[10,25,50]"
                        showFirstLastButtons 
                        aria-label="Nombre de lignes visibles...">
                    </mat-paginator>
                </div>
                 
                 
            </div>
    </mat-expansion-panel>
</mat-accordion>