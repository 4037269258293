import { LayoutModule } from '@angular/cdk/layout';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { GeoApiGouvAddressResponse } from '@placeme/ngx-geo-api-gouv-address';
import { Feature } from 'geojson';
import { BehaviorSubject } from 'rxjs';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { GouvService } from 'src/app/services/gouv.service';
@Component({
    selector: 'app-adresse',
    templateUrl: './adresse.component.html',
    styleUrls: ['./adresse.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective
        }
    ],
    standalone: true,
    imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule
              , NgFor, MatOptionModule, NgIf, AsyncPipe, MatGridListModule,LayoutModule]
})


export class AdresseComponent implements OnInit {

  @Input() controlKey = ''
  @Input()  adresse         : Adresse= {...AdresseInit}
  @Input()  disabledFields  : boolean = false
  @Input()  label           : string = 'Adresse'
  @Input()  forceResize     : boolean = false
  
  adresseFormGroup  : FormGroup = this._fb.group({
      adresseLigne1    : ['',Validators.required]
    , adresseLigne2   : ['']
    , adresseLigne3   : ['']
    , adresseCP       : ['',Validators.required]
    , adresseVille    : ['',Validators.required]
  })
  adresses!         : GeoApiGouvAddressResponse['features']
  adresses$         : BehaviorSubject<GeoApiGouvAddressResponse['features']> = new BehaviorSubject(this.adresses)
  currentAdresse    : string = ''
  breakpointSmall   : boolean = true

  get adresseLigne1()     { return this.adresseFormGroup.get('adresseLigne1'); }
  get adresseLigne2()     { return this.adresseFormGroup.get('adresseLigne2'); }
  get adresseLigne3()     { return this.adresseFormGroup.get('adresseLigne3'); }
  get adresseCP()         { return this.adresseFormGroup.get('adresseCP'); }
  get adresseVille()      { return this.adresseFormGroup.get('adresseVille'); }

  constructor(
              private _fb         : FormBuilder,
              private apiGouv     : GouvService,
              private parentForm  : FormGroupDirective
              ) {}

  ngOnInit(): void {
    this.parentForm.form.addControl(this.controlKey,this.adresseFormGroup)
    //For responsiveness:
    if (this.forceResize) {
      this.breakpointSmall = true      
    }else{
      this.breakpointSmall = (parent.innerWidth <= 600) ? true : false;
    }
  }

  onResize(event:any) {
    //For responsiveness:
    this.breakpointSmall = (event.target.innerWidth <= 600) ? true : false;
  }

  ngOnChanges(){
    
    if (this.disabledFields===true) {
      this.adresseLigne1?.disable()
      this.adresseLigne2?.disable()
      this.adresseLigne3?.disable()
      this.adresseCP?.disable()
      this.adresseVille?.disable()

    }else{
      this.adresseLigne1?.enable()
      this.adresseLigne2?.enable()
      this.adresseLigne3?.enable()
      this.adresseCP?.enable()
      this.adresseVille?.enable()
    }

    this.adresseLigne1?.setValue(this.adresse.ligne1)
    this.adresseLigne2?.setValue(this.adresse.ligne2)
    this.adresseLigne3?.setValue(this.adresse.ligne3)
    this.adresseCP?.setValue(this.adresse.cp)
    this.adresseVille?.setValue(this.adresse.ville)

  }

  getAdresse(e:any){
    const adresse:string = e.target.value
    
    if (adresse.length < 4) {
      return;
    }  
    
    if (adresse==this.currentAdresse) {
      return
    }

    this.apiGouv.getAdresse(adresse).subscribe(
      (geoApiGouvAdresseReponse:GeoApiGouvAddressResponse) => {
        
        this.adresses = geoApiGouvAdresseReponse.features;
        this.adresses$.next(this.adresses)
      })
      this.currentAdresse = adresse
  }
  
  optionSelectionChange(
    adresse: Feature
  ) {
        this.adresseCP!.setValue(
          adresse.properties!['postcode']
        );
        this.adresseVille!.setValue(
          adresse.properties!['city']
        );
        this.adresseLigne1!.setValue(
          adresse.properties!['name']
        );
  }
  
ngOnDestroy(){
  this.adresses$.unsubscribe()
  this.parentForm.form.removeControl(this.controlKey)
}
}
