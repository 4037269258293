<div class="content" style="max-height: 100px; width: 300px;">
    <div class="titlebox">{{title}}</div>
    <div *ngIf="adresse.nom != '' || adresse.nom == undefined" class="nom">{{adresse.nom}}</div>
    <div class="adresse">{{adresse.ligne1}}</div>
    <div *ngIf="adresse.ligne2 != ''"class="adresse">{{adresse.ligne2}}</div>
    <div *ngIf="adresse.ligne3 != ''"class="adresse">{{adresse.ligne3}}</div>
    <div class="div-container-right adresse">
        <div>{{adresse.cp}}</div>
        <div>{{adresse.ville}}</div>
    </div>
</div>