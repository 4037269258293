<mat-card appearance="outlined">
    <form [formGroup]="formGroupPhrasier">

        <h4>{{ titre_page }}</h4>

        <mat-form-field>
            <mat-label>Catégorie</mat-label>
            <mat-select 
                  required
                  formControlName   = "categorie" 
                  (selectionChange) = "onSelectChangeCategorie($event)" 
                  >
                  <mat-option *ngFor="let categorie of categories" [value]="categorie.id">
                        {{ categorie.libelle }}
                  </mat-option>
            </mat-select>
            <mat-error *ngIf="categorie?.errors?.invalid && (categorie?.dirty || categorie?.touched)">{{ categorie?.errors?.erreur }}</mat-error>
      </mat-form-field>

        <mat-form-field>
              <mat-label>Nouvelle Catégorie</mat-label>
              <input matInput type="text" id="new_categorie" formControlName="new_categorie" oninput="this.value = this.value.toUpperCase()">
              <mat-error *ngIf="new_categorie?.invalid && (new_categorie?.dirty || new_categorie?.touched)">{{ new_categorie?.errors?.erreur }}</mat-error>
      </mat-form-field>

        <mat-form-field>
            <mat-label>Sous-catégorie</mat-label>
            <mat-select name="sous_categorie" id="sous_categorie" formControlName="sous_categorie" (selectionChange)="onSelectChangeSousCategorie($event)">
                    <mat-option *ngFor="let sous_categorie of sous_categoriesFiltre" [value]="sous_categorie.id">
                        {{ sous_categorie.libelle }}
                  </mat-option>
            </mat-select>
            <mat-error *ngIf="sous_categorie?.errors?.required && sous_categorie?.errors?.touched">{{ sous_categorie?.errors?.erreur }}</mat-error>
      </mat-form-field>

        <mat-form-field>
            <mat-label>Nouvelle Sous-catégorie</mat-label>
            <input 
                  matInput 
                  type               = "text" 
                  formControlName    = "new_sous_categorie" 
                  oninput            = "this.value = this.value.toUpperCase()"
                  >
            <mat-error *ngIf="new_sous_categorie?.invalid && (new_sous_categorie?.dirty || new_sous_categorie?.touched)">{{ new_sous_categorie?.errors?.erreur }}</mat-error>
      </mat-form-field>

        <mat-form-field>
              <mat-label>Titre</mat-label>
              <input matInput type="text" id="titre" formControlName="titre" required>
              <mat-error *ngIf="titre?.invalid && (titre?.dirty || titre?.touched)">{{ titre?.errors?.erreur }}</mat-error>
        </mat-form-field>

        <mat-form-field class="textarea">
            <mat-label>Phrase</mat-label>
            <textarea matInput id="phrase" formControlName="phrase"></textarea>
            <mat-error *ngIf="phrase?.invalid && (phrase?.dirty || phrase?.touched)">{{ phrase?.errors?.erreur }}</mat-error>
      </mat-form-field>

        <div class="div-button-right" style="margin-top: 16px;">
            <button mat-flat-button type="button" color="basic" (click)="onClickAnnuler()"><mat-icon>west</mat-icon>Annuler</button>
            <button mat-flat-button color="primary" (click)="onClickValider()" [disabled]="formGroupPhrasier.pristine || !(formGroupPhrasier.valid && formGroupPhrasier.dirty)">Valider<mat-icon>east</mat-icon></button>
        </div>
  </form>
</mat-card>