import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DatePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule, _MatCheckboxRequiredValidatorModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TelephoneDirective } from 'src/app/directive/telephone.directive';
import { Adresse, AdresseInit } from 'src/app/interfaces/adresse/adresse';
import { Article } from 'src/app/interfaces/commande/article';
import { Commande } from 'src/app/interfaces/commande/commande';
import { LigneCommande } from 'src/app/interfaces/commande/ligne-commande';
import { Parametre } from 'src/app/interfaces/parametre';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiCommandeService } from 'src/app/services/api-commande.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ApiUtilitaireService } from 'src/app/services/tools/api-utilitaire.service';
import { MailValidator } from 'src/app/validators/mail.validator';
import { TelephoneValidator } from 'src/app/validators/telephone.validator';
import { AdresseComponent } from '../../templates/adresse/adresse.component';
import { CarteAdresseComponent } from '../../templates/cards/carte-adresse/carte-adresse.component';
import { CarteEntiteComponent } from '../../templates/cards/carte-entite/carte-entite.component';
import { DialogEntiteAdresseComponent } from '../../templates/entite-adresse/dialog-entite-adresse/dialog-entite-adresse.component';
import { EntiteAdresse } from '../../templates/entite-adresse/entite-adresse';
import { EntiteAdresseService } from '../../templates/entite-adresse/entite-adresse.service';
import { FooterComponent } from '../../templates/footer/footer.component';
import { DialogOrderValidationComponent } from '../dialog/dialog-order-validation/dialog-order-validation.component';

@Component({
    selector: 'app-kits',
    templateUrl: './kits.component.html',
    styleUrls: ['./kits.component.scss'],
    providers: [DatePipe],
    standalone: true,
    imports: [NgIf, MatCardModule, MatIconModule, MatStepperModule, NgClass, MatButtonModule, ReactiveFormsModule, MatFormFieldModule
            , MatInputModule, MatSelectModule, MatOptionModule, NgFor, TelephoneDirective, AdresseComponent, MatRadioModule
            , MatTableModule, MatDividerModule, NgStyle, MatCheckboxModule, _MatCheckboxRequiredValidatorModule, FooterComponent
            , DatePipe,MatTooltipModule, MatGridListModule,CarteAdresseComponent,CarteEntiteComponent]
})
export class KitsComponent implements OnInit {

  // Constantes utile pour la gestion de la TVA
  CONST_TVA_PAS         = 'PAS' as const; 
  CONST_TVA_DOM         = 'DOM' as const;
  CONST_TVA_FR          = 'FR' as const;

  // gestion de l'affichage de la page
  texteButtonValidate = 'Valider la commande';
  isLinear: boolean = false;
  isOpen :  boolean = false;
  isLocation: boolean = false;
  isVisibleEnSavoirPlus: boolean = false;
  isSameAddress: boolean = false;
  isRead: boolean = false;
  
  // Tableau récapitulatif de la commande
  displayedColumns: string[] = ['article', 'quantite', 'prix_unitaire_ht', 'total_ht', 'par_Mois', 'pourcentage_remise', 'total_remise_ht', 'tauxTVA'];
  dataSource: any;

  // Tableau récapitulatif des prix
  displayedColumns2: string[] = ['libelle', 'prix'];
  dataSource2: any;

  // Variables utilisées pour la gestion des articles, des entités associées à l'utilisateur et l'utilisateur
  entites         : Array<Entite> = new Array<Entite> ();
  deliveryEntite$ : Array<Entite> = new Array<Entite> ();

  ent_raison_sociale : string = '';
  listArticles    : Array<Article> = new Array<Article>();
  $listOptions     : Array<Article> = new Array<Article>();
  entite!         : Entite
  idUtilisateur   : number = 0;
  idEntite        : number = 0;
  idEntiteDelivery: number = 0;

  // Variable permettant de connaître la quantité commandé par article 
  tabQuantite     = new Array;
  
  // Cette variable sert à savoir si au moins un article a été commandé et autorisé ou non de passer à l'étape 3 (Récapitulatif commande)
  quantite_total  : number = 0;
  
  // Variable utilisé pour connaître l'entité sélectionné
  selectedValue   : string = '';

  // Variable utilisé pour connaître la TVA associé à l'entité sélectionné
  // Cette variable peut prendre 3 valeurs : 'PAS' = 0%, 'DOM' = 8.5% ou 'FR' = 20%
  // Par défaut, cette variable est configuré sur 'PAS'
  libelle_tva     : any = this.CONST_TVA_PAS;
  pourcentage_tva : any = '0';

  mailValidator       = inject(MailValidator)
  telephoneValidator  = inject(TelephoneValidator)

  // Variables utilisées pour gérer les lignes_commandes et la commande
  ligne_commande  : LigneCommande = {
    id                : 0,
    id_commande       : 0,
    id_article        : 0,
    quantite          : 0,
    prix_unitaire_ht  : 0,
    total_ht          : '',
    pourcentage_remise: 0,
    total_remise_ht   : 0,
    tauxTVA           : 0,
    total_tva         : 0,
    article           : '',
    par_Mois          : '',
    reference         : '',
    code_fournisseur  : ''
  };

  commande: Commande = <Commande>{};    
  disableAdressFields:boolean = true
  // description d'un schema de validation de données
  infoFacture = this.fBuilder.group({
    id:                 new FormControl<number>(0,Validators.required),
    entite:             [<Entite>{}],
    raison_sociale:     new FormControl('',Validators.required),
    numero_client:      new FormControl(''),
    mailExpert:         new FormControl('', [Validators.required,this.mailValidator.checkValidatorMail()]),
    habilitation:       new FormControl(''),
    telephone_portable: new FormControl(''),
    intraco:            new FormControl(''),
    siren:              new FormControl(''),
    siret:              new FormControl(''),
    telephone:          new FormControl('',[this.telephoneValidator.verificationFormatTelephoneRequis()]),
    mail:               new FormControl('', [Validators.required,this.mailValidator.checkValidatorMail()]),
    tva : new FormGroup ({
      id: new FormControl<number>(0),
      libelle: new FormControl(''),
      pourcentage: new FormControl<number>(0)
    })
  });
  get raison_sociale()    { return this.infoFacture.get('raison_sociale')}
  get intraco()           { return this.infoFacture.get('intraco')}
  get siren()             { return this.infoFacture.get('siren')}
  get siret()             { return this.infoFacture.get('siret')}
  get adresse()           { return this.infoFacture.get('adresse')}   
  get adresseComplement() { return this.infoFacture.get('adresse_cplt')}   
  get code_postal()       { return this.infoFacture.get('code_postal')}   
  get ville()             { return this.infoFacture.get('ville')}   
  get telephone()         { return this.infoFacture.get('telephone')}   
  get telephone_portable(){ return this.infoFacture.get('telephone_portable')}   
  get mailExpert()        { return this.infoFacture.get('mailExpert')}   
  get mail()              { return this.infoFacture.get('mail')}   
  
  infoLivraison = this.fBuilder.group({  
    deliveryEntite: [<Entite>{}],
    destinataire  : ['', [Validators.required]]
  });
  get raisonSocialeLivraison()     { return this.infoLivraison.get('raison_sociale')}
  get adresseLivraison()           { return this.infoLivraison.get('adresse'); }   
  get adresseComplementLivraison() { return this.infoLivraison.get('adresse_cplt'); }   
  get codePostalLivraison()        { return this.infoLivraison.get('code_postal'); }   
  get villeLivraison()             { return this.infoLivraison.get('ville'); }   

  // Contrôles utilisés pour le passage à l'étape 2 (choix articles)
  formGroupEntite = this.fBuilder.group({
    entite: ['', [Validators.required]]
  });

  // Contrôles utilisés pour le passage à l'étape 3 (récapitulatif commande)
  formGroupArticle = this.fBuilder.group({
    quantite_total: [0, [this.verificationQuantite]]
  }); 
// Contrôles pour le validation des conditions lors d'une location.
  formValidation = this.fBuilder.group({
    modePaiement    : new FormControl<string>('0'),
    acceptCondition : new FormControl<boolean>(false,Validators.required)
  })
  get acceptCondition()       { return this.formValidation.get('acceptCondition')};

  // Categorie de commande et/ou articles
  cmdCategorie: any;    
  idCategorie: number = 0;
  codeCategorie: string = '';

  // Option de rachat de location de l'outil diagnostic
  idArticle_RachatSuiteLocation: number = 0;

  entitesUtilisateur  : Entite[]        = []
  
  //Intégration du composant Adresse:
adresseLivraisonEntite  : Adresse = {...AdresseInit,"ligne2":""}
adresseEntite           : Adresse= {...AdresseInit,"ligne2":""}
dirty                   : boolean = false;
disabledFields          : boolean = false
breakpointSmall         : boolean = true

  constructor(public    apiCommande: ApiCommandeService
              , public apiEntite: ApiEntiteService
              , private apiAuthentification: ApiAuthentificationService
              , public apiParam: ApiUtilitaireService
              , private fBuilder: FormBuilder
              , private router: Router
              , private route: ActivatedRoute
              , private toast: PersoSnackbarService
              , public  dialog: MatDialog
              , private apiEntiteAdresse : EntiteAdresseService
            ) { }

onResize(event:any) {
  //For responsiveness:
  this.breakpointSmall = (event.target.innerWidth <= 600) ? true : false;
}

  ngOnInit(): void {    
    
    //For responsiveness:
    this.breakpointSmall = (window.innerWidth <= 600) ? true : false;

    this.idUtilisateur = this.apiAuthentification.authUser.id; 
    this.infoFacture.patchValue({
      mailExpert: this.apiAuthentification.userConnect.email
    });
    this.isLinear = ( !this.apiAuthentification.userConnect.droits_utilisateur.est_admin );
    this.isOpen = (this.apiAuthentification.userConnect.droits_utilisateur.est_admin);
    //
    this.idCategorie = this.route.snapshot.params['idCategorie'];
    if ( this.idCategorie == 0 ) {
      this.toast.showInfo('destination incorrecte');
      this.router.navigate(['app/home']);
    }
    // Chargement des elements de commande
    this.commande.id=0;
    this.loadCategorie();
    
    this.chargeListeCabinet();   
    
    this.commande.idCategorie = this.idCategorie;
    this.getAdressesEntite()
  }
  
  public onPhoneModePortrait() {
    return (window.innerHeight > window.innerWidth);
  }
  
  /* VERIFICATION FORMULAIRE */
  verificationQuantite(fcControler: FormControl): ValidationErrors | null {
    if(fcControler.value <= 0 ){
      return { erreur: 'Vous devez choisir au moins un article.' }
    }    
    return null;
  }

  public chargeListeCabinet(): void {
    
    for(let i = 0; i < this.apiAuthentification.relUserEnt.length; i++) {
      if(this.apiAuthentification.relUserEnt[i].est_active) {
        this.entites.push(this.apiAuthentification.relUserEnt[i].entite);
        this.deliveryEntite$.push(this.apiAuthentification.relUserEnt[i].entite);
      }            
    }
  } 

  // Liste des categories de commande ou article
  public loadCategorie() {
    // récupération de toutes les type de commande
    this.apiCommande.getAnyCategorie()
    .subscribe(
      (data: any) => {
        this.cmdCategorie = data;
        // console.info(this.cmdCategorie);
        switch (+this.idCategorie) {
          case this.cmdCategorie.KIT_ADELA:
            this.codeCategorie = this.apiCommande.CONST_CATEGORIE_KIT_KITADELA;
            break;
          case this.cmdCategorie.DIAG:
            this.codeCategorie = this.apiCommande.CONST_CATEGORIE_OUTIL_DIAG;
            break;
          defaut:
            break;
        }
        this.setModeCommande()
        this.getArticleByCategorie()
      },
      (err) => { console.error(err) }
    )
  }

  public setModeCommande() {
    switch (this.codeCategorie) {
      case this.cmdCategorie.KIT_ADELA:
        this.infoFacture.clearValidators();
        this.infoLivraison.clearValidators();
        break;

      case this.cmdCategorie.DIAG:
        this.formGroupEntite.clearValidators();
        if ( this.isLocation ) {
          this.formValidation.controls.acceptCondition.setValidators([Validators.required])
        } else {
          this.formValidation.clearValidators();
        }
        break;

      default:
        break;
    }
  }

  public getArticleByCategorie(): void {
    // Chargement des articles de la catégorie
    this.apiCommande.getArticleByCategorie( this.idCategorie )
      .subscribe(
        (data: Article[]) => { 
          if (data) {
            this.listArticles = data;
            // Initialisation de la quantité à 0
            for (let i = 0; i < this.listArticles.length; i++){ 
              this.tabQuantite[this.listArticles[i].id] = 0;                     
            }  
            // Pour les commandes de diagnostics, on rajoute les options de recherche
            if (+this.idCategorie === this.cmdCategorie.DIAG) {
              this.apiCommande.getArticleByCategorie( this.cmdCategorie.OPT_DIAG )
              .subscribe(
                (data: Article[]) => {
                  this.$listOptions = data
                }
              )
            }
            
          }
        },
        (err) => { '/!\\ error getArticleByCategorie: ' + console.log(err) }
      );
  }

  public increaseQuantity(id: number): void {
    
    if ( this.codeCategorie == this.apiCommande.CONST_CATEGORIE_OUTIL_DIAG ) {
      // raz de toutes les quantités : soit on achete, soit on loue, mais pas les deux.
      this.quantite_total = 0
      this.tabQuantite.forEach( (element, index) => {
        if ( index != id ) { 
          this.tabQuantite[index] = 0;
        } else {
          this.tabQuantite[index]++;
          if (this.tabQuantite[index] > 10 ) {
            this.tabQuantite[index] = 10;
          }
          this.quantite_total = this.tabQuantite[index];
        };
      });
    } else {
      this.quantite_total += 1;
      this.tabQuantite[id] += 1;
    }
  }

  public decreaseQuantity(id: number): void {
    if(this.tabQuantite[id] > 0 ) {
      this.quantite_total   -= 1;
      this.tabQuantite[id]  -= 1;
    }
  }

  public emptyQuantity(id: number): void {
    if(this.tabQuantite[id] > 0) { 
      this.quantite_total -= this.tabQuantite[id];     
      this.tabQuantite[id] = 0;
    }
  }

  public ajouterArticles(): void {
    this.setModeCommande();
    this.supprimerArticles();

    if(this.quantite_total > 0) {
      var tmp_total_ht        : number = 0;
      let tmp_total_remise_ht : number = 0;
      let tmp_port_ht         : number = 0;
      let tmp_total_tva       : number = 0;
      
  
      for(var idArticle in this.tabQuantite) {     
        if (this.tabQuantite[idArticle] > 0) {                  
          this.ligne_commande.id_article  = parseInt(idArticle);
          this.ligne_commande.quantite    = this.tabQuantite[idArticle];
          
          const pourcentage:number = this.infoFacture.controls.tva.controls.pourcentage.value!
          // ajout des articles faisant partie de la categorie en cours 
          for(let i = 0; i < this.listArticles.length; i++) {
            if (this.listArticles[i].id == parseInt(idArticle)) {
              if (this.listArticles[i].location_ht > 0) {
                // LOCATION : 
                let tarifLocation: number = 0;
                this.isLocation = true;                
                tarifLocation                           = (this.listArticles[i].location_ht * this.tabQuantite[idArticle]);
                this.ligne_commande.prix_unitaire_ht    = this.listArticles[i].location_ht;
                this.ligne_commande.tauxTVA             = this.infoFacture.controls.tva.controls.pourcentage.value!
                this.ligne_commande.total_tva           = this.tabQuantite[idArticle] * this.listArticles[i].location_ht - this.tabQuantite[idArticle] * this.listArticles[i].remise * pourcentage / 100;
                this.ligne_commande.total_ht            = tarifLocation.toString();
                this.ligne_commande.par_Mois            = '/mois';
                this.ligne_commande.pourcentage_remise  = this.listArticles[i].remise;
                this.ligne_commande.total_remise_ht     = this.listArticles[i].location_ht * this.tabQuantite[idArticle] * this.listArticles[i].remise / 100;

                tmp_total_ht        += tarifLocation;
                tmp_total_remise_ht += this.listArticles[i].location_ht * this.tabQuantite[idArticle] * this.listArticles[i].remise / 100;
              } else {
                // ACHAT :
                this.isLocation = false;
                this.formValidation.clearValidators();

                this.ligne_commande.prix_unitaire_ht    = this.listArticles[i].prix_ht;
                this.ligne_commande.tauxTVA             = pourcentage
                this.ligne_commande.total_tva           = this.tabQuantite[idArticle] * this.listArticles[i].prix_ht - this.tabQuantite[idArticle] * this.listArticles[i].remise * pourcentage / 100;
                this.ligne_commande.total_ht            = (this.listArticles[i].prix_ht * this.tabQuantite[idArticle]).toString();
                this.ligne_commande.par_Mois            = ''
                this.ligne_commande.pourcentage_remise  = this.listArticles[i].remise;
                this.ligne_commande.total_remise_ht     = this.listArticles[i].prix_ht * this.tabQuantite[idArticle] * this.listArticles[i].remise / 100;

                tmp_total_ht        += parseInt(this.ligne_commande.total_ht);
                tmp_total_remise_ht += this.listArticles[i].prix_ht * this.tabQuantite[idArticle] * this.listArticles[i].remise / 100;
              }
  
              this.ligne_commande.article = this.listArticles[i].titre;
              break;
            }            
          }   

          this.commande.tab_LigneCommande.push(this.ligne_commande);

          this.ligne_commande = {
            id                : 0,
            id_commande       : 0,
            id_article        : 0,
            quantite          : 0,
            prix_unitaire_ht  : 0,
            total_ht          : '',
            pourcentage_remise: 0,
            total_remise_ht   : 0,
            tauxTVA           : 0,
            total_tva         : 0,
            article           : '',
            par_Mois          : '',
            reference         : '',
            code_fournisseur  : ''
          };
        }        
      }

      this.libelle_tva        = this.infoFacture.controls.tva.controls.libelle.value;
      this.pourcentage_tva    = this.infoFacture.controls.tva.controls.pourcentage.value;
      this.ent_raison_sociale = this.infoFacture.value.raison_sociale? this.infoFacture.value.raison_sociale : '';
      this.idEntite           = this.infoFacture.value.id? this.infoFacture.value.id : 0;

      switch(this.libelle_tva) { 
        // Pas de TVA donc Total TVA = 0 et aucun frais de port
        case this.CONST_TVA_PAS: {                       
           tmp_port_ht    = 0; 
           tmp_total_tva  = 0;
           break; 
        } 
        // DOM = TVA à 8,5% + Frais de port (dépendent des kits commandés)
        case this.CONST_TVA_DOM: { 
          let b_kit_combustible = false;
          let b_kit_moteur_3    = false;
          let b_kit_moteur_4    = false;

          tmp_port_ht = 24.23;

          const articles    = this.commande.tab_LigneCommande.map(el => el.article);          
          b_kit_combustible = articles.includes('Kit Combustible');
          b_kit_moteur_3    = articles.includes('Kit Moteur 3 (Lot de 50 avec seringues)');
          b_kit_moteur_4    = articles.includes('Kit Moteur 4 (Lot de 50)');

          if(b_kit_combustible) {
            if((b_kit_moteur_3 || b_kit_moteur_4)){
              tmp_port_ht += 20.39;
            } else{
              tmp_port_ht += 15.31;
            }            
          } else {
            if((b_kit_moteur_3 || b_kit_moteur_4)){
              tmp_port_ht += 15.31;
            }            
          }

          tmp_total_tva = (tmp_total_ht - tmp_total_remise_ht + tmp_port_ht) * (this.pourcentage_tva/100);

           break; 
        } 
        // FR = TVA à 20% 
        case this.CONST_TVA_FR: {  
           tmp_port_ht = 0;
           tmp_total_tva = (tmp_total_ht - tmp_total_remise_ht + tmp_port_ht) * (this.pourcentage_tva/100);
           break; 
        } 
        default: { 
           tmp_port_ht = 0;
           break; 
        } 
     } 
      
      this.commande.total_ht        = tmp_total_ht.toFixed(2).toString();
      this.commande.total_remise_ht = tmp_total_remise_ht.toFixed(2).toString();  
      this.commande.port_ht         = tmp_port_ht.toFixed(2).toString();
      this.commande.total_tva       = tmp_total_tva.toFixed(2).toString();
      this.commande.total_ttc       = (tmp_total_ht - tmp_total_remise_ht + tmp_port_ht + tmp_total_tva).toFixed(2).toString();
      
      // Affectation des 'dataSources' des tableaux récapitulatifs
      this.dataSource = this.commande.tab_LigneCommande;
      this.dataSource2 = [
        {libelle: 'Total HT'  , prix : this.commande.total_ht},
        {libelle: 'Total remise HT', prix : this.commande.total_remise_ht},
        {libelle: 'Port HT'   , prix: this.commande.port_ht},
        {libelle: 'TVA ('+this.pourcentage_tva+' %)', prix: this.commande.total_tva},
        {libelle: 'Total TTC' , prix: this.commande.total_ttc}];
    }    
  }
 
  public onStepChange(newStep: StepperSelectionEvent): void {      
      // A chaque sortie de l'étape de sélection des articles, on valide les articles
      // Etape "0" pour le calcul de la TVA et etape "1" pour contenu de la commande
      if (newStep.previouslySelectedIndex < 2){
        this.ajouterArticles();
      }
      
      // this.isSameAddress = ( this.infoFacture.get('adresseEntite.adresseVille')!.value == this.infoLivraison.get('adresse.adresseVille')!.value && this.infoFacture.get('adresseEntite.adresseLigne1')!.value == this.infoLivraison.get('adresse.adresseLigne1')!.value )
      this.isSameAddress = ( this.adresseEntite.ville == this.adresseLivraisonEntite.ville 
                            && this.adresseEntite.ligne1 == this.adresseLivraisonEntite.ligne1 )
  }

  public onSelectEntite($event: any) {
    // téléchargement des information de l'entité sélectionné et patch de valeur vers l'affichage
    if($event.value != undefined) {
      this.apiEntite.getEntiteById($event.value)
      .subscribe(
        (data: Entite[]) => {
          this.entite = data[0]
          this.infoFacture.patchValue({
            id:                 data[0].id,
            raison_sociale:     data[0].raison_sociale,
            numero_client:      data[0].numero_client,
            mailExpert:         this.apiAuthentification.userConnect.email,
            habilitation:       data[0].habilitation,
            telephone_portable: this.apiAuthentification.userConnect.telephone,
            intraco:            'FR'+ (12 + 3 * (+data[0].siren % 97) % 97) + data[0].siren,
            siren:              data[0].siren,
            siret:              data[0].siret,
            telephone:          data[0].telephone,
            mail:               data[0].mail_facturation,
            tva: {
              libelle:       data[0].tva?.libelle,
              pourcentage:   data[0].tva? +data[0].tva.pourcentage : 0
            }
          });
          this.idEntite = (data[0].id == undefined ? 0 : data[0].id);
          
          // On filtre sur l'entité groupe pour les entités succursale
          this.deliveryEntite$ = [];
          this.deliveryEntite$ = this.entites.filter(element => element.id_entite_principale === this.idEntite || element.id === this.idEntite );
          this.adresseLivraisonEntite = {...this.adresseLivraisonEntite
            ,"ligne1"     : data[0].adresse
            ,"cp"         : data[0].code_postal
            ,"ville"      : data[0].ville
            ,"ligne2"     : data[0].adresse_cplt
            ,"nom"        : data[0].raison_sociale
            ,"contact"    : data[0].numero_client
            ,"ligne3"     : data[0].adresse_ligne3
          } 
          this.adresseEntite = {...this.adresseEntite
            ,"ligne1"     : data[0].adresse
            ,"cp"         : data[0].code_postal
            ,"ville"      : data[0].ville
            ,"ligne2"     : data[0].adresse_cplt
            ,"nom"        : data[0].raison_sociale
            ,"contact"    : data[0].numero_client
            ,"ligne3"     : data[0].adresse_ligne3
          } 

          this.infoLivraison.patchValue({
            // deliveryEntite: data[0],
            destinataire  : data[0].raison_sociale
          })
          this.idEntiteDelivery = this.idEntite;
          
          console.info('entite principale / livraison : '+this.idEntite+'/'+this.idEntiteDelivery)
        },
        (err: any) => { console.log('onSelectEntite: ',err) },
        () => {}
      )
    } else {
      // RAZ
      this.infoFacture.patchValue({
        id:                 0,
        raison_sociale:     '',
        numero_client:      '',
        mailExpert:         '',
        habilitation:       '',
        telephone_portable: '',
        intraco:            '',
        siren:              '',
        siret:              '',
        telephone:          '',
        mail:               '',
        tva: {
          libelle:       '',
          pourcentage:   0
        }
      });

      this.idEntite = 0;

      // On supprime le filtre
      this.deliveryEntite$ = [];
      this.deliveryEntite$ = this.entites;
      this.adresseLivraisonEntite = {...this.adresseLivraisonEntite
        ,"ligne1": ""
        ,"cp": ""
        ,"ville": ""
        ,"ligne2":""
        ,"ligne3":""
      }  
      this.infoLivraison.patchValue({      
        destinataire  : ''
      })
      this.idEntiteDelivery = 0;
    }
  }

  // Sélection d'une entite de livraison
  public onSelectDeliveryEntite($event: any) {
    this.disableAdressFields = true;
    let entiteSelect: Entite | undefined = <Entite>{};
    entiteSelect = this.deliveryEntite$.find(element => element.id === this.idEntiteDelivery)
    this.adresseLivraisonEntite = {...this.adresseLivraisonEntite
      ,"ligne1" : entiteSelect? entiteSelect.adresse : ""
      ,"cp"     : entiteSelect? entiteSelect.code_postal : ""
      ,"ville"  : entiteSelect? entiteSelect.ville : ""
      ,"ligne2" : entiteSelect?.adresse_cplt
      ,'nom'    : entiteSelect?.raison_sociale
      ,'contact': entiteSelect?.numero_client
      ,'ligne3' : entiteSelect?.adresse_ligne3
    }    
    this.infoLivraison.patchValue({
      destinataire  : entiteSelect?.raison_sociale
    })
  }

  public commander(): void {
    if(this.commande.id <= 0){
      this.commande.id_utilisateur  = this.idUtilisateur;
      this.commande.id_entite       = this.idEntite;
      this.commande.option          = this.isLocation ? this.apiCommande.CONST_OPTION_LOCATION : this.apiCommande.CONST_OPTION_ACHAT;
      this.commande.modePaiement    = this.formValidation.value.modePaiement? this.formValidation.value.modePaiement : '';

      this.commande.raison_sociale            = this.entite.raison_sociale;
      this.commande.adresseFacturation        = this.adresseEntite;
      this.commande.raison_sociale_livraison  = this.infoLivraison.value.destinataire? this.infoLivraison.value.destinataire : '';
      this.commande.adresseLivraison          = this.adresseLivraisonEntite;

      this.commande.telephone       = this.infoFacture.value.telephone? this.infoFacture.value.telephone : '';
      this.commande.tva_intracommunautaire = this.infoFacture.value.intraco? this.infoFacture.value.intraco : '';
  
      this.apiCommande.postCommand(this.commande)
        .subscribe( data => {        
          this.commande = data;      
         },
         (err) => { '/!\\ error postCommande: ' + console.log(err) }
        );
    }
  }
  
  reinitialiserCommande(): void {
    this.dataSource   = [];
    this.dataSource2  = [];
    this.ent_raison_sociale = '';
    this.idEntite           = 0;
    this.isLocation         = false;

    for (let i = 0; i < this.listArticles.length; i++){ 
      this.tabQuantite[this.listArticles[i].id] = 0;                     
    }   
    
    this.quantite_total   = 0;
    this.selectedValue    = '';
    this.libelle_tva      = this.CONST_TVA_PAS;
    this.pourcentage_tva  = '0';
    
    this.ligne_commande = {
      id                : 0,
      id_commande       : 0,
      id_article        : 0,
      quantite          : 0,
      prix_unitaire_ht  : 0,
      total_ht          : '',
      pourcentage_remise: 0,
      total_remise_ht   : 0,
      tauxTVA           : 0,
      total_tva         : 0,
      article           : '',
      par_Mois          : '',
      reference         : '',
      code_fournisseur  : ''
    };

    this.commande = {
      id: 0,
      numero: '',
      date_creation: new Date(0),
      date_modification: '',
      idEtat: 0,
      Etat: {
        id: 0,
        code: '',
        description: '',
        libelle: '',
        ordre: 0,
        selected: false,
        service: '',
        visible: false
      },
      total_ht: '',
      total_remise_ht: '',
      port_ht: '',
      total_tva: '',
      total_ttc: '',
      id_utilisateur: 0,
      id_entite: 0,
      idCategorie: this.idCategorie,
      option: '',
      modePaiement: '',
      tab_LigneCommande: [],
      raison_sociale: '',
      raison_sociale_livraison: '',
      adresseFacturation: AdresseInit,
      adresseLivraison: AdresseInit,
      telephone: '',
      tva_intracommunautaire: ''
    };    

    this.formGroupArticle.patchValue({
     // quantite_total: 0,
      // entité: 
    });

    this.formValidation.patchValue({
      modePaiement: '',
      acceptCondition: false
    })
  }

  supprimerArticles(): void {
    this.commande.tab_LigneCommande = [];
  }

  navigateToHome(): void {
    this.router.navigate(['/app/home']);
  }

  // Message de confirmation de la commande
  dialogConfirmOrder(): void {
    const dialogRef = this.dialog.open(DialogOrderValidationComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true){
        this.commander();
      }
    });
  }    

  //
  public readMore(){
    this.isVisibleEnSavoirPlus = !this.isVisibleEnSavoirPlus;
  }
  //
  public KeepInvoiceAddress() {
    this.adresseLivraisonEntite = {...this.adresseLivraisonEntite
      ,"ligne1"   : this.adresseEntite.ligne1? this.adresseEntite.ligne1 : ''
      ,"cp"       : this.adresseEntite.cp? this.adresseEntite.cp : ''
      ,"ville"    : this.adresseEntite.ville? this.adresseEntite.ville : ''
      ,"ligne2"   : this.adresseEntite.ligne2? this.adresseEntite.ligne2 : ''
      ,"ligne3"   : this.adresseEntite.ligne3? this.adresseEntite.ligne3 : ''
    } 
    this.infoLivraison.patchValue({
      destinataire: this.infoFacture.value.raison_sociale,
    });
  }
     
  filterEntitesOfType(type: boolean){
    let pourOutilDiag: boolean;
    
    pourOutilDiag = (this.codeCategorie == this.apiCommande.CONST_CATEGORIE_OUTIL_DIAG)

    // Pour les outils diag, les entités de type ADELA ne doivent pas être proposées
    return this.entites.filter(entite =>
      // Vérifie si l'entité est active
      entite.EstActif == type &&
      // Vérifie si l'entité est inscrite
      entite.EstInscrit == true &&
      // Si pourOutilDiag est vrai, vérifie que le code de type de l'entité n'est pas CONST_CODE_TYPE_ENTITE_ADELA
      // Sinon, cette condition est toujours vraie
      (pourOutilDiag == true ? entite.typ_ent_code != this.apiEntite.CONST_CODE_TYPE_ENTITE_ADELA : true)
    );
  }

  filterEntitesDeliveryOfType(type: boolean){
    return this.deliveryEntite$.filter(entite => entite.EstActif == type && entite.EstInscrit == true);
  }

  getAdressesEntite(){
    this.apiEntiteAdresse.getEntiteAdresse(this.idUtilisateur).subscribe(
      (entites:Entite[])=>{
        this.entitesUtilisateur = entites
      }
    )
  }

  OuvreSelectionAdresse(){
    
    const entitesValides = this.entitesUtilisateur.filter((entite:Entite)=> {
      const entiteFound = this.entites.find((entiteOrigine:Entite)=>entiteOrigine.id===entite.id && entiteOrigine.EstActif===true)
      return entiteFound
    })
    const dialogRef = this.dialog.open(DialogEntiteAdresseComponent,{data:entitesValides});
    dialogRef.afterClosed().subscribe((result : EntiteAdresse|false)  => {
      if (result != false && result){
        this.adresseLivraisonEntite = {...this.adresseLivraisonEntite
          ,"ligne1"     : result.adresse.ligne1
          ,"ligne2"     : result.adresse.ligne2
          ,"ligne3"     : result.adresse.ligne3
          ,"cp"         : result.adresse.cp
          ,"ville"      : result.adresse.ville
          ,'nom'        : result.entite.raison_sociale
        } 
        this.infoLivraison.patchValue({
          destinataire  : result.entite.raison_sociale
        })
        this.getAdressesEntite()
      }
    });
    
  }
}

