<div [ngSwitch]="modeView">
        <app-filter-suivi 
            *ngSwitchCase="VIEW_FILTER"
            (filterSelected)="applyFilter($event)"
            [relUtiEntites]="relUtiEntites">
        </app-filter-suivi>
    
        <app-list-suivi 
            *ngSwitchCase="VIEW_FILTER"
            [suivis]="suivis"
            (archiveEvent)="archiver($event)"
            (editSuiviEvent)="editSuivi($event)"
            (editSuiviSivEvent)="editSuiviSiv($event)"
            [error]="errorList">
        </app-list-suivi>
    
        <!-- Autres composants -->
        <app-list-suivi-siv
                *ngSwitchCase           = "VIEW_SIV"
                [suivisSiv]             ="suivisSiv"
                [dossier]               = "suiviBack.dossier!"
                (retourEvent)           = "getRetour()"
        >

        </app-list-suivi-siv>
        <app-edit-suivi
                *ngSwitchCase   = "VIEW_EDIT"
                [references]    = "references"
                [errors]        = "errors"
                [rdeaFile]      = "rdeaFile"
                [entites]       = "entites"
                (fileEvent)     = "sendFile($event)"
                (retourEvent)   = "getRetourFromEdit()"
                (editSuiviEvent)= "editSuiviSivFromEdit()"
                [waiting]       = "waiting"
                >

        </app-edit-suivi>
</div>