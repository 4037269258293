import { NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { InvitationCollaborateur } from 'src/app/interfaces/invitation-collaborateur/invitation-collaborateur';
import { Entite } from 'src/app/interfaces/profil-user/entite';
import { EntiteAccesConcentrateur } from 'src/app/interfaces/profil-user/entite-acces-concentrateur';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { RelationUtilisateurEntite } from 'src/app/interfaces/utilisateur-entite/relation-utilisateur-entite';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { ListeHistoriquePrelevementsComponent } from '../../administration-extranet/gestion-comptabilite/historique-prelevements/liste-historique-prelevements/liste-historique-prelevements.component';
import { InvitationCollaborateurComponent } from '../../invitation/invitation-collaborateur/invitation-collaborateur.component';
import { InvitationListeCollaborateurComponent } from '../../invitation/invitation-liste-collaborateur/invitation-liste-collaborateur.component';
import { ShellAdresseComponent } from '../../templates/adresse/shell-adresse/shell-adresse.component';
import { EntiteAccesConcentrateurComponent } from '../entite-acces-concentrateur/entite-acces-concentrateur.component';
import { EntiteCoordonneeBancaireComponent } from '../entite-coordonnee-bancaire/entite-coordonnee-bancaire.component';
import { EntiteInformationsGeneralesComponent } from '../entite-informations-generales/entite-informations-generales.component';
import { ListeSuccursaleComponent } from '../liste-succursale/liste-succursale.component';
import { ListeUtilisateursComponent } from '../liste-utilisateurs/liste-utilisateurs.component';
import { ListeParametreUtilisateurComponent } from "../parametres-utilisateur/liste/liste-parametres-utilisateur.component";
import { UtilisateurParamService } from 'src/app/services/utilisateur-param.service';

export interface OutputParent{
  acces_concentrateur_actif         : boolean,
  creer_succursale                  : boolean,
  entite_est_succursale             : boolean,
  est_dirigeant_principal           : boolean,
  utl_profil                        : ProfilUtilisateur,
  entite                            : Entite,
  ent_id                            : number,
  RelUtilisateurEntite              : RelationUtilisateurEntite,
  acces_concentrateur               : EntiteAccesConcentrateur,
  succursales                       : Array<RelationUtilisateurEntite>,
  display                           : boolean,
  disabled_informations_generales   : boolean,
  disabled_invitation_collaborateur : boolean,
  disabled_liste_collaborateur      : boolean
}
export interface OutputParent2{
  disabled_informations_generales   : boolean,
  disabled_invitation_collaborateur : boolean,
  disabled_liste_collaborateur      : boolean
}
export interface OutputParent3{
  RelUtilisateurEntite              : Array<RelationUtilisateurEntite>; 
}
@Component({
    selector: 'app-entite',
    templateUrl: './entite.component.html',
    styleUrls: ['./entite.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbarModule, MatFormFieldModule, MatIconModule, MatTabsModule, EntiteInformationsGeneralesComponent,
    EntiteCoordonneeBancaireComponent, EntiteAccesConcentrateurComponent, ListeUtilisateursComponent,
    ListeSuccursaleComponent, InvitationCollaborateurComponent, ListeHistoriquePrelevementsComponent,
    InvitationListeCollaborateurComponent, MatDividerModule, MatButtonModule, ShellAdresseComponent, ListeParametreUtilisateurComponent]
})
export class EntiteComponent implements OnInit {    
  CONST_NOM_TYPE_ENTITE_PRINCIPALE = 'Cabinet Expertise' as const;
  CONST_NOM_TYPE_ENTITE_SUCCURSALE = 'Succursale' as const;

  CONST_ACTION_AJOUTER_COLLABORATEUR_DEPUIS_EXTRANET_FFEA = 'nouveau_collaborateur' as const;
  CONST_ACTION_AJOUTER_COLLABORATEUR_DEPUIS_CONCENTRATEUR = 'nouveau_collaborateur_depuis_concentrateur' as const;
  CONST_ACTION_OUVRIR_ONGLET_CONCENTRATEUR                = 'ouvrir_concentrateur' as const;
  CONST_ACTION_OUVRIR_ONGLET_COORDONNEES_BANCAIRE         = 'ouvrir_coordonnees_bancaire' as const;

  action                            : string = '';

  bFirstEvent                       : boolean = false;
  acces_concentrateur_actif         : boolean = false;
  creer_succursale                  : boolean = false;
  entite_est_succursale             : boolean = false;
  est_dirigeant_principal           : boolean = false;
  display                           : boolean = false;
  disabled_informations_generales   : boolean = false;
  disabled_invitation_collaborateur : boolean = true;
  disabled_liste_collaborateur      : boolean = true;

  utl_profil                        : ProfilUtilisateur = <ProfilUtilisateur>{};
  entite                            : Entite = <Entite>{};
  ent_id                            : number = 0;  
  RelUtilisateurEntite              : RelationUtilisateurEntite = <RelationUtilisateurEntite>{};
  RelUtilisateursEntites            : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite> (); 
  acces_concentrateur               : EntiteAccesConcentrateur = <EntiteAccesConcentrateur>{};
  succursales                       : Array<RelationUtilisateurEntite> = new Array<RelationUtilisateurEntite>();  
  selectedTab                       :number |null=null
  // Redirection par mail pour activé ou inviter un collaborateur
  
  // Variable passé en paramètre de l'URL
  id_user_unknown                   : number = 0;
  habilitation                      : string = '';
  indice                            : string = '';
  nom_expert                        : string = '';
  prenom_expert                     : string = '';
  agrement_expert                   : string = '';
  isServiceFFEA                     : boolean= false;
  dirigeant                         : boolean = false
  utl_profil_unknow                 : ProfilUtilisateur = <ProfilUtilisateur>{};
  invitationCollaborateur           : InvitationCollaborateur = <InvitationCollaborateur>{};

  @ViewChild("tab", { static: false }) tab!: MatTabGroup;
  
  constructor(public _sanitizer       : DomSanitizer
            , private route           : Router
            , private routed          : ActivatedRoute
            , private toast           : PersoSnackbarService
            , private apiUtilisateur  : ApiUtilisateurService
            , public apiEntite        : ApiEntiteService
            , private apiAuth         : ApiAuthentificationService
            , public  apiUtiParam     : UtilisateurParamService
            ) {
              this.route.routeReuseStrategy.shouldReuseRoute = function() {
                return false;
      };
    }

  init():void{
    // .../entite/:id_entite?action=...
    
    if(this.routed.snapshot.queryParams.action != undefined && this.routed.snapshot.queryParams.action != '') {
      this.action = this.routed.snapshot.queryParams.action;
    }

    if(this.routed.snapshot.params['id'] != undefined && this.routed.snapshot.params['id'] > 0) {
      this.ent_id = this.routed.snapshot.params['id'];
    }

    // Récupération des paramètres depuis l'url (url reçu par mail) => 
    // .../entite/?habilitation=...
    if(this.routed.snapshot.queryParams.habilitation != undefined && this.routed.snapshot.queryParams.habilitation != '') {
      this.habilitation = this.routed.snapshot.queryParams.habilitation;
    }

    if(this.routed.snapshot.queryParams.indice != undefined && this.routed.snapshot.queryParams.indice != '') {
      this.indice = this.routed.snapshot.queryParams.indice;
    }

    if(this.routed.snapshot.queryParams.nom != undefined && this.routed.snapshot.queryParams.nom != '') {
      this.nom_expert = this.routed.snapshot.queryParams.nom;
    }

    if(this.routed.snapshot.queryParams.prenom != undefined && this.routed.snapshot.queryParams.prenom != '') {
      this.prenom_expert = this.routed.snapshot.queryParams.prenom;
    }

    if(this.routed.snapshot.queryParams.agrement != undefined && this.routed.snapshot.queryParams.agrement != '') {
      this.agrement_expert = this.routed.snapshot.queryParams.agrement;
    }

    // Cas où l'on doit rediriger vers :
    //    -> l'invitation d'un collaborateur
    //    -> la fiche profil de l'expert inconnu

    // Récupération de l'id user depuis l'url (url reçu par mail) => 
    // .../entite/:id_entite?id_user=...
    if(this.routed.snapshot.queryParams.id_user != undefined && this.routed.snapshot.queryParams.id_user > 0) {
      this.id_user_unknown = this.routed.snapshot.queryParams.id_user;
    }

    switch (this.action) {
      case this.CONST_ACTION_AJOUTER_COLLABORATEUR_DEPUIS_EXTRANET_FFEA:
        break;

      case this.CONST_ACTION_AJOUTER_COLLABORATEUR_DEPUIS_CONCENTRATEUR:
        // On cherche à retrouver une correspondance au sein de notre base de données Extranet FFEA
        // Pour le cabinet et l'expert "inconnu"
        this.apiEntite.getEntiteByHabilitationIndice(this.habilitation, this.indice)
        .pipe(
          tap((entites: Entite[]) => {
            if(entites[0].id != undefined) {
              this.ent_id = entites[0].id;
            } 
          }),
          switchMap(() => this.apiUtilisateur.getUtilisateurByAgrement(this.agrement_expert)),
          tap((profil: ProfilUtilisateur) => {
            if(profil.id != undefined) {
              this.id_user_unknown = profil.id;
            }
          })         
        ).subscribe(
          () => {
            this.navigateTo();
          },
          (err: HttpErrorResponse) => {
            this.navigateTo();
          }
        );              

        break;

      default:
        break;
    }  
    
    this.isServiceFFEA = this.apiAuth.isServiceFFEA();
  }

  ngOnInit(): void {
    
    this.init();

    this.routed.fragment
    .subscribe(tabsToSelect => {
      
      if ( tabsToSelect != undefined ) {
        this.selectedTab=+tabsToSelect
      }else{
        this.selectedTab = null
      }
    });
  }
 
  public navigateTo(): void {
      // 1. On ne trouve l'entité dans notre base de données Extranet FFEA
      // (plusieurs possiblités : pas encore migré, nouvelle entité, ...)
      // On redirige l'utilisateur sur la page d'accueil avec un toast
      // indiquant qu'il faut créer l'entité dans une premier temps
      if(this.ent_id == undefined || !(this.ent_id > 0)) {
        this.route.navigate(['/app/home']);
        this.toast.showError('L\'entité n\'existe pas dans l\'Extranet FFEA. Vous devez créer cette entité avant de pouvoir rattacher l\'expert à celle-ci.');
      } else {
        // 2. On ne trouve pas l'utilisateur inconnu dans notre base de données Extranet FFEA
        if(this.id_user_unknown == undefined || !(this.id_user_unknown > 0)) {
          this.route.navigate(['/app/entite/'+this.ent_id], { queryParams: { habilitation : this.habilitation, indice: this.indice, nom: this.nom_expert, prenom: this.prenom_expert, agrement: this.agrement_expert, action :this.CONST_ACTION_AJOUTER_COLLABORATEUR_DEPUIS_EXTRANET_FFEA}});
        } else {
          this.route.navigate(['/app/entite/'+this.ent_id], { queryParams: { id_user: this.id_user_unknown, action: this.CONST_ACTION_AJOUTER_COLLABORATEUR_DEPUIS_EXTRANET_FFEA}});
        }
      }
  }
  
  // Evènement en provenance du component liste-utilisateur
  public onChildCellCliked4(event : OutputParent3): void {    
    switch (this.action) {
      case this.CONST_ACTION_AJOUTER_COLLABORATEUR_DEPUIS_EXTRANET_FFEA:
        // Pour ne pas effectuer plusieurs fois la même manipulation, car l'evènement est dépendant du NgOnChange Child
        if(!this.bFirstEvent) {

          if(this.id_user_unknown != undefined && this.id_user_unknown > 0) {
            let bNouveauCollaborateur : boolean = true;
            let bAccesConcentrateur   : boolean = false;

            this.RelUtilisateursEntites = event.RelUtilisateurEntite;
            
            for(let i = 0; i < this.RelUtilisateursEntites.length; i++) {
              if(this.RelUtilisateursEntites[i].utilisateur_id == this.id_user_unknown && this.RelUtilisateursEntites[i].entite_id == this.ent_id) {
                bNouveauCollaborateur = false;
  
                if(this.RelUtilisateursEntites[i].utilisateur.acces_concentrateur.length == 0) {
                  this.route.navigate(['/app/entite/' + this.ent_id + '/utilisateur/' + this.id_user_unknown]);
                }
  
                // L'expert est soit activé / désactivé du concentrateur de l'entité
                for(let j = 0; j < this.RelUtilisateursEntites[i].utilisateur.acces_concentrateur.length; j++) {
                  if(this.RelUtilisateursEntites[i].utilisateur.acces_concentrateur[j].cle_expert === this.RelUtilisateursEntites[i].utilisateur.agrement_ve + this.entite.habilitation) {
                    bAccesConcentrateur = true;                                                  
                    // Switch de tab sur liste collaborateur
                    const tabGroup = this.tab;
                    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
                    const tabCount = tabGroup._tabs.length;
                    let selectedIndex: number = 0;
                    if(tabGroup.selectedIndex != null) { selectedIndex = tabGroup.selectedIndex }      
                    tabGroup.selectedIndex = (selectedIndex + 3) % tabCount;  
  
                    this.toast.showInfo('L\'expert est déjà activé dans l\'entité.');  
                  }                                       
                }            
              }
            }            
            if(bNouveauCollaborateur) {
              this.apiUtilisateur.getUtilisateur(this.id_user_unknown)
                .subscribe(
                  (profil: ProfilUtilisateur) => {
  
                    // Utilisation d'une variable temporaire pour clôner les données
                    // Méthode pour trigger le ngOnChange du Child app-invitation-collaborateur, car ce dernier utilise la référence de l'input pour détecter un changement
                    let invitation: InvitationCollaborateur = <InvitationCollaborateur>{};
                    invitation.email = profil.email;
                    invitation.estDirigeant = false;
                    invitation.nom = profil.nom;
                    invitation.prenom = profil.prenom;
                    this.invitationCollaborateur = invitation //JSON.parse(JSON.stringify(invitation))
  
                    // Switch de tab sur invitation collaborateur
                    const tabGroup = this.tab;
                    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
                    const tabCount = tabGroup._tabs.length;
                    tabGroup.selectedIndex = tabCount;
                  },
                  (error : any) => {
                    this.toast.showError('Erreur de chargement de l\'utilisateur "' + error.error + '"');
                  }
                );        
            } else {
              if(!bAccesConcentrateur) {
                this.route.navigate(['/app/entite/' + this.ent_id + '/utilisateur/' + this.id_user_unknown]);
              }
            }            
          } else {
            // Cas où l'utilisateur reste inconnu (ex : utilisateur inconnu en provenance du Concentrateur)
            
            // Utilisation d'une variable temporaire pour clôner les données
            // Méthode pour trigger le ngOnChange du Child app-invitation-collaborateur, car ce dernier utilise la référence de l'input pour détecter un changement
            let invitation: InvitationCollaborateur = <InvitationCollaborateur>{};
            invitation.email              = '';
            invitation.estDirigeant       = false;
            invitation.nom                = this.nom_expert;
            invitation.prenom             = this.prenom_expert;
            this.invitationCollaborateur  = invitation

            // Switch de tab sur invitation collaborateur
            const tabGroup = this.tab;
            if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
            const tabCount = tabGroup._tabs.length;   
            tabGroup.selectedIndex =  tabCount;
          }
          
          this.bFirstEvent = true;
        }
        break;

      default:
        break;
    }               
  }

  // Evènement en provenance du component invitation-liste-collaborateur
  public onChildCellClicked3(event: OutputParent2): void {
    this.disabled_informations_generales    = event.disabled_informations_generales;
    this.disabled_liste_collaborateur       = event.disabled_liste_collaborateur;
    this.disabled_invitation_collaborateur  = event.disabled_invitation_collaborateur;

    // Switch de tab sur liste collaborateur
    if(!this.disabled_liste_collaborateur) {
      const tabGroup = this.tab;
      if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
      const tabCount = tabGroup._tabs.length;
      let selectedIndex: number = 0;
      if(tabGroup.selectedIndex != null) { selectedIndex = tabGroup.selectedIndex }      
      tabGroup.selectedIndex = (selectedIndex + 1) % tabCount;  
    }

    // Switch de tab sur invitation collaborateur
    if(!this.disabled_invitation_collaborateur) {
      const tabGroup = this.tab;
      if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
      const tabCount = tabGroup._tabs.length;
      let selectedIndex: number = 0;
      if(tabGroup.selectedIndex != null) { selectedIndex = tabGroup.selectedIndex }      
      tabGroup.selectedIndex = (selectedIndex + 1) % tabCount;  
    }
  } 
  
  // Evènement en provenance du component entite-informations-generales
  public onChildCellClicked2(event: OutputParent): void {
    
    this.acces_concentrateur_actif          = event.acces_concentrateur_actif;
    this.creer_succursale                   = event.creer_succursale;
    this.entite_est_succursale              = event.entite.typ_ent_code==='SUCCURSALE';
    
    this.est_dirigeant_principal            = event.est_dirigeant_principal;  
    this.utl_profil                         = event.utl_profil;
    this.entite                             = event.entite;
    this.ent_id                             = (this.ent_id > 0 ? this.ent_id : event.ent_id);
    this.RelUtilisateurEntite               = event.RelUtilisateurEntite;
    this.acces_concentrateur                = event.acces_concentrateur;
    this.succursales                        = event.succursales;      
    this.display                            = event.display;
    this.disabled_informations_generales    = event.disabled_informations_generales;
    this.disabled_liste_collaborateur       = event.disabled_liste_collaborateur;
    this.disabled_invitation_collaborateur  = event.disabled_invitation_collaborateur;
    
    const tabGroup = this.tab;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    const tabCount = tabGroup._tabs.length;
    
    switch (this.action) {
      case this.CONST_ACTION_OUVRIR_ONGLET_COORDONNEES_BANCAIRE:
        // Switch de tab sur coordonnées bancaires
        tabGroup.selectedIndex = 1 % tabCount;
        break;
      case this.CONST_ACTION_OUVRIR_ONGLET_CONCENTRATEUR:
        // Switch de tab sur acces concentrateur
        tabGroup.selectedIndex = 2 % tabCount;  
        break;
      default:
        // Switch de tab sur liste collaborateur
        if(!this.disabled_liste_collaborateur) {
          const tabGroup = this.tab;
          if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
          const tabCount = tabGroup._tabs.length;
          let selectedIndex: number = 0;
          if(tabGroup.selectedIndex != null) { selectedIndex = tabGroup.selectedIndex }      
          tabGroup.selectedIndex = (selectedIndex + 1) % tabCount;  
        }
  
        // Switch de tab sur invitation collaborateur
        if(!this.disabled_invitation_collaborateur) {
          const tabGroup = this.tab;
          if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
          const tabCount = tabGroup._tabs.length;
          let selectedIndex: number = 0;
          if(tabGroup.selectedIndex != null) { selectedIndex = tabGroup.selectedIndex }      
          tabGroup.selectedIndex = (selectedIndex + 1) % tabCount;  
        }
  
        // Switch de tab sur informations générales
        if(this.disabled_invitation_collaborateur && this.disabled_liste_collaborateur) {
          const tabGroup = this.tab;
          if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
          const tabCount = tabGroup._tabs.length;
          tabGroup.selectedIndex = 0 % tabCount;  
        }
        break;
    }
    if (this.selectedTab!=null) {
      tabGroup.selectedIndex = this.selectedTab ;
      
    }
  } 

  // Evènement en provenance du component entite-acces-concentrateur
  public onChildCellClicked(event: boolean): void {
    this.acces_concentrateur_actif = event;
  }  

  public onClickReturnProfilePage(): void {
    this.route.navigate(['/app/profil/'+this.utl_profil.id]);
  }

  getSuccursale(id_entite: number) {
    let succursales: any;
    
    if(this.RelUtilisateurEntite.est_dirigeant) {
      // Cas n°1 : l'expert est dirigeant de l'entité mère, on affiche toutes les succursales de l'entité
      succursales = this.succursales.filter(rel => rel.entite.id_entite_principale == id_entite && rel.entite.typ_ent_nom == this.CONST_NOM_TYPE_ENTITE_SUCCURSALE && rel.entite.EstInscrit == true)
    } else {
      // Cas n°2 : l'expert n'est pas dirigeant de l'entité mère, on affiche les succursales uniquement actives et auquel il est rattaché
      succursales = this.succursales.filter(rel => rel.entite.id_entite_principale == id_entite && rel.entite.typ_ent_nom == this.CONST_NOM_TYPE_ENTITE_SUCCURSALE && rel.entite.EstInscrit == true && rel.est_active == true)
    }
    
    return succursales;
  }               
}